<div class="content">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Notifications</h4>
          <div class="header-btn">
            <div
              style="display: flex;flex-direction: row;align-items:center; justify-content: space-between; width: 90%;">
              <button class="btn btn-fill btn-primary mr-3" (click)="openPopup(content)">
                Add
              </button>
              <input class=" form-control text-white" type="text" [(ngModel)]="notifications.title" placeholder="search"
                style="width: 30%;" />
            </div>

          </div>
        </div>
        <div class=" card-body">
          <div class=" table-responsive">
            <table class=" table tablesorter" id="">
              <thead class=" text-primary">
                <tr>
                  <th>Title</th>
                  <th>Message</th>
                  <th>Image</th>
                  <th>Status</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let notifications of notifications | filter:notifications.title">

                  <td>{{notifications.title}}</td>
                  <td>{{notifications.body}}</td>
                  <td>
                    <div class="avatar">

                      <img [src]="notifications.image" *ngIf="notifications.image" alt="">
                      <img src="assets/img/default-avatar.png" *ngIf="!notifications.image" alt="">
                    </div>
                  </td>
                  <td> {{notifications.status}}</td>
                  <td>{{notifications?.createdAt | date : 'dd-MM-yyyy'}}</td>
                  <td>{{notifications?.updatedAt | date : 'dd-MM-yyyy'}}</td>
                  <td>
                    <button (click)="onDelete(notifications)" class=" btn btn-fill btn-danger">Delete</button>
                  </td>
                </tr>
                <tr *ngIf="notifications.length===0">
                  <td colspan="7" class="text-center">
                    Notifications not found
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Add modal -->
<ng-template #content let-modal>
  <div *ngIf="isSpinner === true" class="spinner">
    <div class="spinner-border" style="color: rgb(0, 17, 255)"></div>
  </div>
  <div class="modal-header">
    <h4 class="modal-title">Add Notification</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <div class="form-group">
        <label>Notification Title</label>
        <input class="form-control text-dark" #title placeholder="Title" type="text" name="title" />
      </div>
      <div class="form-group">
        <label>Description</label>
        <input class="form-control text-dark" #msg placeholder="Description" type="text" name="msg" />
      </div>
    </div>
    <div class="form-group">
      <label>Image</label>
      <input class="form-control text-dark" #imgurl placeholder="Image" (change)="fileSelect($event)" type="file"
        name="imgurl" />
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button type="button" class="btn btn-outline-dark mr-3" (click)="modal.close('Close click')">
      Close
    </button>
    <button type="button" class="btn btn-fill btn-primary"
      (click)="SendNotificationBtn(title.value, msg.value, imgurl?.value)">

      Send
    </button>
  </div>
</ng-template>