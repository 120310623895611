<div class=" content">
    <div class=" row">
      <div class=" col-md-12">
        <div class=" card">
          <div class=" card-header">
            <h4 class=" card-title">Fonts</h4>
            <div style="display: flex;flex-direction: row;align-items:center; justify-content: space-between; width: 90%;">
            <button class=" btn btn-fill btn-primary mr-3" (click)="openPopup(content)">Add</button>
            <input class=" form-control text-white"type="text"  [(ngModel)]="searchName" placeholder="search" style="width: 30%;" />
            </div>
          </div>
          <div class=" card-body">
            <div class=" table-responsive">
              <table class=" table tablesorter" id="">
                <thead class=" text-primary">
                  <tr>
                    <th>Font Name</th>
                    <th>Link</th>
                    <th>Status</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let font of getFont |filter:searchName">
                    <td>{{font?.name ? font?.name : '-'}}</td>
                    <td style="width: 90px;">{{font?.link ? font?.link : '-'}}</td>
                    <td>{{font?.status ? font?.status:'-'}}</td>
                    <td>{{font?.createdAt | date : 'dd-MM-yyyy'}}</td>
                    <td>{{font?.updatedAt | date : 'dd-MM-yyyy'}}</td>
                    <td>
                      <button class=" btn btn-fill btn-danger" (click)="onDelete(font)">Delete</button>
                    </td>
                  </tr>
                  <tr *ngIf="getFont.length===0">
                    <td colspan="7" class="text-center">
                      Fonts not found
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- Add modal -->
  <ng-template #content let-modal>
    <div class=" modal-header">
      <h4 class="modal-title">Add fonts</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.close('Close click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class=" form-group">
        <label>Font Name</label>
        <input class=" form-control text-dark" placeholder="Font Name" type="text" name="name"
        [(ngModel)]="fonts.name" />
      </div>
      <div class=" form-group">
        <label>Font Link</label>
        <input class=" form-control text-dark" placeholder="Font Link" type="text" name="link"
          [(ngModel)]="fonts.link" />
      </div>
    </div>
    <div class="modal-footer d-flex justify-content-end">
      <button type="button" class="btn btn-outline-dark mr-3" (click)="modal.close('Close click')">Close</button>
      <button type="button" class="btn btn-fill btn-primary" (click)="onSave(modal)">Save</button>
    </div>
  </ng-template>
  
  <!-- Edit Modal -->
  <!-- <ng-template #editContent let-editModal>
    <div class=" modal-header">
      <h4 class="modal-title">Edit fonts</h4>
      <button type="button" class="close" aria-label="Close" (click)="editModal.close('Close click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
     
        <div class="modal-body">
            <div class=" form-group">
              <label>Font Name</label>
              <input class=" form-control text-dark" placeholder="Font Name" type="text" name="name"
              [(ngModel)]="fonts.name" />
            </div>
            <div class=" form-group">
              <label>Font Link</label>
              <input class=" form-control text-dark" placeholder="Font Link" type="text" name="link"
                [(ngModel)]="fonts.link" />
            </div>
          </div>
    </div>
    <div class="modal-footer d-flex justify-content-end">
      <button type="button" class="btn btn-outline-dark mr-3" (click)="editModal.close('Close click')">Close</button>
      <button type="button" class="btn btn-fill btn-primary" (click)="onEditSave(editModal)">Save</button>
    </div>
  </ng-template> -->