import { Component, OnInit } from '@angular/core';
import { RestApiService } from 'src/app/services/rest-api.service';
import Swal from 'sweetalert2';
import {
  ModalDismissReasons,
  NgbActiveModal,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user',
  templateUrl: 'user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  users = [];
  bussinessDetail = [];
  filterTerm: string;
  bcategories;

  offset = 0;
  limit = 15;
  bussinessForm = {
    _id: '',
    bussinessName: '',
    businesscategory: '',
    userName: '',
    designation: '',
    contactNumber: '',
    extracontactNumber: '',
    email: '',
    website: '',
    address: '',
    city: '',
    state: '',
    logo: '',
  };
  isSpinner = false;
  constructor(
    private restApiService: RestApiService,
    private modalService: NgbModal,
    private fb: FormBuilder
  ) {}
  cate;
  ngOnInit() {
    this.getbussinessDetail();
    this.getbussinessCategory();
  }

  previousOrNext(isNext) {
    this.isSpinner = true;
    if (isNext) {
      this.offset++;
      this.getbussinessDetail();
    } else {
      if (this.offset >= 0) {
        this.offset--;
        this.getbussinessDetail();
      }
    }
  }
  shortDate(arrya: any[]) {
    return arrya.sort((a: any, b: any) => {
      var old = new Date(a.createdAt).getTime();
      var current = new Date(b.createdAt).getTime();
      return current - old;
    });
  }
  getbussinessDetail() {
    this.restApiService
      .getbussinessDetail(this.offset, this.limit)
      .then((res) => {
        if (res && res.code === 200) {
          this.bussinessDetail = res.data;
          this.isSpinner = false;          
          this.bussinessDetail = this.shortDate(res.data);
        }
      })
      .catch((err) => {
        console.log('error is', err);
      });
  }
  // onDelete(business){
  //   this.restApiService.deleteBusinessEcard(business._id).then(res => {
  //     if (res && res.code === 200) {
  //       console.log("delete sucess");
  //       this.getbussinessDetail();
  //     }
  //   }).catch(err => {
  //     console.log('error is', err);
  //   })
  // }
  selectedCategory;
  logoFile;
  currentUser;
  onEdit(modal, category) {
    this.selectedCategory = JSON.parse(JSON.stringify(category));
    const activeModal = this.modalService.open(modal);
    this.cate = this.selectedCategory.businesscategory;
  }
  bussinessName;
  onEditSave(modal) {
    this.isSpinner = true;
    this.bussinessForm._id = this.selectedCategory._id;
    this.logoFile = this.selectedCategory.logo;
    this.bussinessForm.bussinessName = this.selectedCategory.bussinessName;
    this.bussinessForm.businesscategory = this.cat_value;
    this.bussinessForm.userName = this.selectedCategory.userName;
    this.bussinessForm.designation = this.selectedCategory.designation;
    this.bussinessForm.contactNumber = this.selectedCategory.contactNumber;
    this.bussinessForm.extracontactNumber =
      this.selectedCategory.extracontactNumber;
    this.bussinessForm.email = this.selectedCategory.email;
    this.bussinessForm.website = this.selectedCategory.website;
    this.bussinessForm.address = this.selectedCategory.address;
    this.bussinessForm.city = this.selectedCategory.city;
    this.bussinessForm.state = this.selectedCategory.state;

    let value = this.bussinessForm;
    if (this.logoFile) {
      value.logo = this.logoFile;
    }
    this.restApiService
      .updateBussinessDetail(value)
      .then((res) => {
        if (res && res.code === 200) {
          this.getbussinessDetail();
          modal.close();
        }
      })
      .catch((err) => {
        console.log('error is', err);
      });
  }
  getbussinessCategory() {
    this.restApiService
      .getbussinessCategory()
      .then((res) => {
        if (res && res.code === 200) {
          this.bcategories = res.data.sort((a, b) => a.index - b.index);
        }
      })
      .catch((err) => {
        console.log('error is', err);
      });
  }
  cat_value;
  getcompanyid(event) {
    this.cat_value = event;
  }
  onDelete(business) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.restApiService
          .deleteBusinessEcard(business._id)
          .then((res) => {
            if (res && res.code === 200) {
              Swal.fire('Deleted!', 'Ecard has been deleted.', 'success');
              this.getbussinessDetail();
            }
          })
          .catch((err) => {
            console.log('error is', err);
          });
      }
    });
  }
}
