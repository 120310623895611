import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './modules/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './modules/layouts/auth-layout/auth-layout.component';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ComponentsModule } from './modules/components/components.module';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './modules/pages/login/login.component';
import { ApiConfiguration } from './services/api/configuration';
import { ApiCallService } from './services/api/api-call.service';
import { RestApiService } from './services/rest-api.service';
import { FramesComponent } from './modules/pages/frames/frames.component';
import { CustomeComponent } from './modules/pages/custome/custome.component';
import { AddCustomeImageComponent } from './modules/pages/add-custome-image/add-custome-image.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AddECardComponent } from './modules/pages/add-e-card/add-e-card.component';
import { NotificationComponent } from './modules/pages/notification/notification.component';
import { FontComponent } from './modules/pages/font/font.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CategoryComponent } from './modules/pages/category/category.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FestivalComponent } from './modules/pages/festival/festival.component';
import { UserComponent } from './modules/pages/user/user.component';
import { BusinessCategoryComponent } from './modules/pages/business-category/business-category.component';
import { SubscriptionComponent } from './modules/pages/subscription/subscription.component';
import { AllUsersDeatilsComponent } from './modules/pages/all-users-deatils/all-users-deatils.component';
import { ShowUserDeatilsComponent } from './modules/pages/show-user-deatils/show-user-deatils.component';
import { DownloadPostComponent } from './modules/pages/download-post/download-post.component';
import { DeletedFestivalComponent } from './modules/pages/deleted-festival/deleted-festival.component';
import { BulkMessagesComponent } from './modules/pages/bulk-messages/bulk-messages.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    LoginComponent,
    FramesComponent,
    AddCustomeImageComponent,
    AddECardComponent,
    NotificationComponent,
    FontComponent,
    CustomeComponent,
    CategoryComponent,
    FestivalComponent,
    UserComponent,
    BusinessCategoryComponent,
    SubscriptionComponent,
    AllUsersDeatilsComponent,
    ShowUserDeatilsComponent,
    DownloadPostComponent,
    DeletedFestivalComponent,
    BulkMessagesComponent
    // AddFrameComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    ComponentsModule,
    NgbModule,
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    BrowserAnimationsModule,
    DragDropModule,
    Ng2SearchPipeModule

  ],
  providers: [
    ApiConfiguration,
    ApiCallService,
    RestApiService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
