import { environment } from './../../../../environments/environment.prod';
import { Component, OnInit } from '@angular/core';
import {
  ModalDismissReasons,
  NgbActiveModal,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { RestApiService } from 'src/app/services/rest-api.service';
import Swal from 'sweetalert2';
import { CdkDragDrop, moveItemInArray, CdkDrag } from '@angular/cdk/drag-drop';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-deleted-festival',
  templateUrl: './deleted-festival.component.html',
  styleUrls: ['./deleted-festival.component.scss'],
})
export class DeletedFestivalComponent implements OnInit {
  isSpinner = false;
  festivals = [];
  closeResult: string;
  searchFestival: string;
  searchCustom: string;
  categories = [];
  festival = {
    categoryId: '',
    image: null,
    title: '',
    isPaid: false,
  };
  selectedFestival;
  categoryList;
  deletedFestivalList;
  deletedCustomList;
  tags;
  today;
  bannerFestival;
  notificationImage;
  selectedImageIndex = null;
  selectedImageCategory = null;
  selectedCategory;
  offsetFestival = 0;
  offsetCustom = 0;
  limit = 10;

  isExpand = false;
  searchSubject: Subject<string> = new Subject();

  constructor(
    private restApiService: RestApiService,
    public http: HttpClient,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal
  ) { }
  ngOnInit(): void {
    this.isSpinner = true;
    this.searchSubject.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((searchText: string) => {
      if (searchText.length <= 0) {
        this.getFestivals();
      } else {
        this.getSearchedFestival(searchText);
      }
    });

    this.getFestivals();
    this.getCustom();
  }

  async singleFestivalExpand(category) {
    if (
      this.isExpand &&
      category?.category?._id == this.selectedCategory?.category?._id
    ) {
      this.isExpand = false;
    } else {
      this.isExpand = true;
      this.selectedCategory = category;
    }
  }
  getFestivals() {
    this.isSpinner = true;
    this.today = new Date();
    var date =
      this.today.getFullYear() +
      '-' +
      (this.today.getMonth() + 1) +
      '-' +
      this.today.getDate();
    this.restApiService
      .getCategoryWiseDeletedFestival(this.offsetFestival, this.limit)
      .then((res) => {
        if (res && res.code === 200) {
          let response = res.data.sort(
            (bussiness) =>
              new Date(bussiness?.category?.extraData?.EndDate) <=
              new Date(date)
          );
          this.deletedFestivalList = response;
          const tags = this.deletedFestivalList.map((x) => {
            return x.category?.name;
          });
          this.tags = ['All', ...tags];
        }
        if (this.deletedFestivalList?.length && this.deletedCustomList?.length) {
          this.isSpinner = false;
        }
      })
      .catch((err) => {
        this.isSpinner = false;
        console.log('error is ', err);
      });
  }

  onActive(festival, type) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Active it!',
    }).then((result) => {
      this.isSpinner = true;
      if (result.isConfirmed) {
        const obj = {
          type,
          id: festival._id,
        };
        this.restApiService
          .activeFestival(obj)
          .then((res) => {
            if (res && res.code === 200) {
              Swal.fire('Actived!', 'Festival has been Actived.', 'success');
              if (type == 'festival') {
                this.getFestivals();
              } else {
                this.getCustom();
              }
            }
            this.isSpinner = false;
          })
          .catch((err) => {
            this.isSpinner = false;
            console.log('error is', err);
          });
      }
    });
  }
  onActiveFestival(category, type) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Active it!',
    })
      .then((result) => {
        if (result.isConfirmed) {
          this.isSpinner = true;
          this.restApiService
            .activeCategory(category.category._id)
            .then((res) => {
              for (let index = 0; index < category.festivals.length; index++) {
                let obj = {
                  type,
                  id: category.festivals[index]._id,
                };
                this.restApiService
                  .activeFestival(obj)
                  .then((res) => {
                    if (res && res.code === 200) {
                      // if (category.festivals.length - 1 == index) {
                      Swal.fire(
                        'Active!',
                        'Festival has been Actived.',
                        'success'
                      );
                      this.getFestivals();
                      this.isSpinner = false;
                    }
                    // }
                  })
                  .catch((err) => {
                    console.log('error is', err);
                  });
              }
            });
        }
      })
      .catch((err) => {
        console.log('error is', err);
      });
  }
  async getSearchedFestival(value) {
    this.isSpinner = true;
    if (value.length < 0) {
      this.getFestivals();
    } else {
      await this.restApiService.getSearchDeletedFestival(value).then(
        async (res) => {

          if (res && res.code == 200) {
            this.deletedFestivalList = res.data;
            this.isSpinner = false;
            console.log('res.data :>> ', res.data);
          }
          if (!res.data.length) {
            this.deletedFestivalList = [];
          }
        },
        (err) => {
          this.deletedFestivalList = [];
          console.log('err is', err);
          this.isSpinner = false;
        }

      );
    }
  }
  getSearchedCustom(value) {
    this.isSpinner = true;
    if (value.length > 0) {
      this.restApiService.getSearchDeletedFestival(value).then(
        async (res) => {
          if (res && res.code == 200) {
            this.deletedCustomList = res.data;
            this.isSpinner = false;
            console.log('res cus.data :>> ', res.data);
          }
          if (!res.data.length) {
            this.deletedCustomList = [];
          }
        },
        (err) => {
          this.deletedCustomList = [];
          console.log('err is', err);
          this.isSpinner = false;
        }
      );
    } else {
      this.getCustom();
    }
  }
  getCustom() {
    this.isSpinner = true;
    this.today = new Date();
    var date =
      this.today.getFullYear() +
      '-' +
      (this.today.getMonth() + 1) +
      '-' +
      this.today.getDate();
    this.restApiService
      .getCategoryWiseDeletedCustom(this.offsetCustom, this.limit)
      .then((res) => {
        if (res && res.code === 200) {
          let response = res.data.sort(
            (bussiness) =>
              new Date(bussiness?.category?.extraData?.EndDate) <=
              new Date(date)
          );
          this.deletedCustomList = response;
          const tags = this.deletedCustomList.map((x) => {
            return x.category?.name;
          });
          this.tags = ['All', ...tags];
        }
        if (this.deletedFestivalList?.length && this.deletedCustomList?.length) {
          this.isSpinner = false;
        }
        console.log("All custome post", this.deletedCustomList);
      })
      .catch((err) => {
        this.isSpinner = false;
        console.log('error is ', err);
      });
  }

  onDeletePermanent(post, type) {
    Swal.fire({
      title: 'Are you sure, you want to delete permenant this post?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!',
    }).then((result) => {
      this.isSpinner = true;
      if (result.isConfirmed) {
        this.restApiService
          .deletePermanent(post._id, type)
          .then((res) => {
            if (res && res.code === 200) {
              Swal.fire('Deleted !', type + ' has been deleted.', 'success');
            }
            if (type == 'festival') {
              this.getFestivals();
            } else {
              this.getCustom();
            }
            this.isSpinner = false;
          })
          .catch((err) => {
            this.isSpinner = false;
            console.log('error is', err);
          });
      }
    });
  }
  onChangePageFestival(isNext) {
    this.isSpinner = true;
    if (isNext) {
      this.offsetFestival++;
      this.getFestivals();
    } else {
      if (this.offsetFestival != -1) {
        this.offsetFestival--;
        this.getFestivals();
      }
    }
  }
  onChangePageCustom(isNext) {
    this.isSpinner = true;
    if (isNext) {
      this.offsetCustom++;
      this.getCustom();
    } else {
      if (this.offsetCustom != -1) {
        this.offsetCustom--;
        this.getCustom();
      }
    }
  }
}
