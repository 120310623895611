<div *ngIf="isSpinner == true" class="spinner"
    style="position: fixed; top: 0; width: 100%; height: 100%; z-index: 99999;">
    <div
        style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100%; background-color: rgba(179, 177, 177, 0.191); ">
        <div class="spinner-border" style="color: rgb(0, 17, 255)"></div>
    </div>
</div>
<div class="content" [ngStyle]="{'filter': isSpinner == true ? 'blur(1px)' : 'blur(0px)'}">
    <div class="row">
        <div class="col-12">
            <div class="card col-12 p-0 d-flex flex-wrap" style="background-color: transparent;">
                <div class="card-header pb-3 p-0 " style="background-color: rgba(75, 87, 255, 0.09);">
                    <div class="row col-md-12 d-flex justify-content-between">
                        <h4 class="card-title p-3">All Users Details</h4>
                        <div class="d-flex">
                            <div class="p-3" style="color: white; font-size: 18px; padding-left: 45px;">Total Prime User:
                                {{allPrimeUserCount}}</div>
                            <div class="p-3" style="color: white; font-size: 18px; padding-left: 45px;">Active Prime User:
                                {{primeUserCount}}</div>
                        </div>
                        
                    </div>
                    <div class="row col-12 d-flex justify-content-center p-0">
                        <form class="col-sm-5 col-md-6 px-4">
                            <div class=" d-flex flex-row">
                                <input type="text" name="focus" required class="search-box" [(ngModel)]="filterTerm"
                                    (ngModelChange)="getSearchedUserDetails($event)" placeholder="Enter search term" />
                                <button class="close-icon" type="reset" (click)="clearFilter()"></button>
                            </div>
                        </form>

                        <div class="col-sm-12 col-md-5 row m-1 ">
                            <!-- <button (click)="onDownloadAllUserExcelSheet(false)"
                                style="width: 32%; border-radius: 5px; margin-left: 5px;"
                                class="btn-primary px-1 py-0 ">Download
                                All
                                User</button>
                            <button (click)="onDownloadAllUserExcelSheet(true)"
                                style="width: 32%; border-radius: 5px; margin-left: 5px;"
                                class="btn-primary px-1 py-0">Download
                                Prime
                                User</button> -->

                            <div class="text-white align-items-center justify-content-center row dwDiv"
                                style="width: 30%; margin-left: 5px;">
                                <select class="opts" name="dwuser" id="dwuser" (change)="dwSheet($event.target.value)"
                                    style="border:none; color: white; background: transparent; padding: 0px; font-size: 12px; width: -webkit-fill-available;">
                                    <option value="" selected disabled style="background: black;">Download Sheets</option>
                                    <option value="dwAllUser" style="background: black;">Download All User</option>
                                    <option value="dwPrimeUser" style="background: black;">Download Prime User</option>
                                    <option value="dwExpPrimeUser" style="background: black;">Download Expired Prime User</option>
                                </select>
                            </div>

                            <div class="text-white align-items-center justify-content-center row userSelect">
                                <select name="user" id="user" (change)="selectUser($event.target.value)"
                                    style="border:none; color: white; background: transparent; padding: 0px; font-size: 12px;">
                                    <option value="allUser" style="background: black;">Users</option>
                                    <option value="PrimeUser" style="background: black;">Prime</option>
                                </select>
                                <div style="color: white; font-size: 18px; padding-left: 10px;"> {{TotalprimeUser}}
                                </div>

                            </div>
                            <!-- <div class="mt-1" style="color: white; font-size: 18px; padding-left: 45px;">Prime User:
                                {{primeUserCount}}</div> -->
                        </div>

                    </div>

                </div>


                <div class="d-flex flex-wrap p-0" style="background-color: rgba(75, 87, 255, 0.09);">
                    <div class=" card-body">
                        <div class="col-12">
                            <div class="text-white d-flex flex-row col-12 align-items-center  text-left p-2"
                                style="border-bottom: 1px solid gray;">
                                <!-- <span class="col-md-1 col-sm-1">Index</span> -->
                                <span class="col-md-3 col-sm-3">Mobile No.</span>
                                <span class="col-md-1 col-sm-1 text-center">Is Prime <i (click)="changePrimeOrder()"
                                        class="fa fa-sort" style="cursor: pointer;"></i></span>
                                <span class="col-md-2 col-sm-2 text-center">Generate Post</span>
                                <span class="col-md-2 col-sm-3">Created At</span>
                                <span class="col-md-3 col-sm-3">Last Active Time <i (click)="changeOrder()"
                                        class="fa fa-sort" style="cursor: pointer;"></i></span>
                                <!-- <span class="col-md-1 col-sm-1">Active Count</span> -->
                                <span class="col-md-2 col-sm-1">Action</span>
                            </div>
                            <div class="d-flex flex-column col-12" *ngFor="let user of UserData;let i = index "
                                style="border-bottom: 1px solid gray; "
                                [ngStyle]="{'border':(selectedUserBusiness[0]?.userId == user?._id) || selectedUserBusiness.length == 0 && viewUser?._id == user?._id   && isExpand ? '1px solid lightgray' : '0px'}">
                                <div class="text-white d-flex flex-row col-12 align-items-center  text-left py-2">
                                    <!-- <span class="col-md-1 col-sm-1 p-0">{{i+1}}</span> -->
                                    <span class="col-md-3 col-sm-3 p-0">{{user.mobileNo}}</span>
                                    <span class="col-md-1 col-sm-1 text-center">{{user.extraData.length}}</span>
                                    <span class="col-md-2 col-sm-2 text-center">{{user?.generateImage}}</span>
                                    <span class="col-md-2 col-sm-3">{{user?.createdAt | date : 'dd-MM-yyyy'}}</span>
                                    <span class="col-md-3 col-sm-3">{{user?.activeTime[user?.activeTime.length-1] | date
                                        : 'dd-MM-yyyy HH:mm'}} <p *ngIf="user?.activeTime?.length-1 > 0">
                                            ({{user?.activeTime?.length}})</p></span>
                                    <!-- <span class="col-md-1 col-sm-1">{{user?.activeTime.length-1 > 0 ? user?.activeTime.length : '-'}}</span> -->
                                    <div class="col-md-2 col-sm-1">
                                        <button class=" btn btn-fill btn-primary px-3 py-2"
                                            (click)="getUserBusiness(user)">View</button>
                                    </div>
                                </div>
                                <div
                                    *ngIf="selectedUserBusiness.length == 0 && viewUser?._id == user?._id  && isExpand">
                                    <p class="text-center text-white" style="font-size: medium; font-weight: 600; ">
                                        Business not found</p>
                                </div>
                                <div *ngIf="(selectedUserBusiness[0]?.userId == user?._id) && isExpand"
                                    class="col-12 row">
                                    <div class="col-md-9  col-sm-12 mt-1 ">
                                        <div *ngFor="let item of selectedUserBusiness">
                                            <div class="row pt-2 mb-1"
                                                style="border-radius: 10px; border: 1px solid rgb(92, 92, 92);">
                                                <div class="col-md-2 col-sm-6 d-flex flex-column">
                                                    <img [src]="item.logo" *ngIf="item.logo && item.logo !='null'"
                                                        alt="" height="100px" width="100px" style="object-fit :contain">
                                                    <img [src]="'assets/img/default-avatar.png'"
                                                        *ngIf="!item.logo || item.logo == '' || item.logo=='null'"
                                                        alt="" height="100px" width="100px" style="object-fit :contain">
                                                    <div style="padding-left: 10px">
                                                        <button (click)="onDelete(item)"
                                                            style="border-radius: 50%; height: 35px; width: 35px; align-items: center; margin-right: 15px; ">
                                                            <i class="tim-icons icon-trash-simple mr-2 "
                                                                style="font-size:18px;"></i>
                                                        </button>
                                                        <button (click)="onEditBusiness(editContent,item)"
                                                            style="border-radius: 50%;height: 35px; width: 35px; align-items: center; ">
                                                            <i class="tim-icons icon-pencil"
                                                                style="font-size:18px;"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 col-sm-12 pt-2">
                                                    <p style="font-size: medium; font-weight: 600;">BussinessName :
                                                        {{item.bussinessName}}</p>
                                                    <p *ngIf="item.businesscategory">Bussinesscategory :
                                                        {{item.businesscategory}}</p>
                                                    <p *ngIf="item.userName">UserName : {{item.userName}}</p>
                                                    <p *ngIf="item.designation">Designation : {{item.designation}}</p>
                                                    <p *ngIf="item.contactNumber">ContactNumber : {{item.contactNumber}}
                                                    </p>
                                                    <p *ngIf="item.extracontactNumber">ExtracontactNumber :
                                                        {{item.extracontactNumber}}</p>
                                                </div>
                                                <div class="col-md-3 col-sm-12 ">
                                                    <p *ngIf="item.email">Email : {{item.email}}</p>
                                                    <p *ngIf="item.website">Website : {{item.website}}</p>
                                                    <p *ngIf="item.address">Address : {{item.address}}</p>
                                                    <p *ngIf="item.city">City : {{item.city}}</p>
                                                    <p *ngIf="item.state">State : {{item.state}}</p>
                                                    <p *ngIf="item.createdAt">CreatedAt : {{item.createdAt}}</p>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-12 mt-1 mb-2"
                                        style="border-radius: 10px; border: 1px solid rgb(92, 92, 92);">
                                        <div id="style-1" style=" overflow-y: scroll; width: 100%;  position: relative;  padding: 3%; 
                                                    ">
                                            <div class="col-12">
                                                <button style="color: white; font-weight: 700; 
                                                            text-align: center; margin-top: 5%; width: 100%; 
                                                            height: 40px; background: rgb(100, 100, 100); 
                                                            border-radius: 5px; border:1px solid gray;"
                                                    (click)="addUserPlanSubscription(selectPlan)">
                                                    Add Plan
                                                </button>
                                                <div class="col-12 p-0">
                                                    <div class="pay-card px-2 mb-0"
                                                        *ngFor="let plan of viewUserExtraData;let i = index"
                                                        [ngStyle]="{'background': plan.isSubscription == 'true' || plan.isSubscription == true ? '#030ffc' : '#fc0328'}"
                                                        style="border: 1px solid gray; position: relative; border-radius: 10px;  margin-top: 5%; ">
                                                        <h3 class="m-0 mt-1">Membership<strong>
                                                                <span> ₹ {{plan.planAmount}}/</span>
                                                                {{plan.planDuraction}}</strong></h3>
                                                        <div
                                                            class="d-flex flex-row align-items-right justify-content-between">
                                                            <h5>Start :{{plan.startDate | date: "dd-MM-yyyy" }}
                                                            </h5>
                                                            <h5>End :{{plan.endDate | date: "dd-MM-yyyy" }}</h5>
                                                        </div>
                                                        <button *ngIf="i == 0"
                                                            style="background: transparent; border: 0px; position: absolute; top: 0px; right: 0px; "
                                                            (click)="updateSubscriptionPlan(editPlan,plan)">
                                                            <i class="tim-icons icon-pencil"
                                                                style="font-size:12px; color:rgb(255, 198, 198); border: 1px solid rgb(255, 198, 198); border-radius: 50%; padding: 3px; background: #07024e54;"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!UserData.length" class="text-white text-center align-items-center pt-4">
                                <p style="font-weight: 600;">User Not found</p>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between pt-3 align-items-center">
                            <div>
                                <a class="previous" (click)="previousOrNext(false)" *ngIf="this.offset != 0">&laquo;
                                    {{offset}} Previous</a>
                            </div>
                            <div>
                                <a class="next" style="cursor: not-allowed;">{{offset+1}}</a>
                            </div>
                            <div *ngIf="UserData.length">
                                <a class="next" (click)="previousOrNext(true)">{{offset+2}} Next &raquo;</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Edit  Business Modal -->
<ng-template #editContent let-editModal>
    <div class=" modal-header">
        <h4 class="modal-title">Edit Business</h4>
        <button type="button" class="close" aria-label="Close" (click)="editModal.close('Close click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <div class=" form-group">
            <input class=" form-control text-dark" placeholder="bussinessName" type="text"
                [(ngModel)]="selectedBusiness.bussinessName" name="categoryName" />
        </div>
        <div class=" form-group">
            <select [(ngModel)]='cate' style="width: 100%; border: 1px solid gray; border-radius: 10px; height: 40px;"
                (change)="getcompanyid($event.target.value)">
                <option *ngFor="let category of bcategories">{{category.businessCategory}}
                </option>
            </select>
        </div>
        <div class="form-group">
            <input class="form-control text-dark" placeholder="Image" (change)="browse($event)" type="file"
                name="image" />
        </div>
        <div class=" form-group">
            <input class=" form-control text-dark" placeholder="contactNumber" type="text"
                [(ngModel)]="selectedBusiness.contactNumber" name="categoryName" />
        </div>
        <div class=" form-group">
            <input class=" form-control text-dark" placeholder="extracontactNumber" type="text"
                [(ngModel)]="selectedBusiness.extracontactNumber" name="categoryName" />
        </div>
        <div class=" form-group">
            <input class=" form-control text-dark" placeholder="status" type="text"
                [(ngModel)]="selectedBusiness.status" name="categoryName" />
        </div>
        <div class=" form-group">
            <input class=" form-control text-dark" placeholder="userName" type="text"
                [(ngModel)]="selectedBusiness.userName" name="categoryName" />
        </div>
        <div class=" form-group">
            <input class=" form-control text-dark" placeholder="designation" type="text"
                [(ngModel)]="selectedBusiness.designation" name="categoryName" />
        </div>

        <div class=" form-group">
            <input class=" form-control text-dark" placeholder="website" type="text"
                [(ngModel)]="selectedBusiness.website" name="categoryName" />
        </div>
        <div class=" form-group">
            <input class=" form-control text-dark" placeholder="email" type="text" [(ngModel)]="selectedBusiness.email"
                name="categoryName" />
        </div>
        <div class=" form-group">
            <input class=" form-control text-dark" placeholder="address" type="text"
                [(ngModel)]="selectedBusiness.address" name="categoryName" />
        </div>
        <div class=" form-group">
            <input class=" form-control text-dark" placeholder="city" type="text" [(ngModel)]="selectedBusiness.city"
                name="categoryName" />
        </div>
        <div class=" form-group">
            <input class=" form-control text-dark" placeholder="state" type="text" [(ngModel)]="selectedBusiness.state"
                name="categoryName" />
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-end">
        <button type="button" class="btn btn-outline-dark mr-3" (click)="editModal.close('Close click')">Close</button>
        <button type="button" class="btn btn-fill btn-primary" (click)="onEditSave(editModal)">Save</button>
    </div>
</ng-template>



<!-- Add Subscription Modal -->
<ng-template #selectPlan let-editModal>
    <div class=" modal-header">
        <h4 class="modal-title">Add Subscription</h4>
        <button type="button" class="close" aria-label="Close" (click)="editModal.close('Close click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class=" form-group" style="padding-left: 5%; padding-right: 5%;">
        <span>Plan payment Id</span>
        <input class=" form-control text-dark bg-white" placeholder="razorpay_p_id" type="text"
            [(ngModel)]="razorpay_p_id" name="categoryName" />
    </div>
    <div style="width: 100%; padding-left: 5%; padding-right: 5%; padding-bottom: 5%;">

        <button class="btn btn-fill  btn-warning col-12" (click)="sendPlan(datas)"
            *ngFor="let datas of getscriptionData;">
            {{datas?.planDiscount}} &#x20B9; per {{datas?.duraction}}
        </button>

    </div>
</ng-template>

<!-- Edit  Subscription Plan Modal -->
<ng-template #editPlan let-editModal>
    <div class=" modal-header">
        <h4 class="modal-title">Edit Subscription Plan</h4>
        <button type="button" class="close" aria-label="Close" (click)="editModal.close('Close click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class=" form-group">
            <span>Plan payment Id</span>
            <input class=" form-control text-dark bg-white" disabled placeholder="website" type="text"
                [(ngModel)]="selectedplan.razorpay_p_id" name="categoryName" />
        </div>
        <div class=" form-group">
            <span>Plan Amount</span>
            <input class=" form-control text-dark" placeholder="website" type="text"
                [(ngModel)]="selectedplan.planAmount" name="categoryName" />
        </div>
        <div class=" form-group">
            <span>Plan Duraction</span>
            <input class=" form-control text-dark" placeholder="website" type="text"
                [(ngModel)]="selectedplan.planDuraction" name="categoryName" />
        </div>
        <div class=" form-group">
            <span>Plan Start Date</span>
            <input class=" form-control text-dark" placeholder="website" type="text"
                [(ngModel)]="selectedplan.startDate" name="categoryName" />
        </div>
        <div class=" form-group">
            <span>Plan End Date</span>
            <input class=" form-control text-dark" placeholder="website" type="text" [(ngModel)]="selectedplan.endDate"
                name="categoryName" />
        </div>
        <div class=" form-group">
            <span>Plan Subscription Status</span>
            <input class=" form-control text-dark" placeholder="website" type="text"
                [(ngModel)]="selectedplan.isSubscription" name="categoryName" />
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-end">
        <button type="button" class="btn btn-outline-dark mr-3" (click)="editModal.close('Close click')">Close</button>
        <button type="button" class="btn btn-fill btn-primary" (click)="saveUpdatePlan(editPlan)">Save</button>
    </div>
</ng-template>