import { environment } from './../../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { RestApiService } from 'src/app/services/rest-api.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import {CdkDragDrop, moveItemInArray, CdkDrag} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-frames',
  templateUrl: './frames.component.html',
  styleUrls: ['./frames.component.scss']
})
export class FramesComponent implements OnInit {
  selectedFestival;
  baseUrl = environment.baseUrl;
  // ImageUrl = environment.ImageUrl;
  frames = []
  email = {
    pt: '0',
    pl: '0'
  };
  address = {
    pt: '0',
    pl: '0'
  };
  number = {
    pt: '0',
    pl: '0'
  };
  website = {
    pt: '0',
    pl: '0'
  };
  selectedFile
  constructor(private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public restApiService: RestApiService,
    private router:Router) {
    this.getFrame();
  }

  ngOnInit(): void {
    
  }
  shortDate(arrya: any[]) {
    return arrya.sort((a: any, b: any) => {
      var old = new Date(a.createdAt).getTime();
      var current = new Date(b.createdAt).getTime();
      return current - old;
    });
  }
  async getFrame() {
    const { data } = await this.restApiService.getFrames();
    this.frames = data.sort((a, b) => a.index - b.index);
    // this.frames = this.shortDate(data)    
  }
  drop(event: CdkDragDrop<unknown>) {
    
    moveItemInArray(this.frames, event.previousIndex, event.currentIndex);
   
    // this.frames.forEach(async (ele, index) => {
    //   ele.index = index;
    //   try {
    //     await this.restApiService.updateFrameIndex({ item: this.frames[index] })

    //   } catch (error) {
    //     console.error("error in  ", index);
    //   }
    // })
  }

  sortPredicate(index: number, item: CdkDrag<number>) {   
    return (index + 1) % 2 === item.data % 2;
  }

  frame
  openPopup(content, item?) {
    if (item) {
      const obj = {
        categoryId: item.categoryId._id,
        title: item.title,
        _id: item._id
      }
      this.selectedFestival = obj
    }
    this.modalService.open(content);
  }
  async browse(ev) {

    this.selectedFile = ev.target.files[0]
    this.frame = await this.getBase64(this.selectedFile)
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  addressChange(postion) {
    this.address = postion;
  }
  addresshChange(postion) {
    this.address += postion;
  }

  addframe(modal) {
    const position = {
      email: this.email,
      address: this.address,
      number: this.number,
      website: this.website
    }
    const params = {
      image: this.selectedFile,
      position: JSON.stringify(position)
    }
    this.restApiService.addFrames(params).then(data => {
      modal.close()
        })
  }
  editaddframe;
  
  async onEdit(i){
    localStorage.setItem('isFrameEdit', 'true');
    this.editaddframe =  i
    this.router.navigateByUrl('/frames/add-frame', { state: i} )
  }
 
  onDelete(frame) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.restApiService.deleteFrame(frame._id).then(res => {
          if (res && res.code === 200) {
            Swal.fire(
              'Deleted!',
              'Frame has been deleted.',
              'success'
            )
            this.getFrame()
          }
        }).catch(err => {
          console.log('error is', err);
        })

      }
    })
  }
  onAddFrame(){
    localStorage.setItem('isFrameEdit', 'false');
    this.router.navigateByUrl('/frames/add-frame')
  }
}
