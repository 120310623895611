<div *ngIf="isSpinner === true" class="spinner" style="position: absolute; width: 100%; height: 100vh;">
  <div class="spinner-border" style="color: rgb(0, 17, 255)"></div>
</div>

<div class="content">

  <div class="row">

    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Festival</h4>
          <div
            style="display: flex;flex-direction: row;align-items:center; justify-content: space-between; width: 90%;">
            <button class="btn btn-fill btn-primary mr-3" (click)="openPopup(content)">
              Add
            </button>
            <input class=" form-control text-white" type="text" [(ngModel)]="searchName" placeholder="search"
              style="width: 30%;" />
          </div>
        </div>

        <div class=" card-body d-flex flex-wrap">

          <div style="display: flex; flex-direction: column !important; width: 100%;">
            <div class="d-flex justify-content-between">
              <h3 class="card-title ml-2 m-0">Banner</h3>
              <button style="border-radius: 50%;  border: 0px; " (click)="onDeleteFestival(festival)">
                <i class="tim-icons icon-trash-simple d-flex  align-items-center justify-content-center"
                  style="font-size:15px;color:rgb(240, 40, 40)"></i>
              </button>
            </div>
            <div style="display: flex;  flex-wrap: wrap;">
              <div *ngFor="let img of  bannerFestival; let i=index">
                <div style="padding: 4%; position: relative;">
                  <div style="width: 200px; padding: 2%;">
                    <img [src]="img.image" style="position: relative;">
                    <img [src]="'assets/icons/ic-prime.svg'" *ngIf="img.isPaid"
                      style="position: absolute; top: 8%; left: 10%; width: 10%; height: 10%;" alt="" />
                  </div>
                  <div style="position: absolute; top: 10%; right: 0%;">
                    <button style="border-radius: 50%;  border: 0px;" (click)="onDelete(img)">
                      <i class="tim-icons icon-trash-simple " style="font-size:10px; color:rgb(240, 40, 40)"></i>
                    </button>
                    <button style="border-radius: 50%;  border: 0px;" (click)="openPopup(editContent, img)">
                      <i class="tim-icons icon-pencil" style="font-size:10px;color:rgb(0, 0, 0)"></i>
                    </button>
                    <button style="border-radius: 50%;  border: 0px;"
                      (click)="openNotificationPopup(sendNotification, img)">
                      <i class="tim-icons icon-bell-55" style="font-size:15px;color:rgb(25, 0, 255)"></i>
                      <!-- <h1 style="color: black;"> {{i}}</h1> -->
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div *ngFor="let festival of allCategoryPost |filter:searchName"
            style="display: flex; flex-direction: column !important; width: 100%;">
            <div class="d-flex justify-content-between">
              <h3 class="card-title ml-2 m-0"> {{festival.category?.name}}</h3>
              <button style="border-radius: 50%;  border: 0px; " (click)="onDeleteFestival(festival)">
                <i class="tim-icons icon-trash-simple d-flex  align-items-center justify-content-center"
                  style="font-size:15px;color:rgb(240, 40, 40)"></i>
              </button>
            </div>
            <div style="display: flex;  flex-wrap: wrap;">
              <div *ngFor="let img of festival.festivals; let i=index">
                <div style="padding: 4%; position: relative;">
                  <div style="width: 200px; padding: 2%;">
                    <img [src]="img.image" style="position: relative;">
                    <img [src]="'assets/icons/ic-prime.svg'" *ngIf="img.isPaid"
                      style="position: absolute; top: 8%; left: 10%; width: 10%; height: 10%;" alt="" />
                  </div>
                  <div style="position: absolute; top: 8%; right: 0%;">
                    <button style="border-radius: 50%;  border: 0px; " (click)="onDelete(img)">
                      <i class="tim-icons icon-trash-simple " style="font-size:15px;color:rgb(240, 40, 40)"></i>
                    </button>
                    <button style="border-radius: 50%;  border: 0px;" (click)="openPopup(editContent, img)">
                      <i class="tim-icons icon-pencil" style="font-size:15px;color:rgb(0, 0, 0)"></i>
                    </button>
                    <button style="border-radius: 50%;  border: 0px;"
                      (click)="openNotificationPopup(sendNotification, img, i,festival.category._id)">
                      <i class="tim-icons icon-bell-55" style="font-size:15px;color:rgb(25, 0, 255)"></i>
                      <!-- <h1 style="color: black;"> {{i}}</h1> -->
                    </button>

                  </div>

                  <!--<div class="d-flex align-items-center justify-content-between">
                    <h6 class="card-title m-0">{{festival.index}}</h6>
                    <h6 class="card-title m-0">Category Name</h6>
                  </div>
                  <div class="d-flex align-items-center justify-content-between">
                    <h6 class="card-title m-0">Category Name</h6>
                    <h6 class="card-title m-0">Category Name</h6>
                  </div>
                  <div class="d-flex align-items-center justify-content-between">
                    <h6 class="card-title m-0">Category Name</h6>
                    <h6 class="card-title m-0">Category Name</h6>
                  </div>
                  <div class="d-flex align-items-center justify-content-between">
                    <h6 class="card-title m-0">Category Name</h6>
                    <h6 class="card-title m-0">Category Name</h6>
                  </div> -->
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="d-flex justify-content-between">
          <div>
            <a class="previous" (click)="previousOrNext(false)" *ngIf="this.offset != -1">&laquo;
              Previous</a>
          </div>
          <div>
            <a class="next" (click)="previousOrNext(true)">Next &raquo;</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Add modal -->
<ng-template #content let-modal>

  <div class="modal-header">
    <h4 class="modal-title">Add Festival</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>Category</label>
      <select class="form-control text-dark" [(ngModel)]="festival.categoryId">
        <option *ngFor="let category of categories" [value]="category._id">
          {{ category.name }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label>Image</label>
      <input class="form-control text-dark" placeholder="Image" (change)="browse($event)" type="file" name="image" />
    </div>
    <div class="form-group">
      <label>Title</label>
      <input class="form-control text-dark" placeholder="Title" type="text" name="title" [(ngModel)]="festival.title" />
    </div>
    <div class="form-group"
      style="display: flex; width: 100%; align-items: center; justify-content: space-between; flex-direction: row; ">
      <label style="width: 90%; height: 100%; padding-top: 10px; ">Paid</label>
      <input class="form-control text-dark" style="text-align: end; padding: 10px; height: 15px; width: 10%;"
        type="checkbox" name="toggle" [(ngModel)]="festival.isPaid" />
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button type="button" class="btn btn-outline-dark mr-3" (click)="modal.close('Close click')">
      Close
    </button>
    <button type="button" class="btn btn-fill btn-primary" (click)="onSave(modal)">
      Save
    </button>
  </div>
</ng-template>

<!-- Edit Modal -->
<ng-template #editContent let-editModal>
  <div class="modal-header">
    <h4 class="modal-title">Edit Festival</h4>
    <button type="button" class="close" aria-label="Close" (click)="editModal.close('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>Category</label>
      <select class="form-control text-dark" [(ngModel)]="selectedFestival.categoryId">
        <option *ngFor="let category of categories" [value]="category._id">
          {{ category?.name }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label>Image</label>
      <input class="form-control text-dark" placeholder="Image" (change)="browse($event)" type="file" name="image" />
    </div>
    <div class="form-group">
      <label>Title</label>
      <input class="form-control text-dark" placeholder="Title" type="text" name="title"
        [(ngModel)]="selectedFestival.title" />
    </div>
    <div class="form-group"
      style="display: flex; width: 100%; align-items: center; justify-content: space-between; flex-direction: row; ">
      <label style="width: 90%; height: 100%; padding-top: 10px; ">Paid</label>
      <input class="form-control text-dark" style="text-align: end; padding: 10px; height: 15px; width: 10%;"
        type="checkbox" name="toggle" [(ngModel)]="selectedFestival.isPaid" />
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button type="button" class="btn btn-outline-dark mr-3" (click)="editModal.close('Close click')">
      Close
    </button>
    <button type="button" class="btn btn-fill btn-primary" (click)="onEditSave(editModal)">
      Save
    </button>
  </div>
</ng-template>



<!-- Add Notification modal -->

<ng-template #sendNotification let-modal>
  <div *ngIf="isSpinner === true" class="spinner">
    <div class="spinner-border" style="color: rgb(0, 17, 255)"></div>
  </div>
  <div class="modal-header">
    <h4 class="modal-title">Add Notification</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <div class="form-group">
        <label>Notification Title</label>
        <input class="form-control text-dark" #title placeholder="Title" type="text" name="title" />
      </div>
      <div class="form-group">
        <label>Description</label>
        <input class="form-control text-dark" #msg placeholder="Description" type="text" name="msg" />
      </div>
    </div>
    <img [src]="notificationImage" style="height: 130px;">

    <!-- <div class="form-group">
      <label>Image</label>
      <input class="form-control text-dark" #imgurl placeholder="Image" (change)="fileSelect($event)" type="file"
        name="imgurl" />
    </div> -->
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button type="button" class="btn btn-outline-dark mr-3" (click)="modal.close('Close click')">
      Close
    </button>
    <button type="button" class="btn btn-fill btn-primary" (click)="SendNotificationBtn(title.value, msg.value)">
      Send
    </button>
  </div>
</ng-template>