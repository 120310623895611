import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestApiService } from 'src/app/services/rest-api.service';
import Swal from 'sweetalert2';
import {Location} from '@angular/common';

@Component({
  selector: 'app-add-custome-image',
  templateUrl: './add-custome-image.component.html',
  styleUrls: ['./add-custome-image.component.scss'],
})
export class AddCustomeImageComponent implements OnInit {
  selectedFile: any;
  frame: any;
  LocationImg = 'assets/img/imglocation.png';
  phoneIcon = 'assets/icons/ic-phone.svg';
  emailIcon = 'assets/icons/ic-email.svg';
  addressIcon = 'assets/icons/ic-location.svg';
  websiteIcon = 'assets/icons/ic-website.svg';
  photoPosition = 'assets/img/angular2-logo-red.png';

  categoryList = [];
  selectedCatg = '';
  isSpinner = false;
  active;
  isCollapsedTextStyle = 0;
  isCollapsedImgPosition = 0;

  ColorValue;
  fontSize = '18';
  Fcolor;
  Fsize;

  AddText = {
    text: {
      color: '#000',
      left: 1,
      right: 0,
      bottom: 0,
      top: 1,
      fontSize: 0,
    },
    icon: {
      left: 1,
      top: 0,
      width: 0,
      height: 1,
    }
  };

  id
  cat_value;
  textPositionX;
  textPositionY;
  fontPositionX;
  fontPositionY;
  isPaid = false
  panelOpenState: boolean = false;
  constructor(private router: Router, private restApiService: RestApiService,private _location: Location) { }
  // getcompanyid(event) {
  //   this.cat_value = event;
  // }
  colorValue() {
    document.getElementById('addText').style.color = this.ColorValue;
    this.Fcolor = this.ColorValue;
  }
  FontValue() {
    document.getElementById('addText').style.fontSize = this.fontSize + 'px';
    this.Fsize = this.fontSize;
  }
  toggleFun3() {
    if (this.isCollapsedTextStyle == 0) this.isCollapsedTextStyle = 1;
    else this.isCollapsedTextStyle = 0;
  }
  toggleFun4() {
    if (this.isCollapsedImgPosition == 0) this.isCollapsedImgPosition = 1;
    else this.isCollapsedImgPosition = 0;
  }
  isSetText;
  toggleFun5() {
    if (this.isSetText == 0) this.isSetText = 1;
    else this.isSetText = 0;
  }
  showCoords(event) {
    if (this.isCollapsedTextStyle == 1) {
      var x = event.layerX;
      var y = event.layerY;
      var prX = x * 100 / 500;
      var prY = y * 100 / 500;
      this.AddText.text.left = prX
      this.AddText.text.top = prY

      // for api
      var txLeft = `${prX - 30}`;
      var txTop = `${prY - 30}`;


      var clintX = `${x - 30}px`;
      var clintY = `${y - 10}px`;


      this.textPositionX = txLeft;
      this.textPositionY = txTop;

      document.getElementById('AddTextLocation').style.top = clintY;
      document.getElementById('AddTextLocation').style.left = clintX;
    }

    if (this.isCollapsedImgPosition == 1) {
      var xT = event.layerX;
      var yT = event.layerY;
      // var prX = xT * 100 / 500;
      // var prY = yT * 100 / 500;

      // this.AddText.icon.left = prX
      // this.AddText.icon.top = prY
      // for api
      var imgLeft = `${x - 30}`;
      var imgTop = `${y - 30}`;

      var clintX = `${xT - 30}px`;
      var clintY = `${yT - 10}px`;
      this.fontPositionX = imgLeft;
      this.fontPositionY = imgTop;

      document.getElementById('imgLocation').style.top = clintY;
      document.getElementById('imgLocation').style.left = clintX;
    }
  }
  cate;
  edit
  isEdit
  ngOnInit(): void {
    this.getCategory();
    this.isEdit = localStorage.getItem('isEdit');
    this.edit = history.state;

    if (!this.edit) {
      return;
    }
    console.log('edit :>> ', this.edit);
    if (this.isEdit == "true") {
      this.cate = this.edit.categoryId;
      this.AddText.text = this.edit.position.text;
      this.AddText.icon = this.edit.position?.icon;
      this.frame = this.edit.image
      this.id = this.edit._id
      this.isPaid = this.edit.isPaid
      this.cat_value = this.edit.categoryId
    }else{
      this.cat_value = this.edit._id
    }

  }

  getCategory() {
    this.restApiService
      .getCategory()
      .then((res) => {
        if (res && res.code === 200) {
          this.categoryList = res.data;

          // this.categoryList.map((i) => {
          //   if (i.name == this.editdata.name) {
          // this.selectedCatg = i.name;
          //   }
          // });
        }
      })
      .catch((err) => {
        console.log('error is', err);
      });
  }
  goBack() {
    // this.router.navigateByUrl('/custome');
    this._location.back()
  }
  async browse(ev) {
    this.selectedFile = ev.target.files[0];
    this.frame = await this.getBase64(this.selectedFile);
  }
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  onPositionChange(event, varType, field, position) {
    const value = parseFloat(event.target.value);
    this[varType][field][position] = value;
  }

  onSave() {
    this.isSpinner = true;
    const position = this.AddText;

    const params = {
      image: this.selectedFile,
      position: JSON.stringify(position),
      categoryId: this.cat_value,
      isPaid: this.isPaid
    };

    this.restApiService.addCustome(params).then((data) => {
      // this.router.navigateByUrl('/custome');
      this._location.back()
      this.isSpinner = false;
    });

  }

  onEdit() {
    this.isSpinner = true;
    const position = this.AddText;

    const obj = {
      position: position,
      categoryId: this.cat_value,
      isPaid: this.isPaid
    };
    this.restApiService.updateCustom(this.id, obj).then(res => {
      if (res && res.code === 200) {
        this.isSpinner = false;
        this._location.back()
        // this.router.navigateByUrl('/custome');
      }
    }, err => {
      console.log('error is', err);
    });

  }
}
