import { Component, OnInit } from '@angular/core';

declare interface RouteInfo {
  path: string;
  title: string;
  // rtlTitle: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    icon: 'icon-chart-pie-36',
    class: '',
  },
  {
    path: '/all-user-details',
    title: 'Users',
    icon: 'icon-single-02',
    class: '',
  },
  {
    path: '/user',
    title: 'User Business',
    icon: 'icon-single-02',
    class: '',
  },

  {
    path: '/business-category',
    title: 'business category',
    icon: 'icon-bullet-list-67',
    class: '',
  },
  {
    path: '/category',
    title: 'Category',
    icon: 'icon-bullet-list-67',
    class: '',
  },
  // {
  //   path: "/festival",
  //   title: "Festival",
  //   icon: "icon-gift-2",
  //   class: ""
  // },
  // {
  //   path: "/custome",
  //   title: "custom",
  //   icon: "icon-settings-gear-63",
  //   class: ""
  // },
  {
    path: '/deleted-festival',
    title: 'Deleted Post',
    icon: 'icon-gift-2',
    class: '',
  },
  {
    path: '/frames',
    title: 'Frames',
    icon: 'icon-image-02',
    class: '',
  },
  {
    path: '/notification',
    title: 'Notification',
    icon: 'icon-bell-55',
    class: '',
  },
  {
    path: '/subscription',
    title: 'subscription',
    icon: 'icon-money-coins',
    class: '',
  },
  {
    path: '/bulk-messages',
    title: 'Bulk Messages',
    icon: 'icon-money-coins',
    class: '',
  },
  // {
  //   path: "/e-card",
  //   title: "Business E-card",
  //   icon: "icon-credit-card",
  //   class: ""
  // },
  // {
  //   path: "/font",
  //   title: "Font",
  //   icon: "icon-credit-card",
  //   class: ""
  // },
];
export const ROUTES_DESIGNER: RouteInfo[] = [
  {
    path: '/category',
    title: 'Category',
    icon: 'icon-bullet-list-67',
    class: '',
  },
  {
    path: '/deleted-festival',
    title: 'Deleted Festivals',
    icon: 'icon-gift-2',
    class: '',
  },
  {
    path: '/frames',
    title: 'Frames',
    icon: 'icon-image-02',
    class: '',
  },
  // {
  //   path: "/festival",
  //   title: "Festival",
  //   icon: "icon-gift-2",
  //   class: ""
  // },
  // {
  //   path: "/custome",
  //   title: "custome",
  //   icon: "icon-settings-gear-63",
  //   class: ""
  // },
];
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  adminLoginData;

  constructor() {}

  ngOnInit() {
    const { user } = JSON.parse(localStorage.getItem('admin'));
    this.adminLoginData = user.role;
    if (user.role == 'D') {
      this.menuItems = ROUTES_DESIGNER.filter((menuItem) => menuItem);
    } else {
      this.menuItems = ROUTES.filter((menuItem) => menuItem);
    }
  }
  isMobileMenu() {
    if (window.innerWidth > 991) {
      return false;
    }
    return true;
  }
}
