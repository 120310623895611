import { Component, OnInit } from '@angular/core';
import {NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RestApiService } from 'src/app/services/rest-api.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-font',
  templateUrl: './font.component.html',
  styleUrls: ['./font.component.scss']
})
export class FontComponent implements OnInit {
  selectedFont
  getFont = []
  searchName:string;
  fonts = {
    
    name: '',
    link:''
  }
  constructor(private restApiService: RestApiService,private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getFonts()
  }
  openPopup(content, item?) {
    if (item) {
      const obj = {
        
        name: item.name,
        _id: item._id
      }
      
      this.selectedFont = obj

    }
    this.modalService.open(content);
  }
  onSave(modal) {
   
    this.restApiService.addFont(this.fonts).then(res => {
      if (res && res.code === 200) {
        modal.close();
     
        this.getFonts()
      }
    }).catch(err => {
      console.log('error is', err);
    })
  }
  shortDate(arrya: any[]) {
    return arrya.sort((a: any, b: any) => {
      var old = new Date(a.createdAt).getTime();
      var current = new Date(b.createdAt).getTime();
      return current - old;
    });
  }
  getFonts() {
    this.restApiService.getFonts().then(res => {
      if (res && res.code === 200) {
        this.getFont = res.data
        this.getFont = this.shortDate(res.data)
      }
    }).catch(err => {
      console.log('error is', err);
    })
  }
  onDelete(font) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.restApiService.deleteFont(font._id).then(res => {
        
          
          if (res && res.code === 200) {
            Swal.fire(
              'Deleted!',
              'Font has been deleted.',
              'success'
            )
            this.getFonts()
          }
        }).catch(err => {
          console.log('error is', err);
        })

      }
    })
  }
  onEditSave(modal) {
    let obj: any;
    obj = {
      name: this.selectedFont.name
    }
  
    this.restApiService.editFont(this.selectedFont._id, obj).then(res => {
      if (res && res.code === 200) {
        modal.close()
        this.getFonts()
      }
    }).catch(err => {
      console.log('error is', err);
    })
  }
}
