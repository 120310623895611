<div *ngIf="isSpinner == true" class="spinner"
  style="position: fixed; top: 0; width: 100%; height: 100%; z-index: 99999;">
  <div
    style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100%; background-color: rgba(179, 177, 177, 0.191); ">
    <div class="spinner-border" style="color: rgb(0, 17, 255)"></div>
  </div>
</div>
<div class="content" [ngStyle]="{'filter': isSpinner == true ? 'blur(1px)' : 'blur(0px)'}">
  <div class=" row">
    <div class="col-12">
      <div class=" card">
        <div class=" card-header mb-3">
          <div
            style="display: flex;flex-direction: row;align-items:center; justify-content: space-between; width: 100%;">
            <div class="d-flex justify-content-between align-items-center col-12 ">
              <h4 class=" card-title pr-5 col-4">Users Business</h4>
              <input class=" form-control col-8 text-white" type="text" [(ngModel)]="filterTerm" placeholder="search" />
            </div>
          </div>
        </div>
        <div class=" d-flex flex-wrap">
          <div *ngFor="let business of bussinessDetail | filter:filterTerm"
            class="example-box col-12 col-sm-12 col-md-4 col-lg-4 d-flex flex-column festival-card mb-4"
            style="height: max-content;">
            <div class="img-div " style="height: 250px !important; width: 100%;">
              <img [src]="business.logo" *ngIf="business.logo && business.logo !='null'" alt="">
              <img [src]="'assets/img/default-avatar.png'"
                *ngIf="!business.logo || business.logo == '' || business.logo=='null'" alt="">
            </div>
            <div class="card-body w-100  m-0 p-0 mb-3 mt-2 mb-sm-3 mb-md-0 mb-lg-0">
              <div class="d-flex flex-row justify-content-between ">
                <h6 class="card-title m-0 ">Business Name</h6>
                <h5 class="card-title m-0 text-right"> {{business.bussinessName ? business.bussinessName : '-'}}</h5>
              </div>
              <div class="d-flex flex-row justify-content-between p-0 m-0">
                <h6 class="card-title m-0">Business category</h6>
                <h5 class="card-title m-0 text-right">{{business.businesscategory ? business.businesscategory : '-'}}
                </h5>
              </div>
              <div class="d-flex flex-row justify-content-between p-0 m-0">
                <h6 class="card-title m-0">User Name</h6>
                <h5 class="card-title m-0 text-right">{{business.userName ? business.userName : '-'}}</h5>
              </div>
              <div class="d-flex flex-row justify-content-between p-0 m-0">
                <h6 class="card-title m-0">Mobile No</h6>
                <h5 class="card-title m-0">{{business.contactNumber ? business.contactNumber:'-'}}</h5>
              </div>
              <div class="d-flex flex-row justify-content-between p-0 m-0">
                <h6 class="card-title m-0">Email</h6>
                <h5 class="card-title m-0 w-75 text-right">{{business.email ? business.email:'-'}}</h5>
              </div>
              <div class="d-flex flex-row justify-content-between p-0 m-0">
                <h6 class="card-title m-0">address</h6>
                <h5 class="card-title m-0">{{business.address}}</h5>
              </div>
              <div class="d-flex flex-row justify-content-between p-0 m-0">
                <h6 class="card-title m-0">city</h6>
                <h5 class="card-title m-0">{{business.city}}</h5>
              </div>
            </div>
            <div class="card-body d-flex flex-row justify-content-between p-0 mb-2 w-100">
              <div style="width: 35%;">
                <button (click)="onEdit(editContent,business)" class="btn-fill btn-primary edit-delete">Edit</button>
              </div>
              <div style="width: 35%;">
                <button (click)="onDelete(business)" class="btn-fill btn-danger edit-delete">Delete</button>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center text-white p-3">
          <div>
            <h2 class="previous" (click)="previousOrNext(false)" style="cursor:pointer;" *ngIf="this.offset != 0">
              &laquo;
              Previous Page {{offset}} </h2>
          </div>
          <div>
            <h2 class="next" style="cursor: not-allowed;">Page {{offset+1}}</h2>
          </div>
          <div *ngIf="bussinessDetail.length">
            <h2 class="next" (click)="previousOrNext(true)" style="cursor:pointer;">Next Page {{offset+2}} &raquo;</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- Edit Modal -->
<ng-template #editContent let-editModal>
  <div class=" modal-header">
    <h4 class="modal-title">Edit Category</h4>
    <button type="button" class="close" aria-label="Close" (click)="editModal.close('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class=" form-group">
      <!-- <label style="position: absolute; justify-content: center; height: 100%; align-items: center; display: flex; right: 0;  font-size: 12px;">Bussiness Name</label> -->
      <input class=" form-control text-dark" placeholder="bussinessName" type="text"
        [(ngModel)]="selectedCategory.bussinessName" name="categoryName" />
    </div>
    <div class=" form-group">
      <select [(ngModel)]='cate' style="width: 100%; border: 1px solid gray; border-radius: 10px; height: 40px;"
        (change)="getcompanyid($event.target.value)">
        <option *ngFor="let category of bcategories">{{category.businessCategory}}
        </option>
      </select>
    </div>
    <div class=" form-group">
      <input class=" form-control text-dark" placeholder="contactNumber" type="text"
        [(ngModel)]="selectedCategory.contactNumber" name="categoryName" />
    </div>
    <div class=" form-group">
      <input class=" form-control text-dark" placeholder="extracontactNumber" type="text"
        [(ngModel)]="selectedCategory.extracontactNumber" name="categoryName" />
    </div>
    <div class=" form-group">
      <input class=" form-control text-dark" placeholder="status" type="text" [(ngModel)]="selectedCategory.status"
        name="categoryName" />
    </div>
    <div class=" form-group">
      <input class=" form-control text-dark" placeholder="userName" type="text" [(ngModel)]="selectedCategory.userName"
        name="categoryName" />
    </div>
    <div class=" form-group">
      <input class=" form-control text-dark" placeholder="designation" type="text"
        [(ngModel)]="selectedCategory.designation" name="categoryName" />
    </div>
    <!-- <div class=" form-group">
      <input class=" form-control text-dark" placeholder="logo" type="file" [(ngModel)]="selectedCategory.logo"
        name="categoryName" />
    </div> -->
    <div class=" form-group">
      <input class=" form-control text-dark" placeholder="website" type="text" [(ngModel)]="selectedCategory.website"
        name="categoryName" />
    </div>
    <div class=" form-group">
      <input class=" form-control text-dark" placeholder="email" type="text" [(ngModel)]="selectedCategory.email"
        name="categoryName" />
    </div>
    <div class=" form-group">
      <input class=" form-control text-dark" placeholder="address" type="text" [(ngModel)]="selectedCategory.address"
        name="categoryName" />
    </div>
    <div class=" form-group">
      <input class=" form-control text-dark" placeholder="city" type="text" [(ngModel)]="selectedCategory.city"
        name="categoryName" />
    </div>
    <div class=" form-group">
      <input class=" form-control text-dark" placeholder="state" type="text" [(ngModel)]="selectedCategory.state"
        name="categoryName" />
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button type="button" class="btn btn-outline-dark mr-3" (click)="editModal.close('Close click')">Close</button>
    <button type="button" class="btn btn-fill btn-primary" (click)="onEditSave(editModal)">Save</button>
  </div>
</ng-template>