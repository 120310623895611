 
import { environment } from "src/environments/environment";
export class ApiConfiguration {
  protected baseUrl: string = environment.apiUrl;
  // protected baseUrl: string = "https://brandspot.herokuapp.com/api/";
  readonly adminLogin = 'adminLogin';
  readonly users = 'users'
  readonly searchUser = 'searchUser'
  readonly category = 'category'
  readonly searchCategory = 'searchCategory'
  readonly getSearchedDeletedFestival = 'getSearchedDeletedFestival'
  readonly getSearchedDeletedCustom = 'getSearchedDeletedCustom'
  readonly categorydelete = 'categorydelete'
  readonly categoryactive = 'categoryactive'
  readonly dashboardItems = 'dashboardItems'
  readonly festivals = 'festivals'
  readonly categoryWiseFestival = 'categoryWiseFestival';
  readonly categoryWiseOffsetFestival = 'categoryWiseOffsetFestival/';
  readonly categoryWiseDeletedFestival = 'categoryWiseDeletedFestival/';
  readonly categoryWiseDeletedCustom = 'categoryWiseDeletedCustom/';
  readonly deletePermanent = 'deletePermanent';
  readonly festival = 'festival'
  readonly activefestival = 'activefestival'
  readonly frames = 'frames'
  readonly frame = 'frame'
  readonly ecard = 'ecard'
  readonly ecards = 'ecards'
  readonly custome = 'custom'
  readonly customs = 'customs'
  readonly categoryWiseCustom = 'categoryWiseCustom';
  readonly notifications = 'notifications'
  readonly notification = 'notification'
  readonly fonts = 'allStyle'
  readonly font = 'addStyle'
  readonly fontdelete = 'deleteStyle'
  readonly ecardChangeIdx = 'ecardChangeIdx'
  readonly customChangeIdx = 'customChangeIdx'
  readonly changeFestivalIdx = 'changeFestivalIdx'
  readonly changeFrameIdx = 'changeFrameIdx'
  readonly changeCategoryIdx = 'changeCategoryIdx'
  readonly changeSubPlanIdx = 'changeSubPlanIdx'
  readonly changeBusinessCategoryIdx = 'changeBusinessCategoryIdx'
  readonly appversion = 'editappversion'
  readonly getversion = 'appversion'
  readonly updateCustom = 'editCostom'
  readonly updateFrame = 'editFrame'
  readonly updateEcard = 'editEcard'
  readonly bussinessDetail = 'bussinessDetail'
  readonly bussinessCategory = 'bussinessCategory'
  readonly deleteBusinessCategory = 'deleteBusinessCategory';
  readonly businessDetail = 'businessDetail';
  readonly searchBusinessCategory = 'searchBusinessCategory';
  readonly addsubscriptionPlan = 'addsubPlan';
  readonly getsubscriptionPlan = 'getsubPlan';
  readonly deletesubscriptionPlan = 'deletesubPlan';
  readonly UserPlanUpdate = 'userEdit';
  readonly primeUserCount = 'primeCount'

  readonly updateUser = 'user';
  readonly getUser = 'users';
  readonly signup = 'signup';
  readonly popupbanner = 'popupbanner';
  readonly popupbannerUpdate = 'popupbannerUpdate';
  
  //generate and download excelsheet
  readonly generateSheet = 'primeUserExcelsheet';
  readonly downloadExcelSheet = 'downloadExcelSheet';
  readonly downloadPostZip = 'downloadPostZip';
  readonly getAllUserPost = 'getAllUserPost';
  readonly setAllUserPost = 'setAllUserPost';
  readonly getSingleFestivalForAdmin = "getSingleFestivalForAdmin"

  readonly expireSheet = 'primeUserExpireExcelsheet';

}
