<div *ngIf="isSpinner == true" class="spinner"
    style="position: fixed; top: 0; width: 100%; height: 100%; z-index: 99999;">
    <div
        style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100%; background-color: rgba(179, 177, 177, 0.191); ">
        <div class="spinner-border" style="color: rgb(0, 17, 255)"></div>
    </div>
</div>
<div class=" content" [ngStyle]="{'filter': isSpinner == true ? 'blur(1px)' : 'blur(0px)'}">
    <div class=" row">
        <div class=" col-md-12">
            <div class=" card">
                <div class=" card-header">
                    <h4 class=" card-title">Business Category</h4>
                    <div
                        style="display: flex;flex-direction: row;align-items:center; justify-content: space-between; width: 90%;">
                        <button class=" btn btn-fill btn-primary" data-target="#searchModal" data-toggle="modal"
                            (click)="open(content)">Add</button>

                        <input class=" form-control text-white" type="text" name="search" [(ngModel)]="searchName"
                            placeholder="search" style="width: 30%;"
                            (ngModelChange)="getSearchedBusinessCategory($event)" />
                    </div>
                </div>
                <div class=" card-body">
                    <div class=" table-responsive">
                        <table class=" table tablesorter" id="">
                            <thead class=" text-primary example-box" style="width: 100%">
                                <tr class="header-tag">
                                    <th>Name</th>
                                    <th>Created At</th>
                                    <th>Updated At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody style="justify-content: center;">
                                <div class="example-list" cdkDropList (cdkDropListDropped)="drop($event)">
                                    <tr *ngFor="let category of bcategories |filter:searchName" class="example-box"
                                        [cdkDropData]="category" cdkDrag>
                                        <td>{{category.businessCategory}}</td>
                                        <td>{{category.createdAt | date : 'dd-MM-yyyy'}}</td>
                                        <td>{{category.updatedAt | date : 'dd-MM-yyyy'}}</td>
                                        <td>
                                            <button class=" btn btn-fill btn-primary mr-3"
                                                (click)="onEdit(editContent,category)">Edit</button>
                                            <button class="btn btn-fill btn-danger" (click)="onDeleteBusinessCategory(category)">
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                </div>
                                <tr *ngIf="bcategories.length===0">
                                    <td colspan="4" class="text-center">
                                        BusinessCategory not found
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="d-flex justify-content-between align-items-center text-white p-3">
                        <div>
                            <h2 class="previous" (click)="previousOrNext(false)" style="cursor:pointer;"
                                *ngIf="this.offset != 0">
                                &laquo;
                                Previous Page {{offset}} </h2>
                        </div>
                        <div>
                            <h2 class="next" style="cursor: not-allowed;">Page {{offset+1}}</h2>
                        </div>
                        <div *ngIf="bcategories.length">
                            <h2 class="next" (click)="previousOrNext(true)" style="cursor:pointer;">Next Page
                                {{offset+2}} &raquo;</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Add modal -->
<ng-template #content let-modal>
    <div class=" modal-header">
        <h4 class="modal-title">Add Business Category</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.close('Close click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class=" form-group">
            <input class=" form-control text-dark" type="text" [(ngModel)]="bselectedCategory" />
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-end">
        <button type="button" class="btn btn-outline-dark mr-3" (click)="modal.close('Close click')">Close</button>
        <button type="button" class="btn btn-fill btn-primary" (click)="onSave(modal)">Save</button>
    </div>
</ng-template>

<!-- Edit Modal -->
<ng-template #editContent let-editModal>
    <div class=" modal-header">
        <h4 class="modal-title">Edit Category</h4>
        <button type="button" class="close" aria-label="Close" (click)="editModal.close('Close click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class=" form-group">
            <input class=" form-control text-dark" placeholder="Name" type="text" [(ngModel)]="bselectedCategory" />
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-end">
        <button type="button" class="btn btn-outline-dark mr-3" (click)="editModal.close('Close click')">Close</button>
        <button type="button" class="btn btn-fill btn-primary" (click)="onEditSave(editModal)">Save</button>
    </div>
</ng-template>