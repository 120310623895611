<div class=" content">
    <div class=" row">
        <div class=" col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4 class=" card-title">Send WhatsApp Bulk Messages</h4>

                </div>
                <div class="card-body d-flex flex-wrap">
                    <div class="col-12 d-flex flex-row flex-wrap align-items-center justify-content-between">
                        <label>
                            upload excel :
                            <input type="file" (change)="onFileChange($event)" multiple="false" />
                        </label>

                        <label>
                            upload image :
                            <input type="file" (change)="getImage($event)" multiple="false" />
                        </label>

                        <img [src]="img" height="100px" width="100px" alt="">

                        <button (click)="sendMsg()">Send Message</button>
                    </div>

                    <table class="col-12 mt-5">
                        <tbody class="bulkMsg">
                            <tr *ngFor="let row of data">
                                <td *ngFor="let val of row">
                                    {{val}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>