import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { ApiConfiguration } from './configuration';

@Injectable({
  providedIn: "root",
})
export class ApiCallService extends ApiConfiguration {

  token: any = {};
  uploadSub = new BehaviorSubject<any>(0);

  constructor(public http: HttpClient) {
    super();
    const token = localStorage.getItem('token');
    this.token = token ? token : null;
  }

  setHeaderToken(token) {
    this.token = token;
    localStorage.setItem('token', token);
  }

  getHeader() {
    return {
      headers: {
        Authorization: 'bearer ' + this.token
        }
    };
  }

  public getData(subUrl: string, token = true): Promise<any> {
    return new Promise((resolve, reject) => {
      const request: string = this.baseUrl + subUrl;
      this.http
        .get(request,
          token ? this.getHeader() : {})
        .subscribe(
          data => resolve(data),
          error => reject(error)
        );
    });
  }

  public postData(subUrl: string, data: any, token = true): Promise<any> {
    return new Promise((resolve, reject) => {
      const request: string = this.baseUrl + subUrl;
      this.http.post(request, data, token ? this.getHeader() : {})
        .subscribe(
          res => resolve(res),
          error => {
            console.log('Main Error :', error);
            reject(error);
          }
        );
    });
  }

  public putData(subUrl: string, data: any, token = true): Promise<any> {
    return new Promise((resolve, reject) => {
      const request: string = this.baseUrl + subUrl;
      this.http
        .put(request,
          data,
          token ? this.getHeader() : {})
        .subscribe(
          res => resolve(res),
          error => reject(error)
        );
    });
  }

  public deleteData(subUrl: string, token = true): Promise<any> {
    return new Promise((resolve, reject) => {
      const request: string = this.baseUrl + subUrl;
      this.http
        .delete(request,
          token ? this.getHeader() : {})
        .subscribe(
          res => resolve(res),
          error => reject(error)
        );
    });
  }

  public postDataWithImage(subUrl: string, data: any, token = true): Promise<any> {
    const form_data = new FormData();
    for (const key in data) {
      if (key === 'image') {
        form_data.append('image', data[key], data[key].name);
      } else {
        form_data.append(key, data[key]);
      }
    }
    return new Promise((resolve, reject) => {
      const request: string = this.baseUrl + subUrl;
      this.http.post(request, form_data, token ? this.getHeader() : {})
        .subscribe(
          res => resolve(res),
          error => {
            console.log('Main Error :', error);
            reject(error);
          }
        );
    });
  }
  public putWithImage(subUrl: string, data: any, token = true): Promise<any> {
    const form_data = new FormData();
   
    for (const key in data) {
      if (key && key === 'image') {
        form_data.append('image', data[key], data[key] && data[key].name);
      } else {
        form_data.append(key, data[key]);
      }
    }
    return new Promise((resolve, reject) => {
      const request: string = this.baseUrl + subUrl;
      this.http
        .put(request, form_data, token ? this.getHeader() : {})
        .subscribe(
          res => resolve(res),
          error => reject(error)
        );
    });
  }
}
