<div class=" content">
  <div class=" row">
    <div class=" col-md-12">
      <button class=" btn btn-fill btn-primary mr-3" (click)="goBack()">Back</button>
    </div>
    <div class="col-md-12">
      <div *ngIf="isSpinner === true" class="spinner">
        <div class="spinner-border" style="color: rgb(0, 17, 255)"></div>
      </div>
      <div class=" card p-4">
        <div class="row">
          <div class="col-md-12 vertical-center">
            <div class="preview">
              <div class="main_img_div">
                <div class="img_div">
                  <ng-container *ngIf="frame">
                    <img class="logo-svg" [src]=" cardlogo" [ngStyle]="{
                        'left': logo.icon.left === 0? 'auto' :logo.icon.left + '%',
                        'top': logo.icon.top === 0? 'auto' : logo.icon.top + '%',
                        'width':logo.icon.img_width === 0? 'auto' : logo.icon.img_width + 'px',
                        'height':logo.icon.img_height === 0? 'auto' : logo.icon.img_height + 'px'
                      }">
                    <div class="phone-text" [ngStyle]="{         
                    'color': personName.text.color,
                    'fontSize': personName.text.fontSize === 0? 'auto' :personName.text.fontSize + 'px',
                    'left': personName.text.left === 0? 'auto' :personName.text.left + '%',
                    'right':personName.text.right === 0? 'auto' : personName.text.right + '%',
                    'top':personName.text.top === 0? 'auto' : personName.text.top + '%',
                    'bottom':personName.text.bottom  === 0? 'auto' : personName.text.bottom + '%'
                  }">
                       Person Name
                    </div>

                    <div class="phone-text" [ngStyle]="{
                    'color': personDesignation.text.color,
                    'fontSize': personDesignation.text.fontSize === 0? 'auto' :personDesignation.text.fontSize + 'px',
                    'left': personDesignation.text.left === 0? 'auto' :personDesignation.text.left + '%',
                    'right':personDesignation.text.right === 0? 'auto' : personDesignation.text.right + '%',
                    'top':personDesignation.text.top === 0? 'auto' : personDesignation.text.top + '%',
                    'bottom':personDesignation.text.bottom  === 0? 'auto' : personDesignation.text.bottom + '%'
                  }">
                      Person Designation
                    </div>
                    <img class="phone-svg" [src]="phone.icon.otherIcon" [ngStyle]="{
                      'width': phone.icon.width ===0? 'auto': phone.icon.width +'%',
                      'height':  phone.icon.height ===0? 'auto': phone.icon.height +'%',
                      'left': phone.icon.left === 0? 'auto' :phone.icon.left + '%',
                      'right':phone.icon.right === 0? 'auto' : phone.icon.right + '%',
                      'top':phone.icon.top === 0? 'auto' : phone.icon.top + '%',
                      'bottom':phone.icon.bottom  === 0? 'auto' : phone.icon.bottom + '%'
                    }">
                    <div class="phone-text" [ngStyle]="{
                      'width': phone.text.width === 0? 'auto' : phone.text.width + '%',
                      'color': phone.text.color,
                      'fontSize': phone.text.fontSize === 0? 'auto' :phone.text.fontSize + 'px',
                      'left': phone.text.left === 0? 'auto' :phone.text.left + '%',
                      'right':phone.text.right === 0? 'auto' : phone.text.right + '%',
                      'top':phone.text.top === 0? 'auto' : phone.text.top + '%',
                      'bottom':phone.text.bottom  === 0? 'auto' : phone.text.bottom + '%'
                     }">
                        +91 99259 87521
                    </div>
                    <img class="website-svg" [src]="website.icon.otherIcon" [ngStyle]="{
                      'width': website.icon.width ===0? 'auto': website.icon.width +'%',
                      'height':  website.icon.height ===0? 'auto': website.icon.height +'%',
                      'left': website.icon.left === 0? 'auto' :website.icon.left + '%',
                      'right':website.icon.right === 0? 'auto' : website.icon.right + '%',
                      'top': website.icon.top === 0? 'auto' : website.icon.top + '%',
                      'bottom':website.icon.bottom === 0? 'auto' : website.icon.bottom + '%'
                     }">
                    <div class="website-text" [ngStyle]="{
                      'width': website.text.width === 0? 'auto' : website.text.width + '%',
                      'color': website.text.color,
                      'fontSize': website.text.fontSize === 0? 'auto' :website.text.fontSize + 'px',
                      'left': website.text.left === 0? 'auto' :website.text.left + '%',
                      'right':website.text.right === 0? 'auto' : website.text.right + '%',
                      'top':website.text.top === 0? 'auto' : website.text.top + '%',
                      'bottom':website.text.bottom  === 0? 'auto' : website.text.bottom + '%'
                      }">
                      www.shapeme.com
                                        </div>
                    <img class="email-svg" [src]="email.icon.otherIcon" [ngStyle]="{
                      'width': email.icon.width ===0? 'auto': email.icon.width +'%',
                      'height':  email.icon.height ===0? 'auto': email.icon.height +'%',
                      'left': email.icon.left === 0? 'auto' :email.icon.left + '%',
                      'right':email.icon.right === 0? 'auto' : email.icon.right + '%',
                      'top':email.icon.top === 0? 'auto' : email.icon.top + '%',
                      'bottom':email.icon.bottom  === 0? 'auto' : email.icon.bottom + '%'
                    }">
                    <div class="email-text" [ngStyle]="{
                      'width': email.text.width === 0? 'auto' : email.text.width + '%',
                      'color': email.text.color,
                      'fontSize': email.text.fontSize === 0? 'auto' :email.text.fontSize + 'px',
                      'left': email.text.left === 0? 'auto' :email.text.left + '%',
                      'right':email.text.right === 0? 'auto' : email.text.right + '%',
                      'top':email.text.top === 0? 'auto' : email.text.top + '%',
                      'bottom':email.text.bottom  === 0? 'auto' : email.text.bottom + '%'
                     }">
                      <div>
                      shivstore95@gmail.com
                      </div>
                    </div>
                    <img class="address-svg" [src]="address.icon.otherIcon" [ngStyle]="{
                      'width': address.icon.width ===0? 'auto': address.icon.width +'%',
                      'height':  address.icon.height ===0? 'auto': address.icon.height +'%',
                      'left': address.icon.left === 0? 'auto' :address.icon.left + '%',
                      'right':address.icon.right === 0? 'auto' : address.icon.right + '%',
                      'top':address.icon.top === 0? 'auto' : address.icon.top + '%',
                      'bottom':address.icon.bottom  === 0? 'auto' : address.icon.bottom + '%'
                    }">
                    <div class="address-text" [ngStyle]="{
                      'width': address.text.width === 0? 'auto' : address.text.width + '%',
                      'color': address.text.color,
                      'fontSize': address.text.fontSize === 0? 'auto' :address.text.fontSize + 'px',
                      'left': address.text.left === 0? 'auto' :address.text.left + '%',
                      'right':address.text.right === 0? 'auto' : address.text.right + '%',
                      'top':address.text.top === 0? 'auto' : address.text.top + '%',
                      'bottom':address.text.bottom  === 0? 'auto' : address.text.bottom + '%'
                     }">
                      <div>
                      Kailash Complex, Shop No. 13, Street No. 6 Talav Darvaja, Junagadh
                      </div>
                    </div>
                  </ng-container>
                  <!-- <img class="preview-image" src="assets/img/happy-janmashtami.png" alt=""> -->
                  <img class="preview-image" [src]="frame" alt="Select Images">

                </div>
                <!-- Edit Photo Side Menu -->
                <div style="width: 5%;"></div>

                <div class="sidePhotoEditMenu" id="style-1">
                  <h1>Add Details</h1>

                  <div class="faq-drawer">
                    <input class="faq-drawer__trigger" id="faq-drawer" type="checkbox" />
                    <label class="faq-drawer__title" for="faq-drawer">Card </label>
                    <div class="faq-drawer__content-wrapper">
                      <div class="faq-drawer__content">
                        <div class=" row">
                          <div class=" col-md-12">
                            <div class=" form-group">
                              <label>Select Card</label>
                              <input class=" form-control text-dark" placeholder="Image" (change)="browse($event)"

                                type="file" name="image" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--Logo icon position -->
                  <div class="faq-drawer">
                    <input class="faq-drawer__trigger" id="faq-drawer0" (click)="toggleFun0()" type="checkbox" />
                    <label class="faq-drawer__title" for="faq-drawer0">Set Logo</label>
                    <div class="faq-drawer__content-wrapper">
                      <div class="faq-drawer__content">
                        <label>Select Logo Position</label>
                        <div class="row">
                          <!--Logo icon position -->
                          <div class=" col-md-4">
                            <div class=" form-group">
                              <label>icon Left </label>
                              <input class=" form-control" type="number" max="100" min="0"
                              [value]= "logo.icon.left"
                                (input)="onPositionChange($event,'logo','icon','left')" />
                            </div>
                          </div>
                          <div class=" col-md-4">
                            <div class=" form-group">
                              <label>icon Top </label>
                              <input class=" form-control" type="number" max="100" min="0"
                              [value]= "logo.icon.top"
                                (input)="onPositionChange($event,'logo','icon','top')" />
                            </div>
                          </div>
                          <div class=" col-md-4">
                            <div class=" form-group">
                              <label>icon Width </label>
                              <input class=" form-control" type="number" max="400" min="40"
                              [value]= "logo.icon.img_width"
                                (input)="onChangeWidthHeight($event,'logo','icon','img_width')" />
                            </div>
                          </div>
                          <div class=" col-md-4">
                            <div class=" form-group">
                              <label>icon Height</label>
                              <input class=" form-control" type="number" max="400" min="40"
                              [value]= "logo.icon.img_height"
                                (input)="onChangeWidthHeight($event,'logo','icon','img_height')" />
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <!--Person Name position -->
                  <div class="faq-drawer">
                    <input class="faq-drawer__trigger" id="faq-drawer6" (click)="toggleFun6()" type="checkbox" />
                    <label class="faq-drawer__title" for="faq-drawer6">Set Person Name</label>
                    <div class="faq-drawer__content-wrapper">
                      <div class="faq-drawer__content">
                        <label>Select Person Name Position</label>
                        <div class="row">
                          <!--Person Name position -->
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text Left </label>
                              <input class=" form-control" type="number" max="94" min="0"
                              [value]= "personName.text.left"
                                (input)="onPositionChange($event,'personName','text','left')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text Top </label>
                              <input class=" form-control" type="number" max="94" min="0"
                              [value]= "personName.text.top"
                                (input)="onPositionChange($event,'personName','text','top')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text Size </label>
                              <input class=" form-control" type="number" min="10" max="35"
                              [value]= "personName.text.fontSize"
                                (input)="onFontSizeChange($event,'personName','text','fontSize')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text Color </label>
                              <input class=" form-control" type="color"
                              [value]= "personName.text.color"
                                (input)="FontValue($event,'personName','text','color')" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--Person Designation position -->
                  <div class="faq-drawer">
                    <input class="faq-drawer__trigger" id="faq-drawer7" (click)="toggleFun7()" type="checkbox" />
                    <label class="faq-drawer__title" for="faq-drawer7">Set Person Designation</label>
                    <div class="faq-drawer__content-wrapper">
                      <div class="faq-drawer__content">
                        <label>Select Person Designation Position</label>
                        <div class="row">
                          <!--Person Designation position -->
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text Left </label>
                              <input class=" form-control" type="number" max="94" min="0"
                              [value]= "personDesignation.text.left"
                                (input)="onPositionChange($event,'personDesignation','text','left')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text Top </label>
                              <input class=" form-control" type="number" max="94" min="0"
                              [value]= "personDesignation.text.top"
                                (input)="onPositionChange($event,'personDesignation','text','top')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text Size </label>
                              <input class=" form-control" type="number" min="10" max="22"
                              [value]= "personDesignation.text.fontSize"
                                (input)="onFontSizeChange($event,'personDesignation','text','fontSize')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text Color </label>
                              <input class=" form-control" type="color"
                              [value]= "personDesignation.text.color"
                                (input)="FontValue($event,'personDesignation','text','color')" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--Phone Number text position -->
                  <div class="faq-drawer">
                    <input class="faq-drawer__trigger" id="faq-drawer1" (click)="toggleFun1()" type="checkbox" />
                    <label class="faq-drawer__title" for="faq-drawer1">Phone Number </label>
                    <div class="faq-drawer__content-wrapper">
                      <div class="faq-drawer__content">
                        <label>Select Phone No. Text Position</label>
                        <div class="row">
                          <!--Phone Number text position -->
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text Left </label>
                              <input class=" form-control" type="number" max="94" min="0"
                              [value]= "phone.text.left"
                                (input)="onPositionChange($event,'phone','text','left')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text Top </label>
                              <input class=" form-control" type="number" max="94" min="0"
                                (input)="onPositionChange($event,'phone','text','top')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text Size </label>
                              <input class=" form-control" type="number" min="10" max="22"
                              [value]= "phone.text.fontSize"
                                (input)="onFontSizeChange($event,'phone','text','fontSize')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text Color </label>
                              <input class=" form-control" type="color"
                              [value]= "phone.text.color"
                                (input)="FontValue($event,'phone','text','color')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text Width </label>
                              <input class=" form-control" type="number" max="94" min="0"
                              [value]= "phone.text.width"
                                (input)="onChangeWidthHeight($event,'phone','text','width')" />
                            </div>
                          </div>
                        </div>
                        <hr>
                      </div>
                    </div>
                  </div>
                  <!--Email text position -->
                  <div class="faq-drawer">
                    <input class="faq-drawer__trigger" id="faq-drawer4" (click)="toggleFun4()" type="checkbox" />
                    <label class="faq-drawer__title" for="faq-drawer4">Email</label>
                    <div class="faq-drawer__content-wrapper">
                      <div class="faq-drawer__content">
                        <label>Select Email Text Position</label>
                        <div class="row">
                          <!--Email text position -->
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text left </label>
                              <input class=" form-control" type="number" max="94" min="0"
                              [value]= "email.text.left"
                                (input)="onPositionChange($event,'email','text','left')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text top </label>
                              <input class=" form-control" type="number" max="94" min="0"
                              [value]= "email.text.top"
                                (input)="onPositionChange($event,'email','text','top')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text size </label>
                              <input class=" form-control" type="number" min="10" max="35"
                              [value]= "email.text.fontSize"
                                (input)="onFontSizeChange($event,'email','text','fontSize')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text color </label>
                              <input class=" form-control" type="color" min="10" max="22"
                              [value]= "email.text.color"
                                (input)="FontValue($event,'email','text','color')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text Width </label>
                              <input class=" form-control" type="number" max="94" min="0"
                              [value]= "email.text.width"
                                (input)="onChangeWidthHeight($event,'email','text','width')" />
                            </div>
                          </div>
                        </div>
                        <hr>


                      </div>
                    </div>
                  </div>
                  <!--website text position -->
                  <div class="faq-drawer">
                    <input class="faq-drawer__trigger" id="faq-drawer3" (click)="toggleFun3()" type="checkbox" />
                    <label class="faq-drawer__title" for="faq-drawer3">Website</label>
                    <div class="faq-drawer__content-wrapper">
                      <div class="faq-drawer__content">
                        <label>Select Website Text Position</label>
                        <div class="row">
                          <!--website text position -->
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text left </label>
                              <input class=" form-control" type="number" max="94" min="0"
                              [value]= "website.text.left"
                                (input)="onPositionChange($event,'website','text','left')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text top </label>
                              <input class=" form-control" type="number" max="94" min="0"
                              [value]= "website.text.top"
                                (input)="onPositionChange($event,'website','text','top')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text color </label>
                              <input class=" form-control" type="number" min="10" max="22"
                              [value]= "website.text.fontSize"
                                (input)="onFontSizeChange($event,'website','text','fontSize')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text color </label>
                              <input class=" form-control" type="color"
                              [value]= "website.text.color"
                                (input)="FontValue($event,'website','text','color')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text Width </label>
                              <input class=" form-control" type="number" max="94" min="0"
                              [value]= "website.text.width"
                                (input)="onChangeWidthHeight($event,'website','text','width')" />
                            </div>
                          </div>
                        </div>
                        <hr>


                      </div>
                    </div>
                  </div>
                  <!--Address text position -->
                  <div class="faq-drawer">
                    <input class="faq-drawer__trigger" id="faq-drawer5" (click)="toggleFun5()" type="checkbox" />
                    <label class="faq-drawer__title" for="faq-drawer5">Address</label>
                    <div class="faq-drawer__content-wrapper">
                      <div class="faq-drawer__content">
                        <label>Select Address Text Position</label>
                        <div class="row">
                          <!--Address text position -->
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text left </label>
                              <input class=" form-control" type="number" max="94" min="0"
                              [value]= "address.text.left"
                                (input)="onPositionChange($event,'address','text','left')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text top </label>
                              <input class=" form-control" type="number" max="94" min="0"
                              [value]= "address.text.top"
                                (input)="onPositionChange($event,'address','text','top')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text size </label>
                              <input class=" form-control" type="number" min="10" max="35"
                              [value]= "address.text.fontSize"
                                (input)="onFontSizeChange($event,'address','text','fontSize')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text color </label>
                              <input class=" form-control" type="color" min="10" max="22"
                              [value]= "address.text.color"
                                (input)="FontValue($event,'address','text','color')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text Width </label>
                              <input class=" form-control" type="number" max="94" min="0"
                              [value]= "address.text.width"
                                (input)="onChangeWidthHeight($event,'address','text','width')" />
                            </div>
                          </div>
                        </div>
                        <hr>

                      </div>
                    </div>
                  </div>


                  <!-- Phone Icon Position -->
                  <div class="faq-drawer">
                    <input class="faq-drawer__trigger" id="faq-drawer8" (click)="toggleFun8()" type="checkbox" />
                    <label class="faq-drawer__title" for="faq-drawer8">Phone Icon Position</label>
                    <div class="faq-drawer__content-wrapper">
                      <div class="faq-drawer__content">
                        <div class="row">
                          <!--Phone icon position -->
                          <div class=" col-md-12">
                            <div class=" form-group">
                              <label>Other icon (.png, .jpg)</label>
                              <input class=" form-control" type="file" (change)="browsePhoneIcon($event)" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>icon Width </label>
                              <input class=" form-control" type="number" max="100" min="0"
                              [value]="phone.icon.width"
                                (input)="onChangeWidthHeight($event,'phone','icon','width')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>icon Height</label>
                              <input class=" form-control" type="number" max="100" min="0"
                              [value]="phone.icon.height"
                                (input)="onChangeWidthHeight($event,'phone','icon','height')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>icon left</label>
                              <input class=" form-control" type="number" max="100" min="0"
                              [value]="phone.icon.left"
                                (input)="onPositionChange($event,'phone','icon','left')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>icon top </label>
                              <input class=" form-control" type="number" max="100" min="0"
                              [value]="phone.icon.top"
                                (input)="onPositionChange($event,'phone','icon','top')" />
                            </div>
                          </div>

                        </div>
                        <hr>

                      </div>
                    </div>
                  </div>
                  <!-- Email Icon Position -->
                  <div class="faq-drawer">
                    <input class="faq-drawer__trigger" id="faq-drawer9" (click)="toggleFun9()" type="checkbox" />
                    <label class="faq-drawer__title" for="faq-drawer9">Email Icon Position</label>
                    <div class="faq-drawer__content-wrapper">
                      <div class="faq-drawer__content">
                        <div class="row">
                          <!--Email icon position -->
                          <div class=" col-md-12">
                            <div class=" form-group">
                              <label>Other icon (.png, .jpg)</label>
                              <input class=" form-control" type="file" (change)="browseEmailIcon($event)" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>icon Width </label>
                              <input class=" form-control" type="number" max="100" min="0"
                              [value]="email.icon.width"
                                (input)="onChangeWidthHeight($event,'email','icon','width')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>icon Height</label>
                              <input class=" form-control" type="number" max="100" min="0"
                              [value]="email.icon.height"
                                (input)="onChangeWidthHeight($event,'email','icon','height')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>icon left</label>
                              <input class=" form-control" type="number" max="100" min="0"
                              [value]="email.icon.left"
                                (input)="onPositionChange($event,'email','icon','left')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>icon top </label>
                              <input class=" form-control" type="number" max="100" min="0"
                              [value]="email.icon.top"
                                (input)="onPositionChange($event,'email','icon','top')" />
                            </div>
                          </div>
                        </div>
                        <hr>
                      </div>
                    </div>
                  </div>
                  <!-- Website Icon Position -->
                  <div class="faq-drawer">
                    <input class="faq-drawer__trigger" id="faq-drawer10" (click)="toggleFun10()" type="checkbox" />
                    <label class="faq-drawer__title" for="faq-drawer10">Website Icon Position</label>
                    <div class="faq-drawer__content-wrapper">
                      <div class="faq-drawer__content">
                        <div class="row">
                          <!--Website icon position -->
                          <div class=" col-md-12">
                            <div class=" form-group">
                              <label>Other icon (.png, .jpg)</label>
                              <input class=" form-control" type="file" (change)="browseWebIcon($event)" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>icon Width </label>
                              <input class=" form-control" type="number" max="100" min="0"
                              [value]="website.icon.width"
                                (input)="onChangeWidthHeight($event,'website','icon','width')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>icon Height</label>
                              <input class=" form-control" type="number" max="100" min="0"
                              [value]="website.icon.height"
                                (input)="onChangeWidthHeight($event,'website','icon','height')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>icon left</label>
                              <input class=" form-control" type="number" max="100" min="0"
                              [value]="website.icon.left"
                                (input)="onPositionChange($event,'website','icon','left')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>icon top </label>
                              <input class=" form-control" type="number" max="100" min="0"
                              [value]="website.icon.top"
                                (input)="onPositionChange($event,'website','icon','top')" />
                            </div>
                          </div>
                        </div>
                        <hr>
                      </div>
                    </div>
                  </div>
                  <!-- Address Icon Position -->
                  <div class="faq-drawer">
                    <input class="faq-drawer__trigger" id="faq-drawer11" (click)="toggleFun11()" type="checkbox" />
                    <label class="faq-drawer__title" for="faq-drawer11">Address Icon Position</label>
                    <div class="faq-drawer__content-wrapper">
                      <div class="faq-drawer__content">
                        <div class="row">
                          <!--Address icon position -->
                          <div class=" col-md-12">
                            <div class=" form-group">
                              <label>Other icon (.png, .jpg)</label>
                              <input class=" form-control" type="file" (change)="browseAddressIcon($event)" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>icon Width </label>
                              <input class=" form-control" type="number" max="100" min="0"
                              [value]="address.icon.width"
                                (input)="onChangeWidthHeight($event,'address','icon','width')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>icon Height</label>
                              <input class=" form-control" type="number" max="100" min="0"
                              [value]="address.icon.height"
                                (input)="onChangeWidthHeight($event,'address','icon','height')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>icon left</label>
                              <input class=" form-control" type="number" max="100" min="0"
                              [value]="address.icon.left"
                                (input)="onPositionChange($event,'address','icon','left')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>icon top </label>
                              <input class=" form-control" type="number" max="100" min="0"
                              [value]="address.icon.top"
                                (input)="onPositionChange($event,'address','icon','top')" />
                            </div>
                          </div>
                        </div>
                        <hr>
                      </div>
                    </div>
                  </div>
                  <div class=" card-footer">
                    <button class=" btn btn-fill btn-danger" *ngIf="isEcardEdit === 'false'"
                    (click)="onSave()">Save</button>
                  <button class=" btn btn-fill btn-danger" *ngIf="isEcardEdit === 'true'"
                    (click)="onEdit()">Update</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>