import { Component, OnInit } from '@angular/core';
import {
  ModalDismissReasons,
  NgbActiveModal,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { RestApiService } from 'src/app/services/rest-api.service';
import Swal from 'sweetalert2';
import { CdkDragDrop, moveItemInArray, CdkDrag } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-business-category',
  templateUrl: './business-category.component.html',
  styleUrls: ['./business-category.component.scss'],
})
export class BusinessCategoryComponent implements OnInit {
  closeResult: string;
  bcategories = [];
  bcategoryName;
  bselectedCategory;
  searchName: string;

  offset = 0;
  limit = 15;
  isSpinner = false;

  constructor(
    private restApiService: RestApiService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.getbussinessCategory();
  }
  previousOrNext(isNext) {
    this.isSpinner = true;
    if (isNext) {
      this.offset++;
      this.getbussinessCategory();
    } else {
      if (this.offset >= 0) {
        this.offset--;
        this.getbussinessCategory();
      }
    }
  }
  drop(event: CdkDragDrop<unknown>) {
    moveItemInArray(this.bcategories, event.previousIndex, event.currentIndex);

    this.bcategories.forEach(async (ele, index) => {
      ele.index = index;
      try {
        await this.restApiService.changeBusinessCategoryIdx({
          item: this.bcategories[index],
        });
      } catch (error) {
        this.isSpinner = false;
        console.error('error in  ', index);
      }
    });
  }
  getbussinessCategory() {
    this.restApiService
      .getbussinessCategoryWithOffset(this.offset, this.limit)
      .then((res) => {
        this.isSpinner = false;
        console.log('res.data', res.data)
        if (res && res.code === 200) {
          this.bcategories = res.data.sort((a, b) => a.index - b.index);
        }
      })
      .catch((err) => {
        this.isSpinner = false;
        console.log('error is', err);
      });
  }
  open(content) {
    this.modalService.open(content).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  onSave(modal) {
    this.isSpinner = true;
    const extraData = {};
    const obj = {
      businessCategory: this.bselectedCategory,
      extraData: JSON.stringify(extraData),
    };
    this.restApiService
      .addbussinessCategory(obj)
      .then((res) => {
        this.isSpinner = false;
        if (res && res.code === 200) {
          modal.close();
          this.getbussinessCategory();
        }
      })
      .catch((err) => {
        this.isSpinner = false;
        console.log('error is', err);
      });
    this.bcategoryName = '';
  }
  bselectedId;
  onEdit(modal, category) {
    this.bselectedCategory = category.businessCategory;
    this.bselectedId = category._id;
    const activeModal = this.modalService.open(modal);
  }
  onEditSave(modal) {
    const extraData = {};
    const obj = {
      businessCategory: this.bselectedCategory,
      extraData: JSON.stringify(extraData),
    };

    this.restApiService
      .editbussinessCategory(this.bselectedId, obj)
      .then((res) => {
        if (res && res.code === 200) {
          modal.close();
          this.getbussinessCategory();
        }
      })
      .catch((err) => {
        console.log('error is', err);
      });
  }
  getSearchedBusinessCategory(value) {
    if (value.length > 2) {
      this.restApiService.getSearchBusinessCategory(value).then(
        async (res) => {
          if (res && res.code == 200) {
            this.bcategories = res.data.sort((a, b) => a.index - b.index);
          }
        },
        (err) => {
          console.log('err is', err);
        }
      );
    } else {
      this.getbussinessCategory();
    }
  }
  onDeleteBusinessCategory(category) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    })
      .then((result) => {
        if (result.isConfirmed) {
          this.isSpinner = true;
          this.restApiService
            .deleteBusinessCategory(category._id)
            .then((res) => {
              if (res && res.code === 200) {
                Swal.fire('Deleted!', 'Category has been deleted.', 'success');
                this.getbussinessCategory();
                this.isSpinner = false;
              }
            })
            .catch((err) => {
              console.log('error is', err);
            });
        }
      })
      .catch((err) => {
        console.log('error is', err);
      });
  }
}
