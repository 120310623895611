<div *ngIf="isSpinner == true" class="spinner"
  style="position: fixed; top: 0; width: 100%; height: 100%; z-index: 99999">
  <div style="
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: rgba(179, 177, 177, 0.191);
    ">
    <div class="spinner-border" style="color: rgb(0, 17, 255)"></div>
  </div>
</div>
<div class="content" [ngStyle]="{ filter: isSpinner == true ? 'blur(1px)' : 'blur(0px)' }">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Category</h4>

          <div style="
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              width: 90%;
            ">
            <div class="d-flex flex-row">
              <button class="btn btn-fill btn-primary" data-target="#searchModal" data-toggle="modal"
                (click)="open(content)">
                Add
              </button>
              <button class="btn btn-fill btn-primary" data-target="#searchModal" data-toggle="modal"
                (click)="getAllCategory(changeIndex)">
                change index
              </button>
            </div>
            <input class="form-control text-white" type="text" name="search" [(ngModel)]="searchName"
              (ngModelChange)="getSearchedUserDetails($event)" placeholder="search" style="width: 30%" />
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table tablesorter" id="">
              <thead class="text-primary" style="width: 100%">
                <tr class="header-tag col-12 border-bottom border-warning">
                  <th class="col-sm-5 col-md-4">Name</th>
                  <th class="col-sm-3 col-md-2 text-center">End Date At</th>
                  <th class="col-sm-4 col-md-6">Action</th>
                </tr>
              </thead>
              <tbody>
                <div>
                  <div *ngFor="let category of categories | filter : searchName" class="text-white"
                    style="border-radius: 20px" [ngStyle]="{
                      border:
                        selectedCategory?._id == category?._id && isExpand
                          ? '1px solid gray'
                          : '0px'
                    }">

                    <tr class="header-tag col-12">
                      <td class="col-sm-5 col-md-4">{{ category.name }}</td>
                      <td class="col-sm-3 col-md-2 text-center" *ngIf="category?.extraData?.EndDate">
                        {{ category?.extraData?.EndDate | date : "dd-MM-yyyy" }}
                      </td>
                      <td class="col-sm-3 col-md-2 text-center" *ngIf="!category?.extraData?.EndDate">
                        --N/A--
                      </td>
                      <td class="col-sm-4 col-md-6">
                        <button (click)="getSingleFestival(category)" class="btn btn-fill btn-primary mr-3">
                          <!-- <i
                            class="tim-icons icon-minimal-down"
                            style="font-size: 15px; color:white"
                          ></i> -->
                          <img src="../../../../assets/icons/ic-show.svg" style="
                              width: 30px;
                              border-radius: 5px;
                              cursor: pointer;
                            " />
                        </button>
                        <button class="btn btn-fill btn-primary mr-3" (click)="onEdit(editContent, category)">
                          Edit
                        </button>
                        <button class="btn btn-fill btn-danger" (click)="onDelete(category)">
                          Delete
                        </button>
                      </td>
                    </tr>
                    <div *ngIf="selectedCategory?._id == category?._id && isExpand" style="width: 100%">
                      <td colspan="6" style="width: 100%">
                        <p>Festival Post</p>
                        <div style="
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                          " class="col-12 pb-4">
                          <div *ngFor="let post of festivalArray">
                            <!-- <p style="padding-left: 10px;">{{post.image}}</p> -->
                            <div class="border p-1" style="width: 150px; position: relative">
                              <img src="{{ post.image }}" style="width: 100%; height: 100%" />
                              <img [src]="'assets/icons/ic-prime.svg'" *ngIf="post.isPaid" style="
                                  position: absolute;
                                  top: 8%;
                                  left: 10%;
                                  width: 10%;
                                  height: 10%;
                                " alt="" />
                              <div style="position: absolute; bottom: 12%; height: 20px; left: 0%;  ">
                                <p
                                  style="padding:4px 8px; background-color: azure; font-size: 15px; color:black; margin-left: 10px; border-radius: 20px; border:1px solid black ">
                                  {{post?.downloadCount}}</p>
                              </div>
                              <div style="
                                  position: absolute;
                                  right: 10px;
                                  top: 10px;
                                  cursor: pointer;
                                ">

                                <!-- edit festival popup -->
                                <button style="border-radius: 50%;border: 0px;margin: 0px 5px;"
                                  (click)="openPopup(editFestivalContent,category,post)">
                                  <i class="tim-icons icon-pencil" style="font-size: 15px; color: rgb(0, 0, 0)"></i>
                                </button>

                                <button style="border-radius: 50%; border: 0px" (click)="onDeleteFestival(post, true)">
                                  <i class="tim-icons icon-trash-simple" style="
                                      font-size: 15px;
                                      color: rgb(240, 40, 40);
                                    "></i>
                                </button>

                                <button style="border-radius: 50%; border: 0px" (click)="
                                    openNotificationPopup(
                                      sendNotification,
                                      post
                                    )
                                  ">
                                  <i class="tim-icons icon-bell-55" style="
                                      font-size: 15px;
                                      color: rgb(25, 0, 255);
                                    "></i>
                                </button>
                              </div>
                            </div>
                          </div>

                          <!-- Add new festival image popup -->
                          <div (click)="openPopup(contentFestival, category)" class="border p-1" style="
                              width: 150px;
                              position: relative;
                              cursor: pointer;
                            ">
                            <img src="assets/img/uploadFile.png" style="width: 100%; height: 100%; padding: 20%" />
                          </div>
                          <!-- <button class="btn btn-fill btn-primary mr-1 col-3" (click)="openPopup(content)">
                                                Add Post
                                              </button> -->
                        </div>
                        <!-- ADD CUSTOM POST  -->
                        <h3 class="border-top border-light">Custom Post</h3>
                        <div style="
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                          " class="col-12">
                          <div *ngFor="let post of customArray">
                            <div class="border p-1" style="width: 150px; position: relative">
                              <img src="{{ post.image }}" style="width: 100%; height: 100%" />
                              <img [src]="'assets/icons/ic-prime.svg'" *ngIf="post.isPaid" style="
                                  position: absolute;
                                  top: 8%;
                                  left: 10%;
                                  width: 10%;
                                  height: 10%;
                                " alt="" />
                              <div style="position: absolute; bottom: 12%; height: 20px; left: 0%;  ">
                                <p
                                  style="padding:4px 8px; background-color: azure; font-size: 15px; color:black; margin-left: 10px; border-radius: 20px; border:1px solid black ">
                                  {{post?.downloadCount}}</p>
                              </div>
                              <div style="
                                  position: absolute;
                                  right: 10px;
                                  top: 10px;
                                  cursor: pointer;
                                ">
                                <button style="
                                    border-radius: 50%;
                                    border: 0px;
                                    margin: 0px 5px;
                                  " (click)="onEditCustomePost(post)">
                                  <i class="tim-icons icon-pencil" style="font-size: 15px; color: rgb(0, 0, 0)"></i>
                                </button>
                                <button style="border-radius: 50%; border: 0px" (click)="onDeleteFestival(post, false)">
                                  <i class="tim-icons icon-trash-simple" style="
                                      font-size: 15px;
                                      color: rgb(240, 40, 40);
                                    "></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div (click)="onAddCustomPost(category)" class="border p-1" style="
                              width: 150px;
                              position: relative;
                              cursor: pointer;
                            ">
                            <img src="assets/img/uploadFile.png" style="width: 100%; height: 100%; padding: 20%" />
                          </div>
                        </div>
                      </td>
                    </div>
                  </div>
                </div>
                <tr *ngIf="categories.length === 0">
                  <td colspan="4" class="text-center">Category not found</td>
                </tr>
              </tbody>
            </table>

            <div class="d-flex justify-content-between pt-3 align-items-center">
              <div>
                <a class="previous" (click)="previousOrNext(false)" *ngIf="this.offset != 0">&laquo; {{ offset }}
                  Previous</a>
              </div>
              <div>
                <a class="next" style="cursor: not-allowed">{{ offset + 1 }}</a>
              </div>
              <div *ngIf="categories.length">
                <a class="next" (click)="previousOrNext(true)">{{ offset + 2 }} Next &raquo;</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Add modal -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Add Category</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <input class="form-control text-dark" placeholder="Name" type="text" [(ngModel)]="categoryName"
        name="categoryName" />
    </div>
    <div class="form-group">
      <input class="form-control text-dark" placeholder="Enter Festival End Date" type="date" [(ngModel)]="EndDate"
        name="EndDate" />
    </div>
    <div class="form-group">
      <select class="form-control text-dark" name="type" [(ngModel)]="type" id="type">
        <option value="business">Business Category</option>
        <option value="general">General Category</option>
        <option value="special">Special Days</option>
        <option value="useful">Useful Category</option>
        <option value="wishes">Wishes Category</option>
        <option value="default">Default Category</option>
      </select>
    </div>
  </div>

  <div class="modal-footer d-flex justify-content-end">
    <button type="button" class="btn btn-outline-dark mr-3" (click)="modal.close('Close click')">
      Close
    </button>
    <button type="button" class="btn btn-fill btn-primary" (click)="onSave(modal)">
      Save
    </button>
  </div>
</ng-template>

<!-- Edit Modal -->
<ng-template #editContent let-editModal>
  <div class="modal-header">
    <h4 class="modal-title">Edit Category</h4>
    <button type="button" class="close" aria-label="Close" (click)="editModal.close('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <input class="form-control text-dark" placeholder="Name" type="text" [(ngModel)]="selectedCategory.name"
        name="categoryName" />
    </div>
    <div class="form-group">
      <input class="form-control text-dark" placeholder="Enter Festival End Date" type="date"
        [(ngModel)]="extraData.EndDate" name="EndDate" />
    </div>
    <div class="form-group">
      <select class="form-control text-dark" name="type" [(ngModel)]="extraData.type" id="type">
        <option value="business">Business Category</option>
        <option value="general">General Category</option>
        <option value="special">Special Days</option>
        <option value="useful">Useful Category</option>
        <option value="wishes">Wishes Category</option>
        <option value="default">Default Category</option>
      </select>
    </div>
  </div>

  <div class="modal-footer d-flex justify-content-end">
    <button type="button" class="btn btn-outline-dark mr-3" (click)="editModal.close('Close click')">
      Close
    </button>
    <button type="button" class="btn btn-fill btn-primary" (click)="onEditSave(editModal)">
      Save
    </button>
  </div>
</ng-template>

<!-- Add modal -->
<ng-template #contentFestival let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Add Festival</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>Image</label>
      <input class="form-control text-dark" placeholder="Image" (change)="browse($event)" type="file" name="image" />
    </div>
    <!-- <div class="form-group">
      <label>Title</label>
      <input class="form-control text-dark" placeholder="Title" type="text" name="title" [(ngModel)]="selectedFestival.title" />
    </div> -->
    <div class="form-group">
      <label>Text</label>
      <input class="form-control text-dark" placeholder="text" type="text" name="text"
        [(ngModel)]="selectedFestival.text" />
    </div>

    <div class="d-flex flex-row">
      <div class="border" style="height: auto;width:  50%;aspect-ratio: 1/1; position: relative;border: 1px solid red;">
        <img [src]='newFestivalImgURL' style="height: 100%;width:100% ;" alt="">
        <p style="position: absolute;font-size: 10px;" [ngStyle]="{
          'left' : !selectedFestival?.textLeft ? '0%' : selectedFestival.textLeft + '%',
          'top' : !selectedFestival?.textTop ? '0%' :  selectedFestival.textTop + '%'}">
          {{selectedFestival.text}}</p>
      </div>
      <div class="w-50 border pl-3">
        <div>
          <label>Text position Left</label>
          <input class="form-control text-dark" placeholder="text" type="number" max="90" name="text"
            [(ngModel)]="selectedFestival.textLeft" />
        </div>
        <div>
          <label>Text position Top</label>
          <input class="form-control text-dark" placeholder="text" type="number" max="90" name="text"
            [(ngModel)]="selectedFestival.textTop" />
        </div>
      </div>
    </div>

    <div class="form-group" style="
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
      ">
      <label style="width: 90%; height: 100%; padding-top: 10px">Paid</label>
      <input class="form-control text-dark" style="text-align: end; padding: 10px; height: 15px; width: 10%"
        type="checkbox" name="toggle" [(ngModel)]="festival.isPaid" />
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button type="button" class="btn btn-outline-dark mr-3" (click)="modal.close('Close click')">
      Close
    </button>
    <button type="button" class="btn btn-fill btn-primary" (click)="onFestivalSave(modal)">
      Save
    </button>
  </div>
</ng-template>

<!-- Edit Modal -->
<ng-template #editFestivalContent let-editModal>
  <div class="modal-header">
    <h4 class="modal-title">Edit Festival</h4>
    <button type="button" class="close" aria-label="Close" (click)="editModal.close('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>Category</label>
      <select class="form-control text-dark" [(ngModel)]="selectedFestival.categoryId">
        <option *ngFor="let category of categories" [value]="category._id">
          {{ category?.name }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label>Image</label>
      <input class="form-control text-dark" placeholder="Image" (change)="browse($event)" type="file" name="image" />
    </div>
    <div class="form-group">
      <label>Title</label>
      <input class="form-control text-dark" placeholder="Title" type="text" name="title"
        [(ngModel)]="selectedFestival.title" />
    </div>
    <div class="form-group">
      <label>Text</label>
      <input class="form-control text-dark" placeholder="Text" type="text" name="text"
        [(ngModel)]="selectedFestival.extraData.text" />
    </div>

    <div>
      <div class="d-flex justify-content-center mt-2">
        <div style="height: 300px;aspect-ratio: 1/1;position: relative;">
          <img class="border" style="width: 100%; aspect-ratio: 1/1;" [src]="selectedFestival.image" alt="">
          <p class="position-absolute"
            [ngStyle]="{
              'left' :selectedFestival.extraData.textPositionLeft ? selectedFestival.extraData.textPositionLeft + '%' : '10%',
              'top' :selectedFestival.extraData.textPositionTop ? selectedFestival.extraData.textPositionTop + '%' : '10%' }">
            {{selectedFestival.extraData.text}}</p>
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-between mt-3">
        <div class="form-group">
          <label>Text Position Left</label>
          <input class="form-control text-dark" placeholder="Left" type="number" name="textPositionLeft"
            [(ngModel)]="selectedFestival.extraData.textPositionLeft" />
        </div>
        <div class="form-group">
          <label>Text Position Top</label>
          <input class="form-control text-dark" placeholder="Top" type="number" name="textPositionTop"
            [(ngModel)]="selectedFestival.extraData.textPositionTop" />
        </div>
      </div>
    </div>
    <div class="form-group" style="
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
      ">
      <label style="width: 90%; height: 100%; padding-top: 10px">Paid</label>
      <input class="form-control text-dark" style="text-align: end; padding: 10px; height: 15px; width: 10%"
        type="checkbox" name="toggle" [(ngModel)]="selectedFestival.isPaid" />
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button type="button" class="btn btn-outline-dark mr-3" (click)="editModal.close('Close click')">
      Close
    </button>
    <button type="button" class="btn btn-fill btn-primary" (click)="onEditFestivalSave(editModal)">
      Save
    </button>
  </div>
</ng-template>

<!-- Add modal -->
<ng-template #changeIndex let-modal>
  <div style="
      position: absolute !important;
      top: 0 !important;
      background: white;
      width: 100%;
    ">
    <div class="modal-header">
      <h4 class="modal-title">Change Index</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.close('Close click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="example-list" cdkDropList (cdkDropListDropped)="drop($event)" style="width: 100%">
      <div *ngFor="let category of categoriesList; let j = index" style="
          padding-left: 5%;
          width: 100%;
          color: gray;
          display: flex;
          flex-direction: column;
          text-align: left;
          justify-content: space-between;
          box-sizing: border-box;
          cursor: move;
          background: transparent;
          font-size: 14px;
        " [cdkDropData]="category" cdkDrag>
        <p style="color: black; padding: 0px; text-align: left">
          {{ j + 1 }} {{ category.name }}
        </p>
      </div>
    </div>
  </div>
</ng-template>

<!-- Add Notification modal -->

<ng-template #sendNotification let-modal>
  <div *ngIf="isSpinner === true" class="spinner">
    <div class="spinner-border" style="color: rgb(0, 17, 255)"></div>
  </div>
  <div class="modal-header">
    <h4 class="modal-title">Add Notification</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <div class="form-group">
        <label>Notification Title</label>
        <input class="form-control text-dark" #title placeholder="Title" type="text" name="title" />
      </div>
      <div class="form-group">
        <label>Description</label>
        <input class="form-control text-dark" #msg placeholder="Description" type="text" name="msg" />
      </div>
    </div>
    <img [src]="notificationImage" style="height: 130px" />
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button type="button" class="btn btn-outline-dark mr-3" (click)="modal.close('Close click')">
      Close
    </button>
    <button type="button" class="btn btn-fill btn-primary" (click)="SendNotificationBtn(title.value, msg.value)">
      Send
    </button>
  </div>
</ng-template>