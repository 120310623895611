<div style="height:90%  !important;" >
<div class="logo bgcolor" >
  <a href="#" class="simple-text logo-mini">
    <div class="logo-img">
      <img src="assets/img/angular2-logo-white-bgremover.png"/>
    </div>
  </a>
  <a href="#" class="simple-text logo-normal">
    Shape me
  </a>
</div>
<div class="sidebar-wrapper bgcolor" >
  <ul class="nav">
    <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{ menuItem.class }} nav-item">
      <a [routerLink]="[menuItem.path]">
        <i class="tim-icons  {{ menuItem.icon }}"></i>
        <p>{{ menuItem.title }}</p>
      </a>
    </li>
  </ul>
</div></div>