import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestApiService } from 'src/app/services/rest-api.service';
import Swal from 'sweetalert2';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-show-user-deatils',
  templateUrl: './show-user-deatils.component.html',
  styleUrls: ['./show-user-deatils.component.scss']
})
export class ShowUserDeatilsComponent implements OnInit {
  viewUser
  viewUserExtraData
  bussinessForm = {
    _id: '',
    bussinessName: '',
    businesscategory: '',
    userName: '',
    designation: '',
    contactNumber: '',
    extracontactNumber: '',
    email: '',
    website: '',
    address: '',
    city: '',
    state: '',
    logo: ''
  }
  SubPlan = {
    planId: '',
    razorpay_p_id: '',
    startDate: '',
    planAmount: 0,
    isSubscription: false,
    endDate: '',
    PlanDuraction: ''
  }
  selectedCategory
  selectedplan
  cate
  bussinessName
  bcategories
  isSpinner
  logoFile
  cat_value
  businessdetails
  userdata
  data
  position
  getscriptionData
  plandata
  planDate
  Duraction
  razorpay_p_id
  logoImage;
  isTure = false;
  constructor(private router: Router, private restApiService: RestApiService, private modalService: NgbModal, private fb: FormBuilder,) {
    this.viewUser = history.state;

    this.viewUserExtraData = this.shortDate(this.viewUser.extraData)
    if (!this.viewUser) {
      return;
    }
    this.getBusiness()
  }
  ngOnInit(): void {
    this.getsubscriptionPlan()
    this.getbussinessCategory()

  }
  getBusiness() {
    this.restApiService.getuserbussinessCategory(this.viewUser._id).then(res => {
      this.businessdetails = res.data;
    });
  }

  goBack() {
    this.router.navigateByUrl('/all-user-details');
  }
  onEdit(modal, category) {
    this.selectedCategory = JSON.parse(JSON.stringify(category));
    const activeModal = this.modalService.open(modal);
    this.cate = this.selectedCategory.businesscategory;
    this.getcompanyid(this.cate)
  }

  browse(event) {
    this.isTure = true;
    this.logoImage = event.target.files[0];

  }
  onEditSave(modal) {

    this.isSpinner = true;
    this.bussinessForm._id = this.selectedCategory._id
    this.logoFile = this.selectedCategory.logo
    this.bussinessForm.bussinessName = this.selectedCategory.bussinessName
    this.bussinessForm.businesscategory = this.cat_value
    this.bussinessForm.userName = this.selectedCategory.userName
    this.bussinessForm.designation = this.selectedCategory.designation
    this.bussinessForm.contactNumber = this.selectedCategory.contactNumber
    this.bussinessForm.extracontactNumber = this.selectedCategory.extracontactNumber
    this.bussinessForm.email = this.selectedCategory.email
    this.bussinessForm.website = this.selectedCategory.website
    this.bussinessForm.address = this.selectedCategory.address
    this.bussinessForm.city = this.selectedCategory.city
    this.bussinessForm.state = this.selectedCategory.state
    let value = this.bussinessForm;
    if (this.isTure == true) {
      value.logo = this.logoImage
      this.isTure = false;
    } else {
      if (this.logoFile) {
        value.logo = this.logoFile
      }
    }
    value['userId'] = this.viewUser._id
    this.restApiService.updateBussinessDetail(value).then(res => {
      if (res && res.code === 200) {
        this.getBusiness()
        modal.close()
      }
    }).catch(err => {
      console.log('error is', err);
    })
  }

  updateSubscriptionPlan(modal, value, i) {
    this.selectedplan = JSON.parse(JSON.stringify(value))
    this.modalService.open(modal);
    this.position = i

  }
  saveUpdatePlan(model) {
    this.SubPlan.razorpay_p_id = this.selectedplan.razorpay_p_id
    this.SubPlan.planId = this.selectedplan.planId
    this.SubPlan.startDate = this.selectedplan.startDate
    this.SubPlan.planAmount = this.selectedplan.planAmount
    this.SubPlan.isSubscription = this.selectedplan.isSubscription
    this.SubPlan.endDate = this.selectedplan.endDate
    this.SubPlan.PlanDuraction = this.selectedplan.planDuraction


    let data = this.SubPlan
    data['position'] = this.position
    this.restApiService.updateUserPlan(this.viewUser._id, data).then(res => {
      if (res && res.code === 200) {
        this.getsubscriptionPlan()
        this.getbussinessCategory()
        this.modalService.dismissAll()
      }
    }).catch(err => {
      console.log('error is', err);
    })
  }
  getbussinessCategory() {
    this.restApiService
      .getbussinessCategory()
      .then((res) => {
        if (res && res.code === 200) {
          this.bcategories = res.data.sort((a, b) => a.index - b.index);
        }
      })
      .catch((err) => {
        console.log('error is', err);
      });
  }

  getcompanyid(event) {
    this.cat_value = event;
  }
  onDelete(business) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.restApiService.deleteBusinessEcard(business._id).then(res => {
          if (res && res.code === 200) {
            Swal.fire(
              'Deleted!',
              'Ecard has been deleted.',
              'success'
            )
            this.getsubscriptionPlan()
            this.getbussinessCategory()
            // this.getbussinessDetail()
          }
        }).catch(err => {
          console.log('error is', err);
        })
      }
    })
  }
  getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);
    if (diffInDays > 28 && diffInDays <= 365) {
      return "Year";
    } else if (diffInDays <= 31) {
      return "Month"
    }
  }
  shortDate(arrya: any[]) {
    return arrya.sort((a: any, b: any) => {
      var old = new Date(a.endDate).getTime();
      var current = new Date(b.endDate).getTime();
      return current - old;
    });
  }

  addUserPlanSubscription(modal) {
    this.modalService.open(modal);
    // this.getsubscriptionPlan()
    
  }

  async sendPlan(datas) {
    this.plandata = datas
    if (this.plandata.duraction == 'Monthly') {
      this.planDate = this.AfterOneMonthDate()
      this.Duraction = 'Monthly'
    } else if (this.plandata.duraction == 'Yearly') {
      this.planDate = this.AfterOneYearDate()
      this.Duraction = 'Yearly'
    }
    //  datas._id
    //  datas.duraction
    //  datas.planDiscount

    let info = {
      isSubscription: true,
      planAmount: datas.planDiscount,
      planId: datas._id,
      startDate: this.planDate.StartingAt,
      endDate: this.planDate.EndingAt,
      razorpay_p_id: this.razorpay_p_id,
      PlanDuraction: this.Duraction
    }
    if (!info.razorpay_p_id) {
      alert("Enter Razorpay payement Id")
    } else {
      this.restApiService.addUserSubscription(this.viewUser._id, info).then(res => {

        if (res && res.code == 200) {
          localStorage.setItem('user', JSON.stringify(res.data))
          localStorage.setItem('isSubscription', JSON.stringify(res.data.extraData[res.data.extraData.length - 1].isSubscription))

          this.modalService.dismissAll();          
          this.getbussinessCategory()
          this.goBack();
        }
      }).catch(err => {
        console.log('error is', err);
      })
    }
  }


  getsubscriptionPlan() {
    this.restApiService.getsubscriptionPlan().then(res => {
      if (res && res.code == 200) {
        const data = res.data
        this.getscriptionData = data.sort((a, b) => a.index - b.index);
      }
    })
  }
  GetTodayDate() {
    return new Date()
  }
  AfterOneYearDate() {
    const n = 365 //number of days to add.
    var today = this.GetTodayDate() //Today's Date
    var requiredDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + n
    )
    return { StartingAt: today.toISOString(), EndingAt: requiredDate.toISOString() }
  }
  AfterOneMonthDate() {
    const n = 28 //number of days to add.
    var today = this.GetTodayDate() //Today's Date
    var requiredDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + n
    )
    return { StartingAt: today.toISOString(), EndingAt: requiredDate.toISOString() }
  }
}
