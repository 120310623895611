import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestApiService } from 'src/app/services/rest-api.service';

@Component({
  selector: 'app-add-e-card',
  templateUrl: './add-e-card.component.html',
  styleUrls: ['./add-e-card.component.scss'],
})
export class AddECardComponent implements OnInit {
  selectedFile;
  frame;
  cardlogo = 'assets/img/angular2-logo-red.png';
  phoneIcon = 'assets/icons/ic-phone.svg';
  emailIcon = 'assets/icons/ic-email.svg';
  addressIcon = 'assets/icons/ic-location.svg';
  websiteIcon = 'assets/icons/ic-website.svg';
  isSpinner = false;
  personName = {
    text: {
      color: '#191919',
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      otherIcon: '',
      otherSize: '',
      fontSize: 0,
    },
  };
  
  personDesignation = {
    text: {
      color: '#191919',
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      otherIcon: '',
      otherSize: '',
      fontSize: 10
    },
  };
  phone = {
    icon: {
      color: '',
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      otherIcon: '',
      otherSize: '',
      width: 5,
      height: 5,
    },
    text: {
      width: 20,
      color: '',
      left: '20%',
      right: 0,
      bottom: 0,
      top: 0,
      fontSize: 0,
    },
  };
  website = {
    icon: {
      color: '',
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      otherIcon: '',
      otherSize: '',
      width: 5,
      height: 5,
    },
    text: {
      width: 20,
      color: '',
      left: '20%',
      right: 0,
      bottom: 0,
      top: 0,
      fontSize: 0,
    },
  };
  email = {
    icon: {
      color: '',
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      otherIcon: '',
      otherSize: '',
      width: 5,
      height: 5,
    },
    text: {
      width: 20,
      color: '',
      left: '20%',
      right: 0,
      bottom: 0,
      top: 0,
      fontSize: 0,
    },
  };
  address = {
    icon: {
      color: '',
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      otherIcon: '',
      otherSize: '',
      width: 5,
      height: 5,
    },
    text: {
      width: 20,
      color: '',
      left: '20%',
      right: 0,
      bottom: 0,
      top: 0,
      fontSize: 0,
    },
  };
  logo = {
    icon: {
      color: '',
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      img_width: 15,
      img_height: 20,
      otherIcon: '',
      otherSize: '',
    },
  };

  LogoPosition;
  framePosition;
  phoneNoPosition;
  websitePosition;
  emailPosition;
  addressPosition;
  personNamePosition;
  personDesignationPosition;

  phoneIconPosition;
  emailIconPosition;
  websiteIconPosition;
  addressIconPosition;

  selectIcon;
  iconPhone;
  iconWeb;
  iconEmail;
  iconAddress;
  constructor(private router: Router, private restApiService: RestApiService) {
  }
  id
  isEcardEdit
  editecard
  ngOnInit(): void {
    this.isEcardEdit = localStorage.getItem('isEdit');
    this.editecard = history.state;
    if (!this.editecard) {
      return;
    }
    this.frame = this.editecard.image;

    this.phone.text = this.editecard.position.phone.text;
  
    
    this.phone.icon = this.editecard.position.phone.icon;

    this.email.text = this.editecard.position.email.text;
    this.email.icon = this.editecard.position.email.icon;

    this.website.text = this.editecard.position.website.text;
    this.website.icon = this.editecard.position.website.icon;
    
    this.address.text = this.editecard.position.address.text;
    this.address.icon =this.editecard.position.address.icon;

    this.logo.icon = this.editecard.position.logo.icon;
 
    this.personDesignation.text = this.editecard.position.personDesignation.text;
  

    this.personName.text = this.editecard.position.personName.text;
   

    
    this.id = this.editecard._id
  }
  goBack() {
    this.router.navigateByUrl('/e-card');
  }
  onPositionChange(event, varType, field, position) {
    const value = parseFloat(event.target.value);
    this[varType][field][position] = value;
  }
  onFontSizeChange(event, varType, field, size) {
    const value = parseFloat(event.target.value);
    this[varType][field][size] = value;
  }
  onChangeWidthHeight(event, varType, field, wsize) {
    const value = parseFloat(event.target.value);
    this[varType][field][wsize] = value;
  }
  FontValue(event, varType, field, Color) {
    const value = event.target.value;
    this[varType][field][Color] = value;
  }

  async browse(ev) {
    this.selectedFile = ev.target.files[0];
    this.frame = await this.getBase64(this.selectedFile);
  }

  async browsePhoneIcon(ev) {
    this.selectIcon = ev.target.files[0];
    this.iconPhone = await this.getBase64(this.selectIcon);
    this.phone.icon.otherIcon = this.iconPhone;
  }
  async browseWebIcon(ev) {
    this.selectIcon = ev.target.files[0];
    this.iconWeb = await this.getBase64(this.selectIcon);
    this.website.icon.otherIcon = this.iconWeb;
  }
  async browseEmailIcon(ev) {
    this.selectIcon = ev.target.files[0];
    this.iconEmail = await this.getBase64(this.selectIcon);
    this.email.icon.otherIcon = this.iconEmail;
  }
  async browseAddressIcon(ev) {
    this.selectIcon = ev.target.files[0];
    this.iconAddress = await this.getBase64(this.selectIcon);
    this.address.icon.otherIcon = this.iconAddress;
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  onSave() {
    this.isSpinner = true;
    const position = {
      phone: this.phone,
      email: this.email,
      website: this.website,
      address: this.address,
      logo: this.logo,
      personName: this.personName,
      personDesignation: this.personDesignation,
    };
    const params = {
      image: this.selectedFile,
      position: JSON.stringify(position),
    };
    this.restApiService.addEcard(params).then((data) => {
      this.router.navigateByUrl('/e-card');
      this.isSpinner = false;
    });
  }
  onEdit() {
    this.isSpinner = true;
    const position = {
      phone: this.phone,
      email: this.email,
      website: this.website,
      address: this.address,
      logo: this.logo,
      personName: this.personName,
      personDesignation: this.personDesignation,
    };

    const obj = {
      position: position,
    };
 
       this.restApiService.updateEcard(this.id,obj).then(res => {
        if(res && res.code === 200){
      
          this.isSpinner = false;
            this.router.navigateByUrl('/e-card');
        }
      
      });
    
  }
  toggleFun0() {
    this.LogoPosition != this.LogoPosition;
  }
  toggleFun1() {
    this.framePosition != this.framePosition;
  }
  toggleFun2() {
    this.phoneNoPosition != this.phoneNoPosition;
  }
  toggleFun3() {
    this.websitePosition != this.websitePosition;
  }
  toggleFun4() {
    this.emailPosition != this.emailPosition;
  }
  toggleFun5() {
    this.addressPosition != this.addressPosition;
  }
  toggleFun6() {
    this.personNamePosition != this.personNamePosition;
  }
  toggleFun7() {
    this.personDesignationPosition != this.personDesignationPosition;
  }

  toggleFun8() {
    this.phoneIconPosition != this.phoneIconPosition;
  }
  toggleFun9() {
    this.emailIconPosition != this.emailIconPosition;
  }
  toggleFun10() {
    this.websiteIconPosition != this.websiteIconPosition;
  }
  toggleFun11() {
    this.addressIconPosition != this.addressIconPosition;
  }
}
