<div class="content">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Subscription</h4>
                    <div class="header-btn">
                        <div style="
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 90%;
              ">
                            <button class="btn btn-fill btn-primary mr-3" (click)="addPlan(addContent)">
                                Add
                            </button>
                            <input class="form-control text-white" type="text" placeholder="search"
                                style="width: 30%" />
                        </div>
                    </div>
                </div>
                <div class="card-body d-flex flex-wrap" cdkDropList (cdkDropListDropped)="drop($event)">
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 frame-card" *ngFor="let data of getscriptionData"
                        [cdkDragData]="data" [cdkDropListSortPredicate]="'sortPredicate'" cdkDrag>
                        <div class="plan-card d-flex flex-column">
                            <div>
                                <div class="card-header">
                                    <h6 class="discount">
                                        {{ data?.flatDiscount }}% flat discount
                                    </h6>
                                    <div>
                                        <button style="background: transparent; border: 0px"
                                            (click)="onDelete(data._id)">
                                            <i class="tim-icons icon-trash-simple mr-2"
                                                style="font-size: 18px; color: rgb(255, 198, 198)"></i>
                                        </button>
                                        <button style="background: transparent; border: 0px"
                                            (click)="editPlan(editContent, data)">
                                            <i class="tim-icons icon-pencil"
                                                style="font-size: 18px; color: rgb(235, 104, 104)"></i>
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <label class="price">&#x20B9;{{ data?.planDiscount }}</label>
                                    <label class="price-per-year">&#x20B9;<del>{{ data?.planPrice }}</del> per
                                        {{ data?.duraction }}</label>
                                </div>
                            </div>
                            <div class="details">
                                <ul>
                                    <li *ngFor="let des of data.description; let i = index">
                                        {{ des.line }}
                                    </li>
                                </ul>
                                <h6 style="margin-left: 10px !important">*T&C Apply</h6>
                            </div>
                            <!-- <div class="card-body d-flex flex-row  justify-content-between mb-2">
                                <div style="width: 46%;">
                                    <button class="btn-fill btn-primary edit-delete"
                                        (click)="editPlan(editContent,data)">Edit</button>
                                </div>
                                <div style="width: 46%;">
                                    <button class="btn-fill btn-danger edit-delete">Delete</button>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Add Modal -->
<ng-template #addContent let-editModal>
    <div class="modal-header">
        <h4 class="modal-title">Add New Subscription Plan</h4>
        <button type="button" class="close" aria-label="Close" (click)="editModal.close('Close click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="SubscriptionForm">
            <div class="form-group">
                <select formControlName="duraction" style="
            width: 100%;
            border: 1px solid gray;
            border-radius: 10px;
            height: 40px;
          " (change)="getDuractionValue($event.target.value)">
                    <option *ngFor="let duraction of planDuraction">
                        {{ duraction }}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <input class="form-control text-dark" placeholder="plan price" type="number" formControlName="planPrice"
                    name="categoryName" />
            </div>
            <div class="form-group">
                <input class="form-control text-dark" placeholder="plan discount" type="number"
                    formControlName="planDiscount" name="categoryName" />
            </div>
            <div class="form-group">
                <input class="form-control text-dark" placeholder="flat discount %" type="number" max="100"
                    formControlName="flatDiscount" name="categoryName" />
            </div>
            <form formArrayName="description">
                <div class="form-group" *ngFor="let item of description().controls; let i = index">
                    <div class="form-group" [formGroupName]="i">
                        <input class="form-control text-dark" placeholder="plan description" type="text"
                            formControlName="line" name="categoryName" />
                    </div>
                </div>
            </form>
        </form>
        <button *ngIf="this.SubscriptionForm.value.description.length < 7" (click)="addLine()">Add more</button>
    </div>
    <div class="modal-footer d-flex justify-content-end">
        <button type="button" class="btn btn-outline-dark mr-3" (click)="editModal.close('Close click')">
            Close
        </button>
        <button type="button" class="btn btn-fill btn-primary" (click)="addNewPlan()">
            Save
        </button>
    </div>
</ng-template>

<!-- EDIT MODEL -->
<ng-template #editContent let-editModal>
    <div class="modal-header">
        <h4 class="modal-title">Add New Subscription Plan</h4>
        <button type="button" class="close" aria-label="Close" (click)="editModal.close('Close click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="SubscriptionForm">
            <div class="form-group">
                <select formControlName="duraction" style="
            width: 100%;
            border: 1px solid gray;
            border-radius: 10px;
            height: 40px;
          " (change)="getDuractionValue($event.target.value)">
                    <option *ngFor="let duraction of planDuraction">
                        {{ duraction }}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <input class="form-control text-dark" placeholder="plan price" type="number"
                    [(ngModel)]="selectedplan.planPrice" formControlName="planPrice" name="categoryName" />
            </div>
            <div class="form-group">
                <input class="form-control text-dark" placeholder="plan discount" type="number"
                    [(ngModel)]="selectedplan.planDiscount" formControlName="planDiscount" name="categoryName" />
            </div>
            <div class="form-group">
                <input class="form-control text-dark" placeholder="flat discount %" type="number" max="100"
                    [(ngModel)]="selectedplan.flatDiscount" formControlName="flatDiscount" name="categoryName" />
            </div>

            <!-- <form formArrayName="description">
                <div class=" form-group" *ngFor='let item of description().controls;let i = index'>
                    <div class=" form-group" [formGroupName]='i'>
                        <input class=" form-control text-dark" placeholder="plan description" type="text" [(ngModel)]="item.line"
                            formControlName="line" name="categoryName" />
                    </div>
                </div>
            </form> -->
        </form>
        <!-- description list start -->
        <div class="form-group">
            <input class="form-control text-dark" placeholder="Description 0" type="text" max="100"
                [value]="description0" />
        </div>
        <div class="form-group">
            <input class="form-control text-dark" placeholder="Description 1" type="text" max="100"
                [(ngModel)]="description1" />
        </div>
        <div class="form-group">
            <input class="form-control text-dark" placeholder="Description 2" type="text" max="100"
                [(ngModel)]="description2" />
        </div>
        <div class="form-group">
            <input class="form-control text-dark" placeholder="Description 3" type="text" max="100"
                [(ngModel)]="description3" />
        </div>
        <div class="form-group">
            <input class="form-control text-dark" placeholder="Description 4" type="text" max="100"
                [(ngModel)]="description4" />
        </div>
        <div class="form-group">
            <input class="form-control text-dark" placeholder="Description 5" type="text" max="100"
                [(ngModel)]="description5" />
        </div>
        <div class="form-group">
            <input class="form-control text-dark" placeholder="Description 6" type="text" max="100"
                [(ngModel)]="description6" />
        </div>

        <!-- description list end -->
        <!-- <button (click)="addLine()">Add more</button> -->
    </div>
    <div class="modal-footer d-flex justify-content-end">
        <button type="button" class="btn btn-outline-dark mr-3" (click)="editModal.close('Close click')">
            Close
        </button>
        <button type="button" class="btn btn-fill btn-primary" (click)="addNewPlan()">
            Save
        </button>
    </div>
</ng-template>