import { Injectable } from '@angular/core';
import { ApiCallService } from './api/api-call.service';

@Injectable({
  providedIn: 'root'
})
export class RestApiService {
  AddCustomeImage(params: { image: any; position: string; }) {
    throw new Error('Method not implemented.');
  }

  constructor(private api: ApiCallService) { }


  login(params) {
    return this.api.postData(this.api.adminLogin, params)
  }
  getUsers(offset,limit) {
    return this.api.getData(this.api.users+'/'+offset+'/'+limit)
  }
  getSearchedUsers(number) {
    return this.api.getData(this.api.searchUser+'/'+number)
  }
  getuserbussinessCategory(userId) {
    return this.api.getData(this.api.bussinessDetail+"/"+userId)
  }
  getbussinessDetail(offset,limit) {
    return this.api.getData(this.api.bussinessDetail+'/'+offset+'/'+limit)
  }
  updateBussinessDetail(params) {
    return this.api.putWithImage(this.api.bussinessDetail + '/' + params._id, params)
  }
  updateUserPlan(id,params) {
    return this.api.postData(this.api.UserPlanUpdate + '/' +id, params)
  }
  deleteBusinessEcard(business_id) {
    return this.api.deleteData(this.api.businessDetail + '/' + business_id)
  }
  getbussinessCategory() {
    return this.api.getData(this.api.bussinessCategory)
  }
  getbussinessCategoryWithOffset(offset,limit) {
    return this.api.getData(this.api.bussinessCategory+'/'+offset+'/'+limit)
  }
  
  addbussinessCategory(data) {
    return this.api.postData(this.api.bussinessCategory, data)
  }
  editbussinessCategory(id, data) {
    return this.api.postData(this.api.bussinessCategory + '/' + id, data)
  }
  getCategory() {
    return this.api.getData(this.api.category)
  }
  getSearchCategory(name) {
    return this.api.getData(this.api.searchCategory+'/'+name)
  }
  getSearchBusinessCategory(name) {
    return this.api.getData(this.api.searchBusinessCategory+'/'+name)
  }
  getSearchDeletedFestival(name) {
    return this.api.getData(this.api.getSearchedDeletedFestival+'/'+name)
  }
  getSearchDeletedCustom(name) {
    return this.api.getData(this.api.getSearchedDeletedCustom+'/'+name)
  }
  deleteBusinessCategory(id) {
    return this.api.deleteData(this.api.deleteBusinessCategory + '/' + id)
  }
  getCategoryWithOffset(offset,limit) {
    return this.api.getData(this.api.category+'/'+offset+'/'+limit)
  }
  addCateogry(params) {
    return this.api.postData(this.api.category, params)
  }
  editCateogry(id, params) {
    return this.api.putData(this.api.category + '/' + id, params)
  }
  activeCategory(id) {
    return this.api.postData(this.api.categoryactive + '/' + id,null)
  }
  deleteCategory(id) {
    return this.api.postData(this.api.categorydelete + '/' + id,null)
  }
  getDashboardItems() {
    return this.api.getData(this.api.dashboardItems)
  }
  getFestivals() {
    return this.api.getData(this.api.festivals)
  }
  getFestivalbyCategoryName(category) {
    return this.api.getData(this.api.festival+ '/' + category)
  }
 getCategoryWiseOffsetFestival(offset,limit) {
    return this.api.getData(this.api.categoryWiseOffsetFestival+offset+'/'+limit)
  }
  getCategoryWiseDeletedFestival(offset,limit) {
    return this.api.getData(this.api.categoryWiseDeletedFestival+offset+'/'+limit)
  }
  getCategoryWiseDeletedCustom(offset,limit) {
    return this.api.getData(this.api.categoryWiseDeletedCustom+offset+'/'+limit)
  }
  getCategoryWiseFestival() {
    return this.api.getData(this.api.categoryWiseFestival)
  }
  addFestival(data) {
    return this.api.postDataWithImage(this.api.festival, data)
  }
  deleteFestival(id) {
    return this.api.deleteData(this.api.festival + '/' + id)
  }
  activeFestival(id) {
    return this.api.postData(this.api.activefestival,id)
  }
  editFestival(id, params) {
    return this.api.putWithImage(this.api.festival + '/' + id, params)
  }
  getFrames() {
    return this.api.getData(this.api.frames)
  }
  addFrames(params) {
    return this.api.putWithImage(this.api.frame, params)
  }
  deleteFrame(id) {
    return this.api.deleteData(this.api.frame + '/' + id)
  }
  getCustome() {
    return this.api.getData(this.api.customs)
  }
  getCategoryWiseCustome() {
    return this.api.getData(this.api.categoryWiseCustom)
  }
  addCustome(params) {
    return this.api.putWithImage(this.api.custome, params)
  }
  deleteCustomePost(id) {
    return this.api.deleteData(this.api.custome + '/' + id)
  }
  getEcards() {
    return this.api.getData(this.api.ecards)
  }
  addEcard(params) {
    return this.api.putWithImage(this.api.ecard, params)
  }
  deleteEcard(id) {
    return this.api.deleteData(this.api.ecard + '/' + id)
  }
  deletePermanent(id,type) {
    return this.api.deleteData(this.api.deletePermanent + '/' + id+'/'+type)
  }
  getNotifications() {
    return this.api.getData(this.api.notifications)
  }
  deleteNotification(id) {
    return this.api.deleteData(this.api.notification + '/' + id)
  }
  addNotification(params) {
    return this.api.postData(this.api.notification, params)
  }
  getFonts() {
    return this.api.getData(this.api.fonts)
  }
  addFont(data) {
    return this.api.postData(this.api.font, data)
  }
  editFont(id, params) {
    return this.api.putWithImage(this.api.font + '/' + id, params)
  }
  deleteFont(id) {
    return this.api.deleteData(this.api.fontdelete + '/' + id)
  }
  updateEcardIndex(data) {
    return this.api.postData(this.api.ecardChangeIdx, data)
  }
  updateCustomIndex(data) {
    return this.api.postData(this.api.customChangeIdx, data)
  }
  updateFestivalIndex(data) {
    return this.api.postData(this.api.changeFestivalIdx, data)
  }
  updateFrameIndex(data) {
    return this.api.postData(this.api.changeFrameIdx, data)
  }
  updateCategoryIndex(data) {
    return this.api.postData(this.api.changeCategoryIdx, data)
  }
  changeSubPlanIdx(data) {
    return this.api.postData(this.api.changeSubPlanIdx, data)
  }
  changeBusinessCategoryIdx(data) {
    return this.api.postData(this.api.changeBusinessCategoryIdx, data)
  }
  appversion(data) {
    return this.api.postData(this.api.appversion, data)
  }
  getversion() {
    return this.api.getData(this.api.getversion)
  }
  updateCustom(id, data) {
    return this.api.postData(this.api.updateCustom + '/' + id, data)
  }
  updateFrame(id, data) {
    return this.api.postData(this.api.updateFrame + '/' + id, data)
  }
  updateEcard(id, data) {
    return this.api.postData(this.api.updateEcard + '/' + id, data)
  }

  addsubscriptionPlan(params) {
    return this.api.postData(this.api.addsubscriptionPlan, params)
  }
  getsubscriptionPlan() {
    return this.api.getData(this.api.getsubscriptionPlan)
  }
  editsubscriptionPlan(id, data) {
    return this.api.postData(this.api.addsubscriptionPlan+'/'+id, data)
  }
  deletesubscriptionPlan(id) {
    return this.api.deleteData(this.api.deletesubscriptionPlan+'/'+id)
  }
  getUser(id){
    return this.api.getData(this.api.getUser+"/"+id)
  }
  addUserSubscription(id,data){
    return this.api.postData(this.api.updateUser+'/'+id , data)
  }
  addNewDesigner(data){
    return this.api.postData(this.api.signup,data)
  }
  getPopupBanner(){
    return this.api.getData(this.api.popupbanner);
  }
  UpdatePopupBanner(data) {
    return this.api.putWithImage(this.api.popupbannerUpdate ,data)
  }

  generateSheet(params){
    return this.api.getData(this.api.generateSheet+"/"+params);
  }
  DownloadExcelSheet(){
    return this.api.getData(this.api.downloadExcelSheet);
  }
  downloadPostZip(){
    return this.api.getData(this.api.downloadPostZip);
  }
  setAllUserPost(data) {
    return this.api.postDataWithImage(this.api.setAllUserPost, data);
  }
  // getAllUserPost(params){
  //   return this.api.getData(this.api.getAllUserPost,params);
  // }
  getSingleFestival(id){
    return this.api.getData(this.api.getSingleFestivalForAdmin+'/'+id)
  }

  expireSheet(){
    return this.api.getData(this.api.expireSheet)
  }
  getPrimeUserCounts() {
    return this.api.getData(this.api.primeUserCount)
  }
}

