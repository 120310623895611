import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import {
  ModalDismissReasons,
  NgbActiveModal,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { RestApiService } from 'src/app/services/rest-api.service';
import { CdkDragDrop, moveItemInArray, CdkDrag } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit {
  planDuraction = ['Monthly', 'Yearly'];

  planPrice;
  planDiscount;
  flatDiscount;
  descriptionGroup: FormGroup;
  getscriptionData;
  SubscriptionForm: FormGroup;

  duraction_value;
  selectedplan;
  isEdit = false;
  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private apiServices: RestApiService
  ) {}
  description0 = '';
  description1 = '';
  description2 = '';
  description3 = '';
  description4 = '';
  description5 = '';
  description6 = '';

  ngOnInit(): void {
    this.getsubscriptionPlan();
    this.descriptionGroup = this.fb.group({
      line: '',
    });
    this.SubscriptionForm = this.fb.group({
      planPrice: ['', Validators.required],
      planDiscount: ['', Validators.required],
      flatDiscount: ['', Validators.required],
      duraction: ['', Validators.required],
      description: this.fb.array([
        this.fb.group({
          line: [''],
        }),
      ]),
    });
    // this.addLine();

  }
  description(): FormArray {
    return this.SubscriptionForm.get('description') as FormArray;
  }
  addLine() {
    if(this.SubscriptionForm.value.description.length < 7){

      this.description().push(
        this.fb.group({
          line: '',
        })
        );
      }
  }

  editPlan(modal, data) {
    let copy: any = {};
    Object.assign(copy, data);
    this.selectedplan = data;
    this.description0 = data?.description[0]?.line || '';
    this.description1 = data?.description[1]?.line || '';
    this.description2 = data?.description[2]?.line || '';
    this.description3 = data?.description[3]?.line || '';
    this.description4 = data?.description[4]?.line || '';
    this.description5 = data?.description[5]?.line || '';
    this.description6 = data?.description[6]?.line || '';
    // delete copy.status
    // delete copy._id

    // // this.fb.group(...copy.description)
    // this.description().patchValue(copy.description);
    // this.descriptionGroup.patchValue(copy);
    // delete copy.description
    // this.SubscriptionForm.setValue(copy)

    this.isEdit = true;
    const activeModal = this.modalService.open(modal);
  }
  addPlan(modal) {
    this.isEdit = false;
    const activeModal = this.modalService.open(modal);
  }
  onEditSave() {
    this.getscriptionData = this.SubscriptionForm.value;
  }
  getDuractionValue(event) {
    this.duraction_value = event;
  }

  addNewPlan() {
    if (!this.isEdit) {
      this.apiServices
        .addsubscriptionPlan(this.SubscriptionForm.value)
        .then((res) => {
          if (res && res.code == 200) {
            this.modalService.dismissAll();
            this.getsubscriptionPlan();
            this.isEdit = false;
          }
        });
    } else {
     
 

      const data = {
        planPrice: this.SubscriptionForm.value.planPrice,
        planDiscount: this.SubscriptionForm.value.planDiscount,
        flatDiscount: this.SubscriptionForm.value.flatDiscount,
        duraction: this.SubscriptionForm.value.duraction,
        description: [
          { line: this.description0 },
          { line: this.description1 },
          { line: this.description2 },
          { line: this.description3 },
          { line: this.description4 },
          { line: this.description5 },
          { line: this.description6 },
        ],
      };
      console.log('data', data)
      this.apiServices.editsubscriptionPlan(this.selectedplan._id, data).then(res => {
        if (res && res.code == 200) {
          console.log('res', res)
          this.modalService.dismissAll();
          this.getsubscriptionPlan();
          this.isEdit = false
        }
      })
    }
  }
  getsubscriptionPlan() {
    this.apiServices.getsubscriptionPlan().then((res) => {
      if (res && res.code == 200) {
        const data = res.data;
        this.getscriptionData = data.sort((a, b) => a.index - b.index);
        console.log('getplans',this.getscriptionData);
      }
    });
  }
  deletesubscriptionPlan(id) {
    this.apiServices.deletesubscriptionPlan(id).then((res) => {
      if (res && res.code == 200) {
        this.modalService.dismissAll();
        this.getsubscriptionPlan();
        this.isEdit = false;
      }
    });
  }

  onDelete(id) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.apiServices
          .deletesubscriptionPlan(id)
          .then((res) => {
            if (res && res.code == 200) {
              this.modalService.dismissAll();
              this.getsubscriptionPlan();
              this.isEdit = false;
            }
          })
          .catch((err) => {
            console.log('error is', err);
          });
      }
    });
  }

  drop(event: CdkDragDrop<unknown>) {
    moveItemInArray(
      this.getscriptionData,
      event.previousIndex,
      event.currentIndex
    );

    this.getscriptionData.forEach(async (ele, index) => {
      ele.index = index;
      try {
        await this.apiServices.changeSubPlanIdx({
          item: this.getscriptionData[index],
        });
      } catch (error) {
        console.error('error in  ', index);
      }
    });
  }
  sortPredicate(index: number, item: CdkDrag<number>) {
    return (index + 1) % 2 === item.data % 2;
  }
}
