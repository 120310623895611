<div class=" content">
  <div class=" row">
    <div class=" col-md-12">
      <button class=" btn btn-fill btn-primary mr-3" (click)="goBack()">Back</button>
    </div>
    <div class="col-md-12">
      <div *ngIf="isSpinner === true" class="spinner">
        <div class="spinner-border" style="color: rgb(0, 17, 255)"></div>
      </div>
      <div class=" card p-4">
        <div class="row">
          <div class="col-md-12 vertical-center">

            <div class="preview">
              <!-- Set Image Position -->
              <!-- <div id="imgLocation">
                <img [src]="photoPosition" />
              </div> -->
              <!-- Image postion and width height-->

              <!-- Set Text Position -->


              <div class="main_img_div">


                <div class="img_div">

                  <img class="preview-image" (click)="showCoords($event)" [src]="frame" alt="Select Images">
                  <div id="AddTextLocation" [ngStyle]="{
                     'left': AddText.text.left === 0? 'auto' :AddText.text.left + '%',
                    'right':AddText.text.right === 0? 'auto' : AddText.text.right + '%',
                    'top':AddText.text.top === 0? 'auto' : AddText.text.top + '%',
                    'bottom':AddText.text.bottom  === 0? 'auto' : AddText.text.bottom + '%'
                  }">
                    Add Text Position
                  </div>
                  <div id="imgLocation" style="border: 2px solid green;" [ngStyle]="{
                    'left': AddText?.icon.left === 0? 'auto' :AddText?.icon.left + '%',
                    'top': AddText?.icon.top === 0? 'auto' : AddText?.icon.top + '%',
                    'width':AddText?.icon.width === 0? 'auto' : AddText?.icon.width + '%',
                    'height':AddText?.icon.height === 0? 'auto' : AddText?.icon.height + '%'
                  }"></div>

                </div>
                <!-- Edit Photo Side Menu -->
                <div style="width: 20%;"></div>

                <div class="sidePhotoEditMenu">

                  <h1>Add Image & Text</h1>
                  <!-- <div class="faq-drawer">
                    <input class="faq-drawer__trigger" id="faq-drawer" type="checkbox" />
                    <label class="faq-drawer__title" for="faq-drawer">Select Category </label>
                    <div class="faq-drawer__content-wrapper">
                      <div class="faq-drawer__content">
                        <select [(ngModel)]='cate' (change)="getcompanyid($event.target.value)">
                          <option value="{{category._id}}" *ngFor="let category of categoryList">{{category.name}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div> -->
                  <div class="faq-drawer">
                    <input class="faq-drawer__trigger" id="faq-drawer2" type="checkbox" />
                    <label class="faq-drawer__title" for="faq-drawer2">Image </label>
                    <div class="faq-drawer__content-wrapper">
                      <div class="faq-drawer__content">
                        <label>Select Image</label>
                        <input class=" form-control text-dark" placeholder="Image" (change)="browse($event)" type="file"
                          name="image" />
                      </div>
                    </div>
                  </div>
                  <!-- <div class="faq-drawer">
                    <input class="faq-drawer__trigger" id="faq-drawer3" (click)="toggleFun3()" type="checkbox" />
                    <label class="faq-drawer__title" for="faq-drawer3">Text Position </label>
                    <div class="faq-drawer__content-wrapper">
                      <div class="faq-drawer__content">
                        <div class="row">
                          
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text color </label>
                              <input class=" form-control color" type="color" [(ngModel)]="ColorValue"
                              (change)="colorValue()" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Other text size</label>
                              <input class=" form-control" type="number" min="10" max="35" [(ngModel)]="fontSize"
                              (change)="FontValue()" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="faq-drawer">
                    <input class="faq-drawer__trigger" id="faq-drawer4" (click)="toggleFun4()" type="checkbox" />
                    <label class="faq-drawer__title" for="faq-drawer4">Image Position </label>
                    <div class="faq-drawer__content-wrapper">
                      <div class="faq-drawer__content">
                        
                      </div>
                    </div>
                  </div> -->
                  <div class="faq-drawer">
                    <input class="faq-drawer__trigger" id="faq-drawer4" (click)="toggleFun4()" type="checkbox" />
                    <label class="faq-drawer__title" for="faq-drawer4">Extra Image Position </label>
                    <div class="faq-drawer__content-wrapper">
                      <div class="faq-drawer__content">
                        <div class="row">

                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Image Left </label>
                              <input class=" form-control" [value]="AddText.icon.left" type="number" max="90" min="0"
                                (input)="onPositionChange($event,'AddText','icon','left')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Image top </label>
                              <input class=" form-control" [value]="AddText.icon.top" type="number" max="80" min="0"
                                (input)="onPositionChange($event,'AddText','icon','top')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Image Width </label>
                              <input class=" form-control" [value]="AddText.icon.width" type="number" max="100" min="0"
                                (input)="onPositionChange($event,'AddText','icon','width')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Image height </label>
                              <input class=" form-control" [value]="AddText.icon.height" type="number" max="100" min="0"
                                (input)="onPositionChange($event,'AddText','icon','height')" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="faq-drawer">
                    <input class="faq-drawer__trigger" id="faq-drawer5" (click)="toggleFun3()" type="checkbox" />
                    <label class="faq-drawer__title" for="faq-drawer5">Add Text Position </label>
                    <div class="faq-drawer__content-wrapper">
                      <div class="faq-drawer__content">
                        <div class="row">
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text Left </label>
                              <input class=" form-control" [value]="AddText.text.left" type="number" max="90" min="0"
                                (input)="onPositionChange($event,'AddText','text','left')" />
                            </div>
                          </div>
                          <div class=" col-md-6">
                            <div class=" form-group">
                              <label>Text top </label>
                              <input class=" form-control" [value]="AddText.text.top" type="number" max="90" min="0"
                                (input)="onPositionChange($event,'AddText','text','top')" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="div-paid">
                      <label style=" font-size: 1.25em;">Paid</label>
                      <input class="isPaid" type="checkbox" [(ngModel)]="isPaid" />

                    </div>
                    <div class="card-footer">
                      <button class=" btn btn-fill btn-danger" *ngIf="isEdit === 'false'"
                        (click)="onSave()">Save</button>
                      <button class=" btn btn-fill btn-danger" *ngIf="isEdit === 'true'"
                        (click)="onEdit()">Update</button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>