<div
  *ngIf="isSpinner == true"
  class="spinner"
  style="position: fixed; top: 0; width: 100%; height: 100%; z-index: 99999"
>
  <div
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: rgba(179, 177, 177, 0.191);
    "
  >
    <div class="spinner-border" style="color: rgb(0, 17, 255)"></div>
  </div>
</div>
<div
  class="content"
  [ngStyle]="{ filter: isSpinner == true ? 'blur(1px)' : 'blur(0px)' }"
>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title font-weight-bold">Deleted Festival</h4>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="col-12 row border m-0 py-2">
                  <h3 class="col-3 py-2 font-weight-bold">Festival post</h3>
                  <input
                    class="form-control text-white col-9"
                    type="text"
                    [(ngModel)]="searchFestival"
                    (ngModelChange)="searchSubject.next($event)"
                    placeholder="search"
                  />
                </div>
                <table
                  class="table tablesorter col-md-12 col-sm-12 border border-muted"
                  id=""
                >
                  <thead class="text-primary bg-secondary" style="width: 100%">
                    <tr class="header-tag col-12 border-bottom border-warning">
                      <th
                        class="text-dark col-sm-5 col-md-4"
                        style="font-size: medium"
                      >
                        Name
                      </th>
                      <th
                        class="text-dark col-sm-4 col-md-6"
                        style="font-size: medium"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <div>
                      <div
                        *ngFor="let category of deletedFestivalList"
                        class="text-white"
                        style="border-radius: 20px"
                        [ngStyle]="{
                          border:
                            selectedCategory?._id == category?._id &&
                            isExpand
                              ? '1px solid gray'
                              : '0px'
                        }"
                      >
                        <tr class="header-tag col-12">
                          <td class="col-sm-5 col-md-4" >
                            {{ category.category.name }}
                          </td>
                          <td class="col-sm-4 col-md-6">
                            <button
                            style="padding: 5px 10px; border-radius: 5px;"
                              (click)="singleFestivalExpand(category)"
                              class="btn btn-fill btn-primary mr-3"
                            >
                              <img
                                src="../../../../assets/icons/ic-show.svg"
                                style="
                                  width: 20px;
                                  border-radius: 5px;
                                  cursor: pointer;
                                "
                              />
                            </button>
                            <button
                            style="padding: 5px 10px; border-radius: 5px;"
                              class="btn btn-fill btn-danger"
                              (click)="onActiveFestival(category,'festival')"
                            >
                              ACTIVE ALL
                            </button>
                          </td>
                        </tr>
                        <div
                          *ngIf="
                            selectedCategory?.category?._id ==
                              category?.category?._id && isExpand
                          "
                          style="width: 100%"
                        >
                          <td colspan="6" style="width: 100%">
                            <p>Festival Post</p>
                            <div
                              style="
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                              "
                              class="col-12 pb-4"
                            >
                              <div
                                *ngFor="let post of selectedCategory.festivals"
                              >
                                <div
                                  class="border p-1"
                                  style="width: 150px; position: relative"
                                >
                                  <img
                                    src="{{ post.image }}"
                                    style="width: 100%; height: 100%"
                                  />
                                  <img
                                    [src]="'assets/icons/ic-prime.svg'"
                                    *ngIf="post.isPaid"
                                    style="
                                      position: absolute;
                                      top: 8%;
                                      left: 10%;
                                      width: 10%;
                                      height: 10%;
                                    "
                                    alt=""
                                  />
                                  <div
                                    style="
                                      position: absolute;
                                      right: 10px;
                                      top: 10px;
                                      cursor: pointer;
                                    "
                                  >
                                    <button
                                      style="
                                        border-radius: 10%;
                                        border: 1px;
                                        background: lightcoral;
                                        color: white;
                                      "
                                      (click)="onActive(post,'festival')"
                                    >
                                      ACTIVE
                                    </button>
                                    <button
                                    style="
                                      border-radius: 10%;
                                      border: 1px;
                                      margin-left: 4px;
                                      background: rgb(255, 255, 255);
                                      color: white;
                                    "
                                    (click)="onDeletePermanent(post,'festival')"
                                  >
                                  <i
                                  class="tim-icons icon-trash-simple"
                                  style="
                                    font-size: 15px;
                                    color: rgb(240, 40, 40);
                                  "
                                ></i>
                                  </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </div>
                      </div>
                    </div>
                  </tbody>
                  <div class="row justify-content-between m-0 p-0">
                    <div>
                      <a
                        class="previous"
                        (click)="onChangePageFestival(false)"
                        *ngIf="offsetFestival != 0"
                        >&laquo; {{ offsetFestival }} Previous</a
                      >
                    </div>
                    <div>
                      <a class="next" style="cursor: not-allowed">{{
                        offsetFestival + 1
                      }}</a>
                    </div>
                    <div *ngIf="deletedFestivalList?.length > 9">
                      <a class="next" (click)="onChangePageFestival(true)"
                        >{{ offsetFestival + 2 }} Next &raquo;</a
                      >
                    </div>
                  </div>
                </table>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="col-12 row border m-0 py-2">
                  <h3 class="col-3 py-2 font-weight-bold">Custom post</h3>
                  <input
                    class="form-control text-white col-9"
                    type="text"
                    [(ngModel)]="searchCustom"
                    (ngModelChange)="getSearchedCustom($event)"
                    placeholder="search"
                  />
                </div>
                <table
                  class="table tablesorter col-md-12 col-sm-12 border border-muted"
                  id=""
                >
                  <thead class="text-primary bg-secondary" style="width: 100%">
                    <tr class="header-tag col-12 border-bottom border-warning">
                      <th
                        class="text-dark col-sm-5 col-md-4"
                        style="font-size: medium"
                      >
                        Name
                      </th>
                      <th
                        class="text-dark col-sm-4 col-md-6"
                        style="font-size: medium"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <div>
                      <div
                        *ngFor="let category of deletedCustomList"
                        class="text-white"
                        style="border-radius: 20px"
                        [ngStyle]="{
                          border:
                            selectedCategory?._id == category?.category?._id &&
                            isExpand
                              ? '1px solid gray'
                              : '0px'
                        }"
                      >
                        <tr class="header-tag col-12">
                         
                          <td class="col-sm-5 col-md-4" >
                            {{ category.category.name }}
                          </td>
                          <td class="col-sm-4 col-md-6">
                            <button
                            style="padding: 5px 10px; border-radius: 5px;"
                              (click)="singleFestivalExpand(category)"
                              class="btn btn-fill btn-primary mr-3"
                            >
                              <img
                                src="../../../../assets/icons/ic-show.svg"
                                style="
                                  width: 20px;
                                  border-radius: 5px;
                                  cursor: pointer;
                                "
                              />
                            </button>
                            <button
                            style="padding: 5px 10px; border-radius: 5px;"
                              class="btn btn-fill btn-danger"
                              (click)="onActiveFestival(category)"
                            >
                              ACTIVE ALL
                            </button>
                          </td>
                        </tr>
                        <div
                          *ngIf="
                            selectedCategory?.category?._id ==
                              category?.category?._id && isExpand
                          "
                          style="width: 100%"
                        >
                          <td colspan="6" style="width: 100%">
                            <p>Custom Post</p>
                            <div
                              style="
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                              "
                              class="col-12 pb-4"
                            >
                              <div
                                *ngFor="let post of selectedCategory.festivals"
                              >
                                <div
                                  class="border p-1"
                                  style="width: 150px; position: relative"
                                >
                                  <img
                                    src="{{ post.image }}"
                                    style="width: 100%; height: 100%"
                                  />
                                  <img
                                    [src]="'assets/icons/ic-prime.svg'"
                                    *ngIf="post.isPaid"
                                    style="
                                      position: absolute;
                                      top: 8%;
                                      left: 10%;
                                      width: 10%;
                                      height: 10%;
                                    "
                                    alt=""
                                  />
                                  <div
                                    style="
                                      position: absolute;
                                      right: 10px;
                                      top: 10px;
                                      cursor: pointer;
                                    "
                                  >
                                    <button
                                      style="
                                        border-radius: 10%;
                                        border: 1px;
                                        background: lightcoral;
                                        color: white;
                                      "
                                      (click)="onActive(post,'custom')"
                                    >
                                      ACTIVE
                                    </button>
                                    <button
                                    style="
                                      border-radius: 10%;
                                      border: 1px;
                                      margin-left: 4px;
                                      background: rgb(255, 255, 255);
                                      color: white;
                                    "
                                    (click)="onDeletePermanent(post,'custom')"
                                  >
                                  <i
                                  class="tim-icons icon-trash-simple"
                                  style="
                                    font-size: 15px;
                                    color: rgb(240, 40, 40);
                                  "
                                ></i>
                                  </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </div>
                      </div>
                    </div>
                  </tbody>
                  <div class="row justify-content-between m-0 p-0">
                    <div>
                      <a
                        class="previous"
                        (click)="onChangePageCustom(false)"
                        *ngIf="offsetCustom != 0"
                        >&laquo; {{ offsetCustom }} Previous</a
                      >
                    </div>
                    <div>
                      <a class="next" style="cursor: not-allowed">{{
                        offsetCustom + 1
                      }}</a>
                    </div>
                    <div *ngIf="deletedCustomList?.length > 9">
                      <a class="next" (click)="onChangePageCustom(true)"
                        >{{ offsetCustom + 2 }} Next &raquo;</a
                      >
                    </div>
                  </div>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
