
<div class="content">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header mb-3">
          <h4 class="card-title">Custom</h4>
          <div
            style="display: flex;flex-direction: row;align-items:center; justify-content: space-between; width: 90%;">
            <button class="btn btn-fill btn-primary mr-3" (click)="onAddFrame()">
              Add
            </button>
            <input class=" form-control text-white" type="text" [(ngModel)]="custome" placeholder="search"
              style="width: 30%;" />

          </div>
        </div>
        <div class="d-flex flex-wrap">
          <div *ngIf="isSpinner === true" class="spinner" style="position: absolute; width: 100%; height: 70vh; display: flex; justify-content: center; align-items: center;">
            <div class="spinner-border" style="color: rgb(0, 17, 255)"></div>
          </div>
          
          <div *ngFor="let festival of allCategoryPost |filter:custome" style="display: flex; flex-direction: column !important; width: 100%;">
            <div class="d-flex justify-content-between">
            <h3 class="card-title ml-2 m-0"> {{festival.category.name}}</h3>
            <button style="border-radius: 50%;  border: 0px; margin-right: 3%;"
                        (click)="onDeleteFestival(festival)" >
                            <i class="tim-icons icon-trash-simple d-flex  align-items-center justify-content-center"
                                style="font-size:15px;color:rgb(240, 40, 40)"></i>
                        </button>
            </div>
            <div style="display: flex;  flex-wrap: wrap;">
              <div *ngFor="let img of festival.custom; let i=index" >
                <div style="padding: 4%; position: relative;" >
                  <div style="width: 200px; padding: 2%;" >
                    <img  [src]="img.image"  style="position: relative;">
                    <img [src]="'assets/icons/ic-prime.svg'" *ngIf="img.isPaid" style="position: absolute; top: 8%; left: 10%; width: 10%; height: 10%;" alt="" />
                  </div>
                    <div style="position: absolute; top: 8%; right: 0%;">
                        <button style="border-radius: 50%;  border: 0px; "
                        (click)="onDelete(img)" >
                            <i class="tim-icons icon-trash-simple "
                                style="font-size:15px;color:rgb(240, 40, 40)"></i>
                        </button>
                        <button style="border-radius: 50%;  border: 0px;"
                     (click)="onEdit(img)">
                            <i class="tim-icons icon-pencil"
                                style="font-size:15px;color:rgb(0, 0, 0)"></i>
                        </button>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div *ngFor="let custome of customeframes | filter:custome" 
            class="example-box col-12 col-sm-12 col-md-4 col-lg-3 d-flex flex-column festival-card mb-4">
            <div style="width: 100%; position: relative;" class="d-flex align-items-center">
              <img [src]="custome.image" *ngIf="custome.image" alt="" />
              <img [src]="'assets/img/default-avatar.png'" *ngIf="!custome.image" alt="" />
              <img [src]="'assets/icons/ic-prime.svg'" *ngIf="custome.isPaid" class="prime-post" alt="" />
            </div>
            <div class="card-body w-100 m-0 p-2 mb-3 mb-sm-3 mb-md-0 mb-lg-0">
              <div class="d-flex flex-row justify-content-between">
                <h6 class="card-title m-0">Category</h6>
                <h5 class="card-title m-0"> {{
                  custome.categoryId && custome.categoryId.name
                  ? custome.categoryId.name
                  : "-"
                  }}</h5>
              </div>
              <div class="d-flex flex-row justify-content-between p-0 m-0">
                <h6 class="card-title m-0">Status</h6>
                <h6 class="card-title m-0">{{ custome.status }}</h6>
              </div>
              <div class="d-flex flex-row justify-content-between p-0 m-0">
                <h6 class="card-title m-0">Created At</h6>
                <h6 class="card-title m-0">{{ custome?.createdAt | date: "dd-MM-yyyy" }}</h6>
              </div>
            </div>
            <div class="card-body d-flex flex-row justify-content-between p-0 mb-2 w-100">
              <div style="width: 35%;">
                <button class="btn-fill btn-primary edit-delete" (click)="onEdit(custome)">Edit</button>
              </div>
              <div style="width: 35%;">
                <button class="btn-fill btn-danger edit-delete" (click)="onDelete(custome)">Delete</button>
              </div>
            </div> -->
          </div>
        </div>
        <!-- <div class="card-body">
            <div class="table-responsive">
              <table class="table tablesorter" id="">
                <thead class="example-box" style="width: 100%">
                  <tr class="header-tag">
                    <th style="width: 130px;">Category</th>
                    <th>Image</th>
                    <th>Status</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                  <div class="example-list">
                    <tr class="example-box" *ngFor="let custome of customeframes | filter:custome; 
                  let i = index" [cdkDropData]="custome" cdkDrag>
                      <td style="width: 130px;">
                        {{
                        custome.categoryId && custome.categoryId.name
                        ? custome.categoryId.name
                        : "-"
                        }}
                      </td>
                      <td>
                        <div class="avatar">
                          <img style="max-width: 30px" [src]="custome.image" *ngIf="custome.image" alt="" />
                          <img [src]="'assets/img/default-avatar.png'" *ngIf="!custome.image" alt="" />
                        </div>
                      </td>
                      <td>
                        {{ custome.status }}
                      </td>
                      <td>{{ custome?.createdAt | date: "dd-MM-yyyy" }}</td>
                      <td>{{ custome?.updatedAt | date: "dd-MM-yyyy" }}</td>
                      <td>
                        <button class="btn btn-fill btn-primary mr-3" (click)="onEdit(custome)">
                          Edit
                        </button>
                        <button class="btn btn-fill btn-danger" (click)="onDelete(custome)">
                          Delete
                        </button>
                      </td>
                    </tr>
                  </div>
                  <tr *ngIf="customeframes.length === 0">
                    <td colspan="5" class="text-center">Frames not found</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> -->
      </div>
    </div>
  </div>