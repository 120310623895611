import { environment } from './../../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { RestApiService } from 'src/app/services/rest-api.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray, CdkDrag } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-custome',
  templateUrl: './custome.component.html',
  styleUrls: ['./custome.component.scss']
})
export class CustomeComponent implements OnInit {
  selectedFestival;
  // baseUrl = environment.baseUrl;
  customeframes = [{
    categoryId: '',
    index: 1
  }]

  email = {
    pt: '0',
    pl: '0'
  };
  address = {
    pt: '0',
    pl: '0'
  };
  number = {
    pt: '0',
    pl: '0'
  };
  website = {
    pt: '0',
    pl: '0'
  };
  selectedFile
  custome
  constructor(private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public restApiService: RestApiService,
    private router: Router) {
    this.getCustome()
  }
  ngOnInit(): void {


  }
  shortDate(arrya: any[]) {
    return arrya.sort((a: any, b: any) => {
      var old = new Date(a.createdAt).getTime();
      var current = new Date(b.createdAt).getTime();
      return current - old;
    });
  }
  categoryList
  allCategoryPost
  tags
  img
  getCustome() {
    // const { data } = await this.restApiService.getCustome();
    //   this.customeframes = data.sort((a, b) => a.index - b.index);
    // this.customeframes = this.shortDate(data)

    this.restApiService.getCategoryWiseCustome().then(res => {

      if (res && res.code === 200) {
        this.categoryList = res.data //.sort((a, b) => a.category.index - b.category.index);
        this.allCategoryPost = this.categoryList
        const tags = this.allCategoryPost.map((x) => { return x.category.name })
        this.tags = ['All', ...tags]

        this.img = this.allCategoryPost[0].custom[0].image
      }
    }).catch(err => {
      console.log('error is ', err);
    })

  }
  frame
  drop(event: CdkDragDrop<unknown>) {

    moveItemInArray(this.customeframes, event.previousIndex, event.currentIndex);

    // this.customeframes.forEach(async (ele, index) => {
    //   ele.index = index;
    //   try {
    //     await this.restApiService.updateCustomIndex({ item: this.customeframes[index] })

    //   } catch (error) {
    //     console.error("error in  ", index);
    //   }
    // })
  }
  sortPredicate(index: number, item: CdkDrag<number>) {
    return (index + 1) % 2 === item.data % 2;
  }
  openPopup(content, item?) {

    if (item) {
      const obj = {
        categoryId: item.categoryId._id,
        title: item.title,
        _id: item._id,
        isPaid: item.isPaid
      }
      this.selectedFestival = obj
    }
    this.modalService.open(content);
  }
  async browse(ev) {

    this.selectedFile = ev.target.files[0]
    this.frame = await this.getBase64(this.selectedFile)

  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  addressChange(postion) {
    this.address = postion;

  }
  addresshChange(postion) {
    this.address += postion;
  }

  editcustomeframes
  async onEdit(i) {
    localStorage.setItem('isEdit', 'true');
    this.editcustomeframes = i
    console.log('i', i)
    this.router.navigateByUrl('/add-custome-image', { state: i })
  }

  onDelete(customeframes) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.restApiService.deleteCustomePost(customeframes._id).then(res => {
          if (res && res.code === 200) {
            Swal.fire(
              'Deleted!',
              'Frame has been deleted.',
              'success'
            )
            this.getCustome()
          }
        }).catch(err => {
          console.log('error is', err);
        })

      }
    })
  }
  onAddFrame() {
    localStorage.setItem('isEdit', 'false');
    this.router.navigateByUrl('/add-custome-image')
  }
  isSpinner
  onDeleteFestival(category) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.isSpinner = true;
        for (let indexi = 0; indexi < category.custom.length; indexi++) {
          this.restApiService.deleteCustomePost(category.custom[indexi]._id).then(res => {
            if (res && res.code === 200) {
              if (category.custom.length - 1 == indexi) {
                Swal.fire(
                  'Deleted!',
                  'Custom has been deleted.',
                  'success'
                )
                this.isSpinner = false;
                this.getCustome();
              }
            }
          }).catch(err => {
            console.log('error is', err);
          })
        }
      }
    })
  }
}
