// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // apiUrl: 'http://192.168.0.106:3000/api/',
  // baseUrl: 'http://192.168.0.106:3000',

  // apiUrl: 'https://brandspot.herokuapp.com/api/',
  // baseUrl: 'https://brandspot.herokuapp.com/',


  // apiUrl: 'https://shapemebackend.herokuapp.com/api/',
  // baseUrl: 'https://shapemebackend.herokuapp.com/',

  // apiUrl: 'https://shapeme-testing.herokuapp.com/api/',
  // baseUrl: 'https://shapeme-testing.herokuapp.com/',

  // apiUrl: 'https://shapeme.co.in/api/',
  // baseUrl: 'https://shapeme.co.in/',

  apiUrl: 'https://www.shapeme.co.in/api/',
  baseUrl: 'https://www.shapeme.co.in/',

  // apiUrl: 'http://localhost:3000/api/',
  // baseUrl: 'http://localhost:3000/',

  // ImageUrl: 'https://shapeme.s3.ap-south-1.amazonaws.com/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
