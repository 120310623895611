import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RestApiService } from 'src/app/services/rest-api.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  isSpinner = false;
  notifications;
  file;
  constructor(
    private modalService: NgbModal,
    public http: HttpClient,
    public restApiService: RestApiService
  ) {}

  ngOnInit(): void {
    this.getNotifications();
  }
  shortDate(arrya: any[]) {
    return arrya.sort((a: any, b: any) => {
      var old = new Date(a.createdAt).getTime();
      var current = new Date(b.createdAt).getTime();
      return current - old;
    });
  }

  // async getNotifications() {
  //   const { data } = await this.restApiService.getNotifications();
  //   this.notifications = data
  // }
  async getNotifications() {
    const { data } = await this.restApiService.getNotifications();
    this.notifications = this.shortDate(data);
    this.notifications;

  }
  onDelete(notifications) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.restApiService.deleteNotification(notifications._id).then(res => {
          if (res && res.code === 200) {
            Swal.fire(
              'Deleted!',
              'Frame has been deleted.',
              'success'
            )
            this.getNotifications()
          }
        }).catch(err => {
          console.log('error is', err);
        })

      }
    })
  }
  openPopup(content) {
    this.modalService.open(content);
  }

  fileSelect(event?) {
    this.file = event.target.files[0];
  }
  allData;

  async SendNotificationBtn(title?, msg?) {
    // this.isSpinner = true;
    let imgurl = '';
    if (this.file) {
      const obj = {
        image: this.file,
        title: title,
        body: msg,
      };
      
      try {
         await this.restApiService.addNotification(obj).then(res=>{
         if (res && res.code === 200) {
          imgurl = res.data.image
          this.allData = res.data
        }
      })
      } catch (error) {
        imgurl = '';
      }
      
    }else {
      const obj = {
        
        title: title,
        body: msg,
      };
      
      try {
         await this.restApiService.addNotification(obj).then(res=>{
         if (res && res.code === 200) {
          imgurl = ''
          this.allData = res.data
        }
      })
      } catch (error) {
        imgurl = '';
      }
      
    }

    if (confirm('Are you sure to send ?')) {
      let body = {
        notification: {
          title: title,
          body: msg,
          sound: 'default',
          click_action: 'FCM_PLUGIN_ACTIVITY',
            // "schedule": { "at": new Date(Date.now() + 1000 * 5) },
          image: imgurl ? imgurl : '',
        },
        data: {
          title: title,
          body: msg,
          sound: 'default',
          click_action: 'FCM_PLUGIN_ACTIVITY',
          image: imgurl ? imgurl : '',
        },
        android: {
          notification: {
            icon:imgurl ? imgurl : '',
            click_action: "FCM_PLUGIN_ACTIVITY"
          }
        },
        to: '/topics/all',
        // to:"djp9G7JvTOCA8k8OtabrKq:APA91bHHHwbZKUK_krQrziHdGwWw_j_LdzX4LaDJ74L1cSmNsFpNdlm_ZU9SXrn-xQOvcqs4qAukd5TBbTZlEk4r1MoQR6KTZb70irJ6mQOJcP0Kpn_PgyLd0rTAZakf4AFN9xN5exzW",
        priority: 'high',
      };
      let options = new HttpHeaders().set('Content-type', 'Application/json');
      this.http
        .post('https://fcm.googleapis.com/fcm/send', body, {
          headers: options.set(
            'Authorization',
            'key=AAAAyZsBpJ8:APA91bEuF1wYuysSsGktQzeROoCs5E8F9OsOkkR_Jjqc6GSc2G3j7sIuiYWvuGB9A_1is2UzXVMeVMFnHpbglNdQpZYva5lKBq8n4LT7NGzBqtss5MHpgB-69Of-I6D-fHSRGIjdgZmo'
          ),
        })
        .subscribe(console.log, console.error);
        this.modalService.dismissAll()
        this.getNotifications();
        this.isSpinner = false  
    }else this.isSpinner = false
  }
}
