<div class=" content">
    <div class=" row">
        <div class=" col-md-12">
            <div class=" card">
                <div class=" card-header">
                    <h4 class=" card-title">All User Post</h4>
                </div>
                <div class=" card-body">
                    <div class=" row">
                        <div class="col-md-12">
                            <div class=" card p-4">
                                <div class="row">
                                    <div class="col-md-12 vertical-center">
                                        <div class="preview">
                                            <div class="main_img_div">
                                                <div class="img_div">
                                                    <ng-container *ngIf="frame">
                                                        <img class="logo-svg" [src]=" cardlogo" style="object-fit: contain; border: 1px solid red;" [ngStyle]="{
                                                            'left': logo.left === 0? 'auto' :logo.left + '%',
                                                            'top': logo.top === 0? 'auto' : logo.top + '%',
                                                            'width':logo.img_width === 0? 'auto' : logo.img_width + 'px',
                                                            'height':logo.img_height === 0? 'auto' : logo.img_height + 'px'
                                                          }">
                                                        <label class="business-text" [src]=" cardlogo" style="border: 1px solid red;" [ngStyle]="{
                                                            'left': businessText.left === 0? 'auto' :businessText.left + '%',
                                                            'top': businessText.top === 0? 'auto' : businessText.top + '%',
                                                            'font-size': businessText.size === 0? 'auto' : businessText.size + 'px',
                                                            'color':businessText.color
                                                          }">Business Name</label>
                                                    </ng-container>
                                                    <img class="preview-image" [src]="post" alt="">
                                                    <img class="preview-frame" [src]="frame" alt="Select Images">
                                                </div>
                                                <!-- Edit Photo Side Menu -->
                                                <div style="width: 5%;"></div>
                                                <div class="sidePhotoEditMenu" id="style-1">
                                                    <h1>Add Details</h1>
                                                    <div class="faq-drawer">
                                                        <input class="faq-drawer__trigger" id="faq-drawer"
                                                            type="checkbox" />
                                                        <label class="faq-drawer__title" for="faq-drawer">Post
                                                        </label>
                                                        <div class="faq-drawer__content-wrapper">
                                                            <div class="faq-drawer__content">
                                                                <div class=" row">
                                                                    <div class=" col-md-12">
                                                                        <div class=" form-group">
                                                                            <label>Select Post</label>
                                                                            <input class=" form-control text-dark"
                                                                                placeholder="Image"
                                                                                (change)="browsePost($event)"
                                                                                type="file" name="image" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="faq-drawer">
                                                        <input class="faq-drawer__trigger" id="faq-drawer0"
                                                            (click)="toggleFun0()" type="checkbox" />
                                                        <label class="faq-drawer__title" for="faq-drawer0">Frame
                                                        </label>
                                                        <div class="faq-drawer__content-wrapper">
                                                            <div class="faq-drawer__content">
                                                                <div class=" row">
                                                                    <div class=" col-md-12">
                                                                        <div class=" form-group">
                                                                            <label>Select frame</label>
                                                                            <select
                                                                                (change)="getcompanyid($event.target.value)">
                                                                                <option value="{{i}}"
                                                                                    style="width: 100px !important;"
                                                                                    *ngFor="let category of allFrames;let i=index">
                                                                                    {{category.index}}
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!--Logo icon position -->
                                                    <div class="faq-drawer">
                                                        <input class="faq-drawer__trigger" id="faq-drawer1"
                                                            (click)="toggleFun1()" type="checkbox" />
                                                        <label class="faq-drawer__title" for="faq-drawer1">Set
                                                            Logo</label>
                                                        <div class="faq-drawer__content-wrapper">
                                                            <div class="faq-drawer__content">
                                                                <label>Select Logo Position</label>
                                                                <div class="row">
                                                                    <!--Logo icon position -->
                                                                    <div class=" col-md-4">
                                                                        <div class=" form-group">
                                                                            <label>icon Left </label>
                                                                            <input class=" form-control" type="number"
                                                                                max="100" min="0"
                                                                                [value]="logo.left"
                                                                                (input)="onPositionChange($event,'logo','left')" />
                                                                        </div>
                                                                    </div>
                                                                    <div class=" col-md-4">
                                                                        <div class=" form-group">
                                                                            <label>icon Top </label>
                                                                            <input class=" form-control" type="number"
                                                                                max="100" min="0"
                                                                                [value]="logo.top"
                                                                                (input)="onPositionChange($event,'logo','top')" />
                                                                        </div>
                                                                    </div>
                                                                    <div class=" col-md-4">
                                                                        <div class=" form-group">
                                                                            <label>icon Width </label>
                                                                            <input class=" form-control" type="number"
                                                                                max="400" min="40"
                                                                                [value]="logo.img_width"
                                                                                (input)="onChangeWidthHeight($event,'logo','img_width')" />
                                                                        </div>
                                                                    </div>
                                                                    <div class=" col-md-4">
                                                                        <div class=" form-group">
                                                                            <label>icon Height</label>
                                                                            <input class=" form-control" type="number"
                                                                                max="400" min="40"
                                                                                [value]="logo.img_height"
                                                                                (input)="onChangeWidthHeight($event,'logo','img_height')" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!--Business Text position -->
                                                    <div class="faq-drawer">
                                                        <input class="faq-drawer__trigger" id="faq-drawer2"
                                                            (click)="toggleFun2()" type="checkbox" />
                                                        <label class="faq-drawer__title" for="faq-drawer2">Set
                                                            Business Text</label>
                                                        <div class="faq-drawer__content-wrapper">
                                                            <div class="faq-drawer__content">
                                                                <label>Select Business text Position</label>
                                                                <div class="row">
                                                                    <!--Logo icon position -->
                                                                    <div class=" col-md-4">
                                                                        <div class=" form-group">
                                                                            <label>icon Left </label>
                                                                            <input class=" form-control" type="number"
                                                                                max="100" min="0"
                                                                                [value]="businessText.left"
                                                                                (input)="onPositionChange($event,'businessText','left')" />
                                                                        </div>
                                                                    </div>
                                                                    <div class=" col-md-4">
                                                                        <div class=" form-group">
                                                                            <label>icon Top </label>
                                                                            <input class=" form-control" type="number"
                                                                                max="100" min="0"
                                                                                [value]="businessText.top"
                                                                                (input)="onPositionChange($event,'businessText','top')" />
                                                                        </div>
                                                                    </div>
                                                                    <div class=" col-md-4">
                                                                        <div class=" form-group">
                                                                            <label>Font size</label>
                                                                            <input class=" form-control" type="number"
                                                                                max="40" min="15"
                                                                                [value]="businessText.size"
                                                                                (input)="onChangeWidthHeight($event,'businessText','size')" />
                                                                        </div>
                                                                    </div>
                                                                    <div class=" col-md-6">
                                                                        <div class=" form-group">
                                                                            <label>Text Color </label>
                                                                            <input class=" form-control" type="color"
                                                                                [value]="businessText.color"
                                                                                (input)="onChangeBusinessTextColor($event,'businessText','color')" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class=" card-footer">
                                                        <button class=" btn btn-fill btn-danger" (click)="DownloadData()">Download</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>