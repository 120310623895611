import { Component, OnInit } from '@angular/core';
import {
  ModalDismissReasons,
  NgbActiveModal,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { RestApiService } from 'src/app/services/rest-api.service';
import Swal from 'sweetalert2';
import { CdkDragDrop, moveItemInArray, CdkDrag } from '@angular/cdk/drag-drop';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit {
  categories = [];
  closeResult: string;
  categoryName: string;
  EndDate: string;
  type: string
  selectedCategory;

  selectedFestival;

  searchName: string;
  offset = 0;
  limit = 20;
  isExpand = false;
  festivalArray = [];
  customArray = [];
  festival = {
    categoryId: '',
    image: null,
    title: '',
    isPaid: false,
  };
  isSpinner = false;
  newFestivalImgURL: any = 'assets/img/uploadFile.png';


  categoriesList = [];
  constructor(
    private restApiService: RestApiService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private router: Router,
    public http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.getCategory();
  }
  shortDate(arrya: any[]) {
    return arrya.sort((a: any, b: any) => {
      var old = new Date(a.createdAt).getTime();
      var current = new Date(b.createdAt).getTime();
      return current - old;
    });
  }
  drop(event: CdkDragDrop<unknown>) {
    moveItemInArray(
      this.categoriesList,
      event.previousIndex,
      event.currentIndex
    );

    this.categoriesList.forEach(async (ele, index) => {
      ele.index = index;
      try {
        await this.restApiService.updateCategoryIndex({
          item: this.categoriesList[index],
        });
      } catch (error) {
        console.error('error in  ', index);
      }
    });
  }
  sortPredicate(index: number, item: CdkDrag<number>) {
    return (index + 1) % 2 === item.data % 2;
  }
  previousOrNext(isNext) {
    this.isSpinner = true;
    if (isNext) {
      this.offset++;
      this.getCategory();
    } else {
      if (this.offset >= 0) {
        this.offset--;
        this.getCategory();
      }
    }
  }

  getCategory() {
    this.restApiService
      .getCategoryWithOffset(this.offset, this.limit)
      .then((res) => {
        if (res && res.code === 200) {
          this.categories = res.data;
          this.isSpinner = false;
        }
      })
      .catch((err) => {
        this.isSpinner = false;
        console.log('error is', err);
      });
  }
  getAllCategory(content) {
    this.isSpinner = true;
    this.modalService.open(content).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.getCategory();
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
    this.restApiService
      .getCategory()
      .then((res) => {
        if (res && res.code === 200) {
          this.categoriesList = res.data; //.sort((a, b) => a.index - b.index);
          this.isSpinner = false;
        }
      })
      .catch((err) => {
        this.isSpinner = false;
        console.log('error is', err);
      });
  }
  open(content) {
    this.modalService.open(content).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  extraData = {
    EndDate: '',
    type: '',
  };
  onSave(modal) {
    const obj = {
      name: this.categoryName,
      extraData: {
        EndDate: this.EndDate,
        type: this.type
      },
    };
    this.restApiService
      .addCateogry(obj)
      .then((res) => {
        if (res && res.code === 200) {
          modal.close();
          this.getCategory();
        }
      })
      .catch((err) => {
        console.log('error is', err);
      });
    this.categoryName = '';
  }
  onEdit(modal, category) {
    this.selectedCategory = JSON.parse(JSON.stringify(category));
    const enddate = this.selectedCategory?.extraData;
    if (enddate?.EndDate || enddate?.type) {
      this.extraData = this.selectedCategory.extraData;
    } else {
      this.extraData = {
        EndDate: '',
        type: 'default'
      };
    }

    const activeModal = this.modalService.open(modal);
  }
  onDelete(category) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.restApiService
          .deleteCategory(category._id)
          .then((res) => {
            if (res && res.code === 200) {
              Swal.fire('Deleted!', 'Category has been deleted.', 'success');
              this.getCategory();
            }
          })
          .catch((err) => {
            console.log('error is', err);
          });
      }
    });
  }
  onEditSave(modal) {
    const obj = {};
    Object.assign(obj, {
      name: this.selectedCategory.name,
      extraData: {
        EndDate: this.extraData.EndDate,
        type: this.extraData.type
      },
    });
    this.restApiService
      .editCateogry(this.selectedCategory._id, obj)
      .then((res) => {
        if (res && res.code === 200) {
          modal.close();
          this.getCategory();
        }
      })
      .catch((err) => {
        console.log('error is', err);
      });
  }
  async getSingleFestival(category) {
    if (this.isExpand && category?._id == this.selectedCategory?._id) {
      this.isExpand = false;
    } else {
      this.selectedCategory = category;
      await this.restApiService.getSingleFestival(category._id).then((res) => {
        this.isExpand = true;
        this.festivalArray = res.data.festival;
        this.customArray = res.data.custom;
      });
    }
  }
  onDeleteFestival(festival, isFestival) {
    console.log(festival._id);
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        if (isFestival) {
          this.restApiService
            .deleteFestival(festival._id)
            .then((res) => {
              console.log({ res });
              if (res && res.code === 200) {
                this.isExpand = false;
                Swal.fire('Deleted!', 'Festival has been deleted.', 'success');
              }
            })
            .catch((err) => {
              console.log('error is', err);
            });
        } else {
          this.restApiService
            .deleteCustomePost(festival._id)
            .then((res) => {
              console.log({ res });
              if (res && res.code === 200) {
                this.isExpand = false;
                Swal.fire('Deleted!', 'Festival has been deleted.', 'success');
              }
            })
            .catch((err) => {
              console.log('error is', err);
            });
        }
      }
    });
  }
  openPopup(content, item?, festival?) {
    if (item) {
      const obj = {
        categoryId: item._id,
        id: festival?._id,
        title: item?.name,
        isPaid: festival?.isPaid || false,
        extraData: festival?.extraData ? festival?.extraData : '',
        image: festival?.image
      };

      console.log(festival);
      
      this.festival.categoryId = item._id;
      this.selectedFestival = obj;
    }
    this.modalService.open(content);
  }
  browse(event) {
    this.newFestivalImgURL = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(event.target.files[0]));
    this.festival.image = event.target.files[0];
  }

  onFestivalSave(modal) {
    this.isSpinner = true;

    const obj = {
      categoryId: this.festival.categoryId,
      title: this.selectedFestival?.title,
      image: this.festival?.image,
      isPaid: this.festival?.isPaid || false,
      extraData: JSON.stringify({
        text: this.selectedFestival?.text,
        textPositionLeft: this.selectedFestival?.textLeft,
        textPositionTop: this.selectedFestival?.textTop
      }),
    };
    this.restApiService
      .addFestival(obj)
      .then((res) => {
        if (res && res.code === 200) {
          modal.close();
          this.getCategory();
          this.isSpinner = false;
        }
      })
      .catch((err) => {
        this.isSpinner = false;
        console.log('error is', err);
      });
  }

  getSearchedUserDetails(value) {
    if (value.length > 2) {
      this.restApiService.getSearchCategory(value).then(
        async (res) => {
          if (res && res.code == 200) {
            this.categories = res.data.sort((a, b) => a.index - b.index);
          }
        },
        (err) => {
          console.log('err is', err);
        }
      );
    } else {
      this.getCategory();
    }
  }

  onEditFestivalSave(modal) {
    let obj: any;
    if (!this.selectedFestival.isPaid) {
      this.selectedFestival.isPaid = false;
    }
    obj = {
      categoryId: this.selectedFestival.categoryId,
      title: this.selectedFestival.title,
      isPaid: this.selectedFestival.isPaid,
      extraData: JSON.stringify(this.selectedFestival.extraData),
    };
    
    if (this.festival.image) {
      obj.image = this.festival.image;
    }
    console.log('obj', obj);
    this.restApiService
      .editFestival(this.selectedFestival.id, obj)
      .then((res) => {
        if (res && res.code === 200) {
          modal.close();
          this.getCategory();
        }
      })
      .catch((err) => {
        console.log('error is', err);
      });
  }

  //  add custome post
  async onAddCustomPost(i) {
    localStorage.setItem('isEdit', 'false');
    this.router.navigateByUrl('/add-custome-image', { state: i });
  }

  async onEditCustomePost(i) {
    localStorage.setItem('isEdit', 'true');
    console.log('i', i);
    this.router.navigateByUrl('/add-custome-image', { state: i });
  }

  notificationImage
  selectedImageIndex
  selectedImageCategory
  openNotificationPopup(content, img, i?, festival_category_id?) {
    this.modalService.open(content);
    this.notificationImage = img.image
    console.log(img.image);
    if (i && festival_category_id) {
      this.selectedImageIndex = i
      this.selectedImageCategory = festival_category_id
    }
  }

  async SendNotificationBtn(title?, msg?) {
    let imgurl = this.notificationImage;

    if (confirm('Are you sure to send ?')) {

      let body = {
        notification: {
          title: title,
          body: msg,
          sound: 'default',
          click_action: 'FCM_PLUGIN_ACTIVITY',
          // "schedule": { "at": new Date(Date.now() + 1000 * 5) },
          image: imgurl ? imgurl : '',
        },
        data: {
          title: title,
          body: msg,
          index: this.selectedImageIndex ? this.selectedImageIndex : '',
          categoryId: this.selectedImageCategory ? this.selectedImageCategory : '',
          sound: 'default',
          click_action: 'FCM_PLUGIN_ACTIVITY',
          image: imgurl ? imgurl : '',
        },
        android: {
          notification: {
            icon: imgurl ? imgurl : '',
            click_action: "FCM_PLUGIN_ACTIVITY"
          }
        },
        to: '/topics/all',
        // to: "dP7O-DZcTdWF_WJc6sSRg5:APA91bEjiMG9OI21TKBlW8iVty-uz2kT2xdWsKRGPLJRnqBBZiu_8YVpjiXb1kuT1OlowVtyZxRKoJhQ8cTFsYXncLwH6dCy30aHR8741iLuKVTvUtTubAnTOLd2RjLLQ-kihvjB_8Vc",
        priority: 'high',
      };
      let options = new HttpHeaders().set('Content-type', 'Application/json');
      console.log('SendNotificationBtn', body)
      this.http
        .post('https://fcm.googleapis.com/fcm/send', body, {
          headers: options.set(
            'Authorization',
            'key=AAAAyZsBpJ8:APA91bEuF1wYuysSsGktQzeROoCs5E8F9OsOkkR_Jjqc6GSc2G3j7sIuiYWvuGB9A_1is2UzXVMeVMFnHpbglNdQpZYva5lKBq8n4LT7NGzBqtss5MHpgB-69Of-I6D-fHSRGIjdgZmo'
          ),
        })
        .subscribe(console.log, console.error);

      this.addDB(title, msg)
      this.modalService.dismissAll()

      //  easy 5 steps to shape your business !!
      // Shapeme believes in simplicity. You can grow your business in easy 5 steps choose your category and poster then set a logo and frame go to share it.
    } else this.isSpinner = false


  }
  async addDB(title, msg) {
    const obj = {
      image: this.notificationImage,
      title: title,
      body: msg,
    };

    try {
      await this.restApiService.addNotification(obj).then(res => {
        if (res && res.code === 200) {

          this.isSpinner = false
        }
      })
    } catch (error) {
      console.log(error);
      // imgurl = '';
    }
  }

}
