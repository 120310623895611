<div class="content">
  <div class="row" style=" height: 90vh; overflow: hidden !important;">
    <div class="col-md-12" style=" overflow: hidden !important;">
      <div style="display: flex;flex-direction: row;align-items:center; justify-content: space-between; width: 90%;">
        <button class="btn btn-fill btn-primary mr-3" (click)="goBack()">
          Back
        </button>


      </div>
    </div>
    <div class="main_div">
      <div id="style-1" class="left_content">
        <div *ngFor="let item of businessdetails">
          <div class="card col-12 p-0">
            <div class="d-flex justify-content-end align-items-center m-1 card-div">
              <button (click)="onDelete(item)">
                <i class="tim-icons icon-trash-simple mr-2 " style="font-size:18px;color:rgb(255, 198, 198)"></i>
              </button>
              <button (click)="onEdit(editContent,item)">
                <i class="tim-icons icon-pencil" style="font-size:18px;color:rgb(255, 198, 198)"></i>
              </button>
            </div>
            <div class="d-flex col-12 pt-3">
              <img class="col-4" [src]="item.logo" *ngIf="item.logo && item.logo !='null'" alt="" height="auto"
                width="40%">
              <img class="col-4" [src]="'assets/img/default-avatar.png'"
                *ngIf="!item.logo || item.logo == '' || item.logo=='null'" alt="" height="auto" width="40%">
              <div class="col-6 p-10 d-flex flex-column">
                <h3>BussinessName : {{item.bussinessName}}</h3>
                <p>Bussinesscategory : {{item.businesscategory}}</p>
              </div>
            </div>

            <div class="col-12  py-3">
              <!-- <div class="col-3 p-0">
                <img [src]="item.logo" *ngIf="item.logo && item.logo !='null'" alt="" height="auto" width="80%">
                <img [src]="'assets/img/default-avatar.png'" *ngIf="!item.logo || item.logo == '' || item.logo=='null'"
                  alt="" height="auto" width="100%">
              </div> -->
              <div class="col-12 p-tag">
                <!-- <p>BussinessName : {{item.bussinessName}}</p> -->
                <!-- <p>Bussinesscategory : {{item.businesscategory}}</p> -->
                <p>UserName : {{item.userName}}</p>
                <p>Designation : {{item.designation}}</p>
                <p>ContactNumber : {{item.contactNumber}}</p>
                <p>ExtracontactNumber : {{item.extracontactNumber}}</p>
              </div>
              <div class="col-12 p-tag">
                <p>Email : {{item.email}}</p>
                <p>Website : {{item.website}}</p>
                <p>Address : {{item.address}}</p>
                <p>City : {{item.city}}</p>
                <p>State : {{item.state}}</p>
                <p>CreatedAt : {{item.createdAt}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="style-1"
        style=" overflow-y: scroll; width: 40%;  height: 80vh;position: relative;  padding: 3%; border: 1px solid rgb(139, 139, 139);  border-radius: 10px;">
        <div class="col-12">
          <div class="d-flex align-items-center "
            style="border: 1px solid gray; position: relative; background: rgb(100, 100, 100); border-radius: 10px; padding: 5%; ">
            <img src="..///..//..//.//..//assets/img/ic-phonebook.svg" alt="" height="50px" width="50px" class="mr-3">
            <h3 class="card-title m-0 p-0">{{viewUser.mobileNo}}</h3>
          </div>
          <button
            style="color: white; font-weight: 700; text-align: center; margin-top: 5%; width: 100%; height: 40px; background: rgb(100, 100, 100); border-radius: 5px; border:1px solid gray;"
            (click)="addUserPlanSubscription(selectPlan)">
            Add Plan
          </button>
          <div class="col-12">
            <div class="pay-card p-3 mb-0" *ngFor="let plan of viewUserExtraData;let i = index"
              [ngStyle]="{'background': plan.isSubscription == 'true' || plan.isSubscription == true ? '#030ffc' : '#fc0328'}"
              style="border: 1px solid gray; position: relative; border-radius: 10px;  margin-top: 5%; ">
              <h3>Membership<strong><span> ₹ {{plan.planAmount}}/</span> {{plan.planDuraction}} </strong></h3>
              <div style="display: flex; flex-direction: row; align-items: flex-end; justify-content: space-between;">
                <h5>Start :{{plan.startDate | date: "dd-MM-yyyy" }}</h5>
                <h5>End :{{plan.endDate | date: "dd-MM-yyyy" }}</h5>
              </div>
              <button style="background: transparent; border: 0px; position: absolute; top: 5px; right: 5px; "
                (click)="updateSubscriptionPlan(editPlan,plan,i)">
                <i class="tim-icons icon-pencil"
                  style="font-size:12px; color:rgb(255, 198, 198); border: 1px solid rgb(255, 198, 198); border-radius: 50%; padding: 3px;"></i>
              </button>
            </div>


          </div>
          <!-- <div class="pay-card p-3 mb-0"
            style="width: 100%; display: flex; justify-content: center; align-items: center;">
            <button style="background: transparent; border: 0px; " (click)="addUserPlanSubscription(selectPlan)">
              <i class="tim-icons icon-simple-add"
                style="font-size:15px; color:rgb(255, 198, 198); border: 1px solid rgb(255, 198, 198); border-radius: 50%; padding: 5px;"></i>
            </button>
          </div> -->
        </div>
      </div>
    </div>

    <!-- 
    <div class="col-md-12 ">
      <div class="card">
        <div class="card-body ">
          <div class="col-12 d-flex align-items-center">
            <div class="col-6 d-flex align-items-center">
              <img src="..///..//..//.//..//assets/img/ic-phonebook.svg" alt="" height="50px" width="50px" class="mr-3">
              <h4 class="card-title m-0 p-0">{{viewUser.mobileNo}}</h4>
            </div>
            <div class="col-6" style="display: flex;  justify-content: flex-end;">
              <div class="pay-card p-3 mb-0" *ngFor="let plan of viewUser.extraData;let i = index"
                style="border: 1px solid gray; text-align: right; position: relative;  border-radius: 5px; margin-right: 5%;">
                <h3>Membership <span>₹{{plan.planAmount}}/</span> {{plan.planDuraction}}</h3>
                <div style="display: flex; flex-direction: column; align-items: flex-end;">
                  <h5>Start :{{plan.startDate | date: "dd-MM-yyyy" }}</h5>
                  <h5>End :{{plan.endDate | date: "dd-MM-yyyy" }}</h5>
                </div>
                <button style="background: transparent; border: 0px; position: absolute; top: -10px; right: -5px;"
                  (click)="updateSubscriptionPlan(editPlan,plan,i)">
                  <i class="tim-icons icon-pencil"
                    style="font-size:18px; color:rgb(255, 198, 198); border: 1px solid rgb(255, 198, 198); border-radius: 50%; padding: 5px;"></i>
                </button>
              </div>
            </div>
          </div>
          <div *ngFor="let item of businessdetails">
            <div class="card col-12 mt-4 p-0" style="border: 1px solid gray;">
              <div class="d-flex justify-content-end align-items-center m-1"
                style=" z-index: 99; position: absolute; right: 0; top: 0;">
                <button style="background: transparent; border: 0px;" (click)="onDelete(item)">
                  <i class="tim-icons icon-trash-simple mr-2 " style="font-size:18px;color:rgb(255, 198, 198)"></i>
                </button>
                <button style="background: transparent; border: 0px;" (click)="onEdit(editContent,item)">
                  <i class="tim-icons icon-pencil" style="font-size:18px;color:rgb(255, 198, 198)"></i>
                </button>
              </div>
              <div class="col-12 d-flex flex-row py-3">
                <div class="col-3 p-0">
                  <img [src]="item.logo" *ngIf="item.logo && item.logo !='null'" alt="" height="auto" width="80%">
                  <img [src]="'assets/img/default-avatar.png'"
                    *ngIf="!item.logo || item.logo == '' || item.logo=='null'" alt="" height="auto" width="100%">
                </div>
                <div class="col-4 p-tag">
                  <p>BussinessName : {{item.bussinessName}}</p>
                  <p>Bussinesscategory : {{item.businesscategory}}</p>
                  <p>UserName : {{item.userName}}</p>
                  <p>Designation : {{item.designation}}</p>
                  <p>ContactNumber : {{item.contactNumber}}</p>
                  <p>ExtracontactNumber : {{item.extracontactNumber}}</p>
                </div>
                <div class="col-4 p-tag">
                  <p>Email : {{item.email}}</p>
                  <p>Website : {{item.website}}</p>
                  <p>Address : {{item.address}}</p>
                  <p>City : {{item.city}}</p>
                  <p>State : {{item.state}}</p>
                  <p>CreatedAt : {{item.createdAt}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</div>

<!-- Add Subscription Modal -->
<ng-template #selectPlan let-editModal>
  <div class=" modal-header">
    <h4 class="modal-title">Add Subscription</h4>
    <button type="button" class="close" aria-label="Close" (click)="editModal.close('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class=" form-group" style="padding-left: 5%; padding-right: 5%;">
    <span>Plan payment Id</span>
    <input class=" form-control text-dark bg-white" placeholder="razorpay_p_id" type="text" [(ngModel)]="razorpay_p_id"
      name="categoryName" />
  </div>
  <div
    style="width: 100%; padding-left: 5%; padding-right: 5%; padding-bottom: 5%;">

    <button class="btn btn-fill  btn-warning col-12"  (click)="sendPlan(datas)"
      *ngFor="let datas of getscriptionData;">
      {{datas?.planDiscount}} &#x20B9; per {{datas?.duraction}}
    </button>

  </div>
</ng-template>

<!-- Edit  Subscription Plan Modal -->
<ng-template #editPlan let-editModal>
  <div class=" modal-header">
    <h4 class="modal-title">Edit Subscription Plan</h4>
    <button type="button" class="close" aria-label="Close" (click)="editModal.close('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class=" form-group">
      <span>Plan payment Id</span>
      <input class=" form-control text-dark bg-white" disabled placeholder="website" type="text"
        [(ngModel)]="selectedplan.razorpay_p_id" name="categoryName" />
    </div>
    <div class=" form-group">
      <span>Plan Amount</span>
      <input class=" form-control text-dark" placeholder="website" type="text" [(ngModel)]="selectedplan.planAmount"
        name="categoryName" />
    </div>
    <div class=" form-group">
      <span>Plan Duraction</span>
      <input class=" form-control text-dark" placeholder="website" type="text" [(ngModel)]="selectedplan.planDuraction"
        name="categoryName" />
    </div>
    <div class=" form-group">
      <span>Plan Start Date</span>
      <input class=" form-control text-dark" placeholder="website" type="text" [(ngModel)]="selectedplan.startDate"
        name="categoryName" />
    </div>
    <div class=" form-group">
      <span>Plan End Date</span>
      <input class=" form-control text-dark" placeholder="website" type="text" [(ngModel)]="selectedplan.endDate"
        name="categoryName" />
    </div>
    <div class=" form-group">
      <span>Plan Subscription Status</span>
      <input class=" form-control text-dark" placeholder="website" type="text" [(ngModel)]="selectedplan.isSubscription"
        name="categoryName" />
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button type="button" class="btn btn-outline-dark mr-3" (click)="editModal.close('Close click')">Close</button>
    <button type="button" class="btn btn-fill btn-primary" (click)="saveUpdatePlan(editPlan)">Save</button>
  </div>
</ng-template>


<!-- Edit  Business Modal -->
<ng-template #editContent let-editModal>
  <div class=" modal-header">
    <h4 class="modal-title">Edit Business</h4>
    <button type="button" class="close" aria-label="Close" (click)="editModal.close('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class=" form-group">
      <!-- <label style="position: absolute; justify-content: center; height: 100%; align-items: center; display: flex; right: 0;  font-size: 12px;">Bussiness Name</label> -->
      <input class=" form-control text-dark" placeholder="bussinessName" type="text"
        [(ngModel)]="selectedCategory.bussinessName" name="categoryName" />
    </div>
    <div class=" form-group">
      <select [(ngModel)]='cate' style="width: 100%; border: 1px solid gray; border-radius: 10px; height: 40px;"
        (change)="getcompanyid($event.target.value)">
        <option *ngFor="let category of bcategories">{{category.businessCategory}}
        </option>
      </select>
    </div>
    <div class="form-group">
      <input class="form-control text-dark" placeholder="Image" (change)="browse($event)" type="file" name="image" />
    </div>
    <div class=" form-group">
      <input class=" form-control text-dark" placeholder="contactNumber" type="text"
        [(ngModel)]="selectedCategory.contactNumber" name="categoryName" />
    </div>
    <div class=" form-group">
      <input class=" form-control text-dark" placeholder="extracontactNumber" type="text"
        [(ngModel)]="selectedCategory.extracontactNumber" name="categoryName" />
    </div>
    <div class=" form-group">
      <input class=" form-control text-dark" placeholder="status" type="text" [(ngModel)]="selectedCategory.status"
        name="categoryName" />
    </div>
    <div class=" form-group">
      <input class=" form-control text-dark" placeholder="userName" type="text" [(ngModel)]="selectedCategory.userName"
        name="categoryName" />
    </div>
    <div class=" form-group">
      <input class=" form-control text-dark" placeholder="designation" type="text"
        [(ngModel)]="selectedCategory.designation" name="categoryName" />
    </div>
    <!-- <div class=" form-group">
        <input class=" form-control text-dark" placeholder="logo" type="file" [(ngModel)]="selectedCategory.logo"
          name="categoryName" />
      </div> -->
    <div class=" form-group">
      <input class=" form-control text-dark" placeholder="website" type="text" [(ngModel)]="selectedCategory.website"
        name="categoryName" />
    </div>
    <div class=" form-group">
      <input class=" form-control text-dark" placeholder="email" type="text" [(ngModel)]="selectedCategory.email"
        name="categoryName" />
    </div>
    <div class=" form-group">
      <input class=" form-control text-dark" placeholder="address" type="text" [(ngModel)]="selectedCategory.address"
        name="categoryName" />
    </div>
    <div class=" form-group">
      <input class=" form-control text-dark" placeholder="city" type="text" [(ngModel)]="selectedCategory.city"
        name="categoryName" />
    </div>
    <div class=" form-group">
      <input class=" form-control text-dark" placeholder="state" type="text" [(ngModel)]="selectedCategory.state"
        name="categoryName" />
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button type="button" class="btn btn-outline-dark mr-3" (click)="editModal.close('Close click')">Close</button>
    <button type="button" class="btn btn-fill btn-primary" (click)="onEditSave(editModal)">Save</button>
  </div>
</ng-template>