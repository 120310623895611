import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestApiService } from 'src/app/services/rest-api.service';
import { environment } from 'src/environments/environment'
@Component({
  selector: 'app-download-post',
  templateUrl: './download-post.component.html',
  styleUrls: ['./download-post.component.scss']
})
export class DownloadPostComponent implements OnInit {
  selectedFile
  post
  frame
  LogoPosition
  BusinessTextPosition
  cardlogo = 'assets/img/angular2-logo-red.png';
  logo = {

    color: '',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    img_width: 15,
    img_height: 20,
    otherIcon: '',
    otherSize: '',
  };
  businessText = {
    color: '',
    size: '',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    img_width: 15,
    img_height: 20,
    otherIcon: '',
    otherSize: '',
  };
  allFrames
  postData
  framePosition = 0
  constructor(private router: Router, private restApiService: RestApiService) { }

  ngOnInit(): void {
    this.getFrame();
  }

  //API get all frames
  async getFrame() {
    const { data } = await this.restApiService.getFrames();
    this.allFrames = data
    
    this.frame = this.allFrames[0].image
  }

  async getcompanyid(event) {
    this.frame = this.allFrames[event].image;
    this.framePosition = event
  }
  // browse local post 
  async browsePost(ev) {
    this.selectedFile = ev.target.files[0];
    this.post = await this.getBase64(this.selectedFile);
  }
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  // change logo and business text position
  onPositionChange(event, varType, position) {
    const value = parseFloat(event.target.value);
    this[varType][position] = value;
  }
  onChangeWidthHeight(event, varType, wsize) {
    const value = parseFloat(event.target.value);
    this[varType][wsize] = value;
  }
  // change business text color 
  onChangeBusinessTextColor(event, varType, Color) {
    const value = event.target.value;
    this[varType][Color] = value;
  }

  // Dropdown logo position
  postDropDown;
  toggleFun0() {
    this.postDropDown != this.postDropDown;
  }

  toggleFun1() {
    this.LogoPosition != this.LogoPosition;
  }
  toggleFun2() {
    this.BusinessTextPosition != this.BusinessTextPosition;
  }


  DownloadData() {

    const position = {
      image: this.selectedFile,
      framePosition: this.framePosition,
      businessText: JSON.stringify(this.businessText),
      logo: JSON.stringify(this.logo)

    }

    this.restApiService.setAllUserPost(position).then(res => {
      try {
        if(res && res.code ==200){
          setTimeout(() => {
            this.restApiService.downloadPostZip().then(res=>{
                window.open(environment.baseUrl+res.data)
              })
          }, 15000);
        }
      } catch (error) {
        console.log(error);

      }
    }).catch(err => {
      console.log(err);

    })
  }

}
