import { environment } from './../../../../environments/environment.prod';
import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RestApiService } from 'src/app/services/rest-api.service';
import Swal from 'sweetalert2';
import { CdkDragDrop, moveItemInArray, CdkDrag } from '@angular/cdk/drag-drop';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-festival',
  templateUrl: './festival.component.html',
  styleUrls: ['./festival.component.scss']
})
export class FestivalComponent implements OnInit {
  isSpinner = false
  festivals = [];
  closeResult: string;
  searchName: string;
  categories = [];
  festival = {
    categoryId: '',
    image: null,
    title: '',
    isPaid: false
  }
  selectedFestival;
  categoryList
  upcategoryList
  allCategoryPost
  tags
  today
  bannerFestival
  notificationImage
  selectedImageIndex = null
  selectedImageCategory = null

  offset = 0;
  limit = 50;
  constructor(private restApiService: RestApiService, public http: HttpClient, private modalService: NgbModal, public activeModal: NgbActiveModal) { }
  ngOnInit(): void {
    this.getCategory();
    this.getFestivals()
    this.getBannerFestival();
  }
  getCategory() {
    this.restApiService.getCategory().then(res => {
      if (res && res.code === 200) {
        this.categories = res.data
      }
    }).catch(err => {
      console.log('error is', err);
    })
  }
  shortDate(arrya: any[]) {
    return arrya.sort((a: any, b: any) => {
      var old = new Date(a.createdAt).getTime();
      var current = new Date(b.createdAt).getTime();
      return current - old;
    });
  }
  drop(event: CdkDragDrop<unknown>) {
    moveItemInArray(this.festivals, event.previousIndex, event.currentIndex);
  }
  sortPredicate(index: number, item: CdkDrag<number>) {
    return (index + 1) % 2 === item.data % 2;
  }

  getBannerFestival() {
    this.restApiService.getFestivalbyCategoryName('Banner').then(res => {
      if (res && res.code === 200) {
        const data = res.data.festivals;

        this.bannerFestival = data.filter(banner => banner.status === 'ACTIVE')
        // console.log(this.bannerFestival);
      }
    }).catch(err => {
      console.log('error is ', err);
    })
  }
  // getFestivals() {
  //   this.today = new Date();
  //   var date = this.today.getFullYear() + '-' + (this.today.getMonth() + 1) + '-' + (this.today.getDate());
  //   this.restApiService.getCategoryWiseFestival().then(res => {
  //     if (res && res.code === 200) {
  //       this.categoryList = res.data.sort((a, b) => a.category.index - b.category.index);
  //       this.upcategoryList = this.categoryList.filter((bussiness =>
  //         new Date(bussiness?.category?.extraData?.EndDate) >= new Date(date)));
  //       this.allCategoryPost = this.categoryList
  //       const tags = this.allCategoryPost.map((x) => { return x.category.name })
  //       this.tags = ['All', ...tags]
  //     }
  //   }).catch(err => {
  //     console.log('error is ', err);
  //   })

  // }
  getFestivals() {
    this.today = new Date();
    var date = this.today.getFullYear() + '-' + (this.today.getMonth() + 1) + '-' + (this.today.getDate());
    this.restApiService.getCategoryWiseOffsetFestival(this.offset, this.limit).then(res => {
      if (res && res.code === 200) {
        this.categoryList = res.data.sort((a, b) => a.category.index - b.category.index);
        this.upcategoryList = this.categoryList.filter((bussiness =>
          new Date(bussiness?.category?.extraData?.EndDate) >= new Date(date)));
        this.allCategoryPost = this.categoryList
        const tags = this.allCategoryPost.map((x) => { return x.category.name })
        this.tags = ['All', ...tags]
      }
    }).catch(err => {
      console.log('error is ', err);
    })

  }

  openNotificationPopup(content, img, i?, festival_category_id?) {
    this.modalService.open(content);
    this.notificationImage = img.image
    console.log(img.image);
    if (i && festival_category_id) {
      this.selectedImageIndex = i
      this.selectedImageCategory = festival_category_id
    }
  }

  async SendNotificationBtn(title?, msg?) {
    let imgurl = this.notificationImage;
   
    if (confirm('Are you sure to send ?')) {

      let body = {
        notification: {
          title: title,
          body: msg,
          sound: 'default',
          click_action: 'FCM_PLUGIN_ACTIVITY',
          // "schedule": { "at": new Date(Date.now() + 1000 * 5) },
          image: imgurl ? imgurl : '',
        },
        data: {
          title: title,
          body: msg,
          index: this.selectedImageIndex ? this.selectedImageIndex : '',
          categoryId: this.selectedImageCategory ? this.selectedImageCategory : '',
          sound: 'default',
          click_action: 'FCM_PLUGIN_ACTIVITY',
          image: imgurl ? imgurl : '',
        },
        android: {
          notification: {
            icon:imgurl ? imgurl : '',
            click_action: "FCM_PLUGIN_ACTIVITY"
          }
        },
        to: '/topics/all',
        // to: "fsuCtjujSyeX-hULkyy5No:APA91bFgGAKZpcoJgQ5B2ypwEafy463PcEKVjzbMJEiMFsu4t0oQINBFmF0Fw5f3FgKlt-43gqb0Y9y5LbCCtX_bsaUfzwxk8FjFP5gv6ZHbqRVMugJH1RgzJWhza7WtMeLdFVV7BpBC",
        priority: 'high',
      };
      let options = new HttpHeaders().set('Content-type', 'Application/json');
      this.http
        .post('https://fcm.googleapis.com/fcm/send', body, {
          headers: options.set(
            'Authorization',
            'key=AAAAyZsBpJ8:APA91bEuF1wYuysSsGktQzeROoCs5E8F9OsOkkR_Jjqc6GSc2G3j7sIuiYWvuGB9A_1is2UzXVMeVMFnHpbglNdQpZYva5lKBq8n4LT7NGzBqtss5MHpgB-69Of-I6D-fHSRGIjdgZmo'
          ),
        })
        .subscribe(console.log, console.error);
       
      this.addDB(title,msg)
      this.modalService.dismissAll()
       
     
    } else this.isSpinner = false

   
  }
  async addDB(title,msg){
    const obj = {
      image: this.notificationImage,
      title: title,
      body: msg,
    };

    try {
      await this.restApiService.addNotification(obj).then(res => {
        if (res && res.code === 200) {
         
          this.isSpinner = false
        }
      })
    } catch (error) {
      console.log(error);
      // imgurl = '';
    }
  }

  openPopup(content, item?) {
    if (item) {
      const obj = {
        categoryId: item.categoryId._id,
        title: item.title,
        _id: item._id,
        isPaid: item.isPaid
      }
      this.selectedFestival = obj
    }
    this.modalService.open(content);
  }
  browse(event) {
    this.festival.image = event.target.files[0];
  }
  onSave(modal) {
    this.isSpinner = true
    this.restApiService.addFestival(this.festival).then(res => {
      if (res && res.code === 200) {
        modal.close();
        this.getFestivals()
        this.getBannerFestival()
        this.isSpinner = false
      }
    }).catch(err => {
      this.isSpinner = false
      console.log('error is', err);
    })
  }
  onDelete(festival) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.restApiService.deleteFestival(festival._id).then(res => {
          if (res && res.code === 200) {
            Swal.fire(
              'Deleted!',
              'Festival has been deleted.',
              'success'
            )
            this.getFestivals()
            this.getBannerFestival()
          }
        }).catch(err => {
          console.log('error is', err);
        })

      }
    })
  }
  onEditSave(modal) {
    let obj: any;
    if (!this.selectedFestival.isPaid) {
      this.selectedFestival.isPaid = false
    }
    obj = {
      categoryId: this.selectedFestival.categoryId,
      title: this.selectedFestival.title,
      isPaid: this.selectedFestival.isPaid
    }
    if (this.festival.image) {
      obj.image = this.festival.image;
    }


    this.restApiService.editFestival(this.selectedFestival._id, obj).then(res => {
      if (res && res.code === 200) {
        modal.close()
        this.getFestivals()
        this.getBannerFestival()
      }
    }).catch(err => {
      console.log('error is', err);
    })
  }
  onDeleteFestival(category) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.isSpinner = true;
        this.restApiService.deleteCategory(category.category._id).then(res => {
          if (res && res.code === 200) {
            Swal.fire(
              'Deleted!',
              'Festival has been deleted.',
              'success'
            )
            this.getFestivals();
            this.getBannerFestival();
            this.isSpinner = false;

          }
        }).catch(err => {
          console.log('error is', err);
        })

      }
    }).catch(err => {
      console.log('error is', err);
    })
  }

  previousOrNext(isNext) {
    if (isNext) {
      this.offset++;
      this.getFestivals();
    } else {
      if (this.offset != -1) {
        this.offset--;
        this.getFestivals();
      }
    }
  }
}
