<div class="login-container">
    <div class="card card-login mx-auto text-center bg-dark">
        <div *ngIf="isSpinner === true" class="spinner">
            <div class="spinner-border" style="color: #f3bb1c"></div>
          </div>
        <div class="card-header mx-auto bg-dark  mb-4">
            <div class="logo-container"> <img src="assets/img/shapemelogo.png" class="logo" alt="Logo"> </div><br />
            <span class="logo_title mt-5">Shapeme Admin</span>
        </div>
        <div class="card-body">
            <div [formGroup]="loginForm">
                <div class="input-group form-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-user"></i></span>
                    </div>
                    <input type="text" autocomplete="off"  class="form-control" formControlName="email" placeholder="example@gmai.com"
                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Email is required</div>
                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                    </div>
                </div>

                <div class="input-group form-group mb-4">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-key"></i></span>
                    </div>
                    <input type="password" autocomplete="off"  class="form-control" formControlName="password" placeholder="Password"
                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                        <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                    </div>
                    
                </div>
                
                <div class="form-group" >
                    <button (click)="onLogin()" name="btn" value="Login" 
                        class="float-center login_btn" >
                        Login
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>