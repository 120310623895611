import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import GlobleService from 'src/app/services/globle.service';
import { RestApiService } from 'src/app/services/rest-api.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-all-users-deatils',
  templateUrl: './all-users-deatils.component.html',
  styleUrls: ['./all-users-deatils.component.scss'],
})
export class AllUsersDeatilsComponent implements OnInit {
  filterTerm: string;
  UserData = [];
  isSpinner = false;
  primeUser = [];
  UsersArray = [];
  offset = 0;
  limit = 100;
  number;
  TotalprimeUser;
  isExpand = false;
  dashboardItems;

  viewUser;
  selectedUserBusiness = [];
  selectedBusiness;
  bcategories;
  bussinessForm = {
    _id: '',
    bussinessName: '',
    businesscategory: '',
    userName: '',
    designation: '',
    contactNumber: '',
    extracontactNumber: '',
    email: '',
    website: '',
    address: '',
    city: '',
    state: '',
    logo: '',
  };
  cate;
  cat_value;
  logoFile;
  logoImage;
  isTure = false;
  viewUserExtraData;

  getscriptionData;
  plandata;
  planDate;
  Duraction;
  razorpay_p_id;
  selectedplan;
  position;
  SubPlan = {
    planId: '',
    razorpay_p_id: '',
    startDate: '',
    planAmount: 0,
    isSubscription: false,
    endDate: '',
    PlanDuraction: '',
  };
  primeUserCount: any;
  allPrimeUserCount: any;
  constructor(
    private restApiService: RestApiService,
    private router: Router,
    private modalService: NgbModal,
    private globleService: GlobleService
  ) {
    this.getbussinessCategory();
    this.getsubscriptionPlan();
  }

  ngOnInit(): void {
    this.getUserDetails();
    this.getPrimeUsersCount();
  }
  previousOrNext(isNext) {
    this.isSpinner = true;
    if (isNext) {
      this.offset++;
      this.getUserDetails();
    } else {
      if (this.offset != -1) {
        this.offset--;
        this.getUserDetails();
      }
    }
  }
  getPrimeUsersCount() {
    this.restApiService.getPrimeUserCounts().then((res) => {
      if (res && res.code == 200) {
        this.primeUserCount = res.data.primeUserCount;
        this.allPrimeUserCount = res.data.allPrimeUserCount;
      }
    }, (err) => {
      console.log('err is in prime count', err);
    });
  }
  getUserDetails() {
    this.isSpinner = true;
    this.restApiService.getUsers(this.offset, this.limit).then(
      (res) => {
        if (res && res.code == 200) {
          // this.primeUserCount = res.data.primeUserCount;
          // this.allPrimeUserCount = res.data.allPrimeUserCount;
          this.UsersArray = res.data.users;
          this.selectUser('allUser');
          this.isSpinner = false;
        }
      },
      (err) => {
        this.isSpinner = false;
        this.globleService.showError('Opps!', 'Somthing wrong.');
        console.log('err is', err);
      }
    );
  }
  isDesc = true;
  changeOrder() {
    if (this.isDesc) {
      this.UserData = this.shortDateAsc(this.UsersArray);
      this.isDesc = false;
    } else {
      this.UserData = this.shortDateDesc(this.UsersArray);
      this.isDesc = true;
    }
  }
  isPrimeDesc = true;

  changePrimeOrder() {
    if (this.isPrimeDesc) {
      this.UserData = this.shortIsPrimeDataAsc(this.UsersArray);
      this.isPrimeDesc = false;
    } else {
      this.UserData = this.shortIsPrimeDataDesc(this.UsersArray);
      this.isPrimeDesc = true;
    }
  }
  getSearchedUserDetails(value) {
    // this.UserData = [];
    if (value.length > 2) {
      this.restApiService.getSearchedUsers(value).then(
        async (res) => {
          if (res && res.code == 200) {
            if (res.data.length) {
              this.UserData = res.data;
            } else {
              this.UserData = [];
            }
            this.isSpinner = false;
          }
        },
        (err) => {
          this.isSpinner = false;
          this.globleService.showError('Opps!', 'Somthing wrong.');
          console.log('err is', err);
        }
      );
    } else {
      this.getUserDetails();
    }
  }
  clearFilter() {
    this.getUserDetails();
  }

  selectUser(e) {
    if (!this.TotalprimeUser) {
      this.UsersArray.map((ele) => {
        ele.extraData.map((e) => {
          if (e.isSubscription == true) {
            this.primeUser.push(ele);
          }
        });
      });
    }
    if (e == 'allUser') {
      // this.UserData = this.shortDateAsc(this.UsersArray);
      this.UserData = this.UsersArray
      this.TotalprimeUser = this.UserData.length;
    } else {
      this.UserData = this.primeUser;
      this.TotalprimeUser = this.UserData.length;
    }
  }
  shortDate(arrya: any[]) {
    return arrya.sort((a: any, b: any) => {
      var old = new Date(a.createdAt).getTime();
      var current = new Date(b.createdAt).getTime();
      return current - old;
    });
  }
  shortDateAsc(arrya: any[]) {
    return arrya.sort((a: any, b: any) => {
      var old = new Date(a.activeTime[a.activeTime.length - 1]).getTime();
      var current = new Date(b.activeTime[b.activeTime.length - 1]).getTime();
      return current - old;
    });
  }
  shortDateDesc(arrya: any[]) {
    return arrya.sort((b: any, a: any) => {
      var old = new Date(a.activeTime[a.activeTime.length - 1]).getTime();
      var current = new Date(b.activeTime[b.activeTime.length - 1]).getTime();
      return current - old;
    });
  }
  shortIsPrimeDataAsc(arrya: any[]) {
    return arrya.sort((a: any, b: any) => {
      var old = a.extraData.length
      var current = b.extraData.length;
      return current - old;
    });
  }
  shortIsPrimeDataDesc(arrya: any[]) {
    return arrya.sort((b: any, a: any) => {
      var old = a.extraData.length
      var current = b.extraData.length;
      return current - old;
    });
  }
  shortPlanEndDateWise(arrya: any[]) {
    return arrya.sort((a: any, b: any) => {
      var old = new Date(a.endDate).getTime();
      var current = new Date(b.endDate).getTime();
      return current - old;
    });
  }

  async getUserBusiness(i) {
    if (this.isExpand && i._id == this.viewUser?._id) {
      this.isExpand = false;
    } else {
      this.restApiService.getuserbussinessCategory(i._id).then((res) => {
        this.isExpand = true;
        this.selectedUserBusiness = res.data;
        this.viewUser = i;
        this.viewUserExtraData = this.shortPlanEndDateWise(i?.extraData);
      });
    }
  }

  onDownloadAllUserExcelSheet(value) {
    this.isSpinner = true;
    if (!value) {
      this.restApiService
        .generateSheet(false)
        .then((res) => {
          console.log("all user exce;", res);
          if (res) {
            window.open(environment.baseUrl + 'uploads/AllUser.xlsx');
            this.isSpinner = false;
          }
        })
        .catch((err) => {
          this.isSpinner = false;
          console.log(err);
          this.globleService.showError('Opps!', 'Somthing wrong.');
        });
    } else {
      this.restApiService
        .generateSheet(true)
        .then((res) => {
          if (res) {
            window.open(environment.baseUrl + 'uploads/PrimeUser.xlsx');
            this.isSpinner = false;
          }
        })
        .catch((err) => {
          this.isSpinner = false;
          console.log(err);
          this.globleService.showError('Opps!', 'Somthing wrong.');
        });
    }
  }
  getbussinessCategory() {
    this.restApiService
      .getbussinessCategory()
      .then((res) => {
        if (res && res.code === 200) {
          this.bcategories = res.data.sort((a, b) => a.index - b.index);
        }
      })
      .catch((err) => {
        this.globleService.showError('Opps!', 'Somthing wrong.');
        console.log('error is', err);
        this.isSpinner = false;
      });
  }

  onDelete(business) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.restApiService
          .deleteBusinessEcard(business._id)
          .then((res) => {
            if (res && res.code === 200) {
              Swal.fire('Deleted!', 'Ecard has been deleted.', 'success');
              this.isSpinner = false;
              this.isExpand = false;
            }
          })
          .catch((err) => {
            this.isSpinner = false;
            this.globleService.showError('Opps!', 'Somthing wrong.');
            console.log('error is', err);
          });
      }
    });
  }
  onEditBusiness(modal, category) {
    this.selectedBusiness = JSON.parse(JSON.stringify(category));
    this.modalService.open(modal);
    this.cate = this.selectedBusiness.businesscategory;
    this.getcompanyid(this.cate);
  }

  getcompanyid(event) {
    this.cat_value = event;
  }

  browse(event) {
    this.isTure = true;
    this.logoImage = event.target.files[0];
  }
  onEditSave(modal) {
    // this.isSpinner = true;
    this.bussinessForm._id = this.selectedBusiness._id;
    this.logoFile = this.selectedBusiness.logo;
    this.bussinessForm.bussinessName = this.selectedBusiness.bussinessName;
    this.bussinessForm.businesscategory = this.cat_value;
    this.bussinessForm.userName = this.selectedBusiness.userName;
    this.bussinessForm.designation = this.selectedBusiness.designation;
    this.bussinessForm.contactNumber = this.selectedBusiness.contactNumber;
    this.bussinessForm.extracontactNumber =
      this.selectedBusiness.extracontactNumber;
    this.bussinessForm.email = this.selectedBusiness.email;
    this.bussinessForm.website = this.selectedBusiness.website;
    this.bussinessForm.address = this.selectedBusiness.address;
    this.bussinessForm.city = this.selectedBusiness.city;
    this.bussinessForm.state = this.selectedBusiness.state;
    let value = this.bussinessForm;
    if (this.isTure == true) {
      value.logo = this.logoImage;
      this.isTure = false;
    } else {
      if (this.logoFile) {
        value.logo = this.logoFile;
      }
    }
    value['userId'] = this.viewUser._id;
    this.restApiService
      .updateBussinessDetail(value)
      .then((res) => {
        if (res && res.code === 200) {
          this.getUserBusiness(this.viewUser);
          modal.close();
          this.isSpinner = false;
        }
      })
      .catch((err) => {
        this.isSpinner = false;
        console.log('error is', err);
        this.globleService.showError('Opps!', 'Somthing wrong.');
      });
  }

  // Subscription plan

  addUserPlanSubscription(modal) {
    this.modalService.open(modal);
    //
  }

  async sendPlan(datas) {
    this.isSpinner = true;
    this.plandata = datas;
    if (this.plandata.duraction == 'Monthly') {
      this.planDate = this.AfterOneMonthDate();
      this.Duraction = 'Monthly';
    } else if (this.plandata.duraction == 'Yearly') {
      this.planDate = this.AfterOneYearDate();
      this.Duraction = 'Yearly';
    }
    let info = {
      isSubscription: true,
      planAmount: datas.planDiscount,
      planId: datas._id,
      startDate: this.planDate.StartingAt,
      endDate: this.planDate.EndingAt,
      razorpay_p_id: this.razorpay_p_id,
      PlanDuraction: this.Duraction,
    };
    if (!info.razorpay_p_id) {
      alert('Enter Razorpay payement Id');
    } else {
      this.restApiService
        .addUserSubscription(this.viewUser._id, info)
        .then((res) => {
          if (res && res.code == 200) {
            localStorage.setItem('user', JSON.stringify(res.data));
            localStorage.setItem(
              'isSubscription',
              JSON.stringify(
                res.data.extraData[res.data.extraData.length - 1].isSubscription
              )
            );
            this.getUserDetails();
            setTimeout(() => {
              this.getSearchedUserDetails(this.filterTerm);
              this.modalService.dismissAll();
            }, 1000);
            this.isSpinner = false;
            this.getbussinessCategory();
          }
        })
        .catch((err) => {
          console.log('error is', err);
        });
    }
  }

  getsubscriptionPlan() {
    this.restApiService.getsubscriptionPlan().then((res) => {
      if (res && res.code == 200) {
        const data = res.data;
        this.getscriptionData = data.sort((a, b) => a.index - b.index);
      }
    });
  }
  GetTodayDate() {
    return new Date();
  }
  AfterOneYearDate() {
    const n = 365; //number of days to add.
    var today = this.GetTodayDate(); //Today's Date
    var requiredDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + n
    );
    return {
      StartingAt: today.toISOString(),
      EndingAt: requiredDate.toISOString(),
    };
  }
  AfterOneMonthDate() {
    const n = 28; //number of days to add.
    var today = this.GetTodayDate(); //Today's Date
    var requiredDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + n
    );
    return {
      StartingAt: today.toISOString(),
      EndingAt: requiredDate.toISOString(),
    };
  }
  updateSubscriptionPlan(modal, value) {
    this.selectedplan = JSON.parse(JSON.stringify(value));
    this.modalService.open(modal);
  }
  saveUpdatePlan(model) {
    this.SubPlan.razorpay_p_id = this.selectedplan.razorpay_p_id;
    this.SubPlan.planId = this.selectedplan.planId;
    this.SubPlan.startDate = this.selectedplan.startDate;
    this.SubPlan.planAmount = this.selectedplan.planAmount;
    this.SubPlan.isSubscription = this.selectedplan.isSubscription;
    this.SubPlan.endDate = this.selectedplan.endDate;
    this.SubPlan.PlanDuraction = this.selectedplan.planDuraction;

    let data = this.SubPlan;
    // data['position'] = this.position;
    this.restApiService
      .updateUserPlan(this.viewUser._id, data)
      .then((res) => {
        if (res && res.code === 200) {
          this.getUserDetails();
          setTimeout(() => {
            this.getSearchedUserDetails(this.filterTerm);
            this.modalService.dismissAll();
          }, 1000);
        }
        this.isExpand = false;
      })
      .catch((err) => {
        this.isExpand = false;
        console.log('error is', err);
      });
  }

  expirePrimeUsers() {

    this.restApiService
      .expireSheet()
      .then((res) => {
        console.log("expire data res --", res);
        if (res) {
          window.open(environment.baseUrl + 'uploads/subscriptionexprired.xlsx');
        }
      })
      .catch((err) => {
        console.log("expire data error --", err);
      });

  }

  dwSheet(e) {
    console.log("on excel", e);
    if (e == 'dwAllUser') {
      this.onDownloadAllUserExcelSheet(false);
    }
    if (e == 'dwPrimeUser') {
      this.onDownloadAllUserExcelSheet(true);
    }
    if (e == 'dwExpPrimeUser') {
      this.expirePrimeUsers();
    }
  }


}
