<div class=" content">
  <div class=" row">
    <div class=" col-md-12">
      <div class=" card">
        <div class=" card-header">
          <h4 class=" card-title">Frames</h4>
          <button class=" btn btn-fill btn-primary mr-3" (click)="onAddFrame()">Add</button>
        </div>
        <div class=" card-body d-flex flex-wrap">
          <div class="col-12 col-sm-12 col-md-4 col-lg-2 frame-card" *ngFor="let frame of frames;let i = index" >
            <img class="card-img-top" [src]="frame.image" *ngIf="frame.image" alt=" Card image cap"> 
            <img [src]="'assets/img/default-avatar.png'" *ngIf="!frame.image" alt="">
            <div class="card-body p-0 pt-2">
              <div class="d-flex flex-row justify-content-between">
                <h6 class="card-title">Status</h6>
                <h5 class="card-title"> {{frame.status}}</h5>
              </div>
              <div class="d-flex flex-row justify-content-between">
                <h6 class="card-title">Created At</h6>
                <h5 class="card-title">{{frame?.createdAt | date : 'dd-MM-yyyy'}}</h5>
              </div>
            </div>
            <div class="card-body d-flex flex-row justify-content-between p-0 mb-2">
              <div style="width: 46%;">
                <button class="btn-fill btn-primary edit-delete" (click)="onEdit(frame)">Edit</button>
              </div>
              <div style="width: 46%;">
                <button class="btn-fill btn-danger edit-delete" (click)="onDelete(frame)">Delete</button>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class=" card-body">
          <div class=" table-responsive">
            <table class=" table tablesorter" id="">
              <thead class="example-box" style="width: 100%">
                <tr  class="header-tag">
                  <th>Image</th>
                  <th>Status</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                <tr 
                class="example-box"
                *ngFor="let frame of frames;let i = index"
                [cdkDragData]="frame"
                  [cdkDropListSortPredicate]="'sortPredicate'"
                  cdkDrag>
                  <td>
                    <div class="avatar">
                      <img style="max-width: 30px;" [src]="frame.image" *ngIf="frame.image" alt="">
                      <img [src]="'assets/img/default-avatar.png'" *ngIf="!frame.image" alt="">
                    </div>
                  </td>
                  <td>
                    {{frame.status}}
                  </td>
                  <td>{{frame?.createdAt | date : 'dd-MM-yyyy'}}</td>
                  <td>{{frame?.updatedAt | date : 'dd-MM-yyyy'}}</td>
                  <td>
                    <button
                        class="btn btn-fill btn-primary mr-3"
                        (click)="onEdit(frame)"
                      >
                        Edit
                      </button>
                    <button class=" btn btn-fill btn-danger" (click)="onDelete(frame)">Delete</button>
                  </td>
                </tr>
                <tr *ngIf="frames.length===0">
                  <td colspan="5" class="text-center">
                    Frames not found
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>

<!-- Add modal -->
<ng-template #content let-modal>
  <div class=" modal-header">
    <h4 class="modal-title">Add Frames</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- <div class="editor">
      <img class="bg" src="assets/img/imgpsh_fullsize_anim (1).jpg" alt="">
      <img class="frame" *ngIf="frame" [src]="frame" alt="">
      <div>
        <div class=" group" style="margin: {{address.pt}}px  {{address.pl}}px;">
          <label>Alpha One Junagadh</label>
        </div>
        <div class=" group" style="margin: {{email.pt}}px {{email.pl}}px;">
          <label>epsilon@gnai,com</label>
        </div>
        <div class=" group" style="margin: {{number.pt}}px {{number.pl}}px;">
          <label>9876543210</label>
        </div>
        <div class=" group" style="margin: {{website.pt}}px {{website.pl}}px;">
          <label>epsilom.com</label>
        </div>
      </div>
    </div> -->
    <div class=" form-group">
      <label>Image</label>
      <input class=" form-control text-dark" placeholder="Image" (change)="browse($event)" type="file" name="image" />
    </div>
    <div class=" form-group">
      <label>Address</label><br>
      <!-- <input class=" form-control text-dark" [(ngModel)]="address" placeholder="Adreass" type="radio"1/> -->
      <div style="display: flex;">
        <label style="margin-right: 15px;">Margin :</label>
        <div style="margin-right: 10px;">
          <label style="margin-right: 5px;">Top</label>
          <input type="number" style="width: 30px;height: 25px;" [(ngModel)]="address.pt" class="paddinginput">
        </div>
        <div style="margin-right: 10px;">
          <label style="margin-right: 5px;">Left</label>
          <input type="number" style="width: 30px;height: 25px;" [(ngModel)]="address.pl" class="paddinginput">
        </div>
      </div>
    </div>
    <div class=" form-group">
      <label>Email</label>
      <!-- <input class=" form-control text-dark" placeholder="exam@gmai.com" type="text" /> -->
      <div style="display: flex;">
        <label style="margin-right: 15px;">Margin :</label>
        <div style="margin-right: 10px;">
          <label style="margin-right: 5px;">Top</label>
          <input type="number" style="width: 30px;height: 25px;" [(ngModel)]="email.pt" class="paddinginput">
        </div>
        <div style="margin-right: 10px;">
          <label style="margin-right: 5px;">Left</label>
          <input type="number" style="width: 30px;height: 25px;" [(ngModel)]="email.pl" class="paddinginput">
        </div>
      </div>
    </div>
    <div class=" form-group">
      <label>Phone Number</label>
      <!-- <input class=" form-control text-dark" placeholder="+91 9876543210" type="text" /> -->
      <div style="display: flex;">
        <label style="margin-right: 15px;">Margin :</label>
        <div style="margin-right: 10px;">
          <label style="margin-right: 5px;">Top</label>
          <input type="number" style="width: 30px;height: 25px;" [(ngModel)]="number.pt" class="paddinginput">
        </div>
        <div style="margin-right: 10px;">
          <label style="margin-right: 5px;">Left</label>
          <input type="number" style="width: 30px;height: 25px;" [(ngModel)]="number.pl" class="paddinginput">
        </div>
      </div>
    </div>
    <div class=" form-group">
      <label>Web Site</label>
      <!-- <input class=" form-control text-dark" placeholder="examal@.com" type="text" /> -->
      <div style="display: flex;">
        <label style="margin-right: 15px;">Margin :</label>
        <div style="margin-right: 10px;">
          <label style="margin-right: 5px;">Top</label>
          <input type="number" style="width: 30px;height: 25px;" [(ngModel)]="website.pt" class="paddinginput">
        </div>
        <div style="margin-right: 10px;">
          <label style="margin-right: 5px;">Left</label>
          <input type="number" style="width: 30px;height: 25px;" [(ngModel)]="website.pl" class="paddinginput">
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button type="button" class="btn btn-fill btn-primary" (click)=" addframe(modal)">Upload</button>
  </div>
</ng-template>

<!-- Edit Modal -->