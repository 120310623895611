import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';

type AOA = any[][];

@Component({
  selector: 'app-bulk-messages',
  templateUrl: './bulk-messages.component.html',
  styleUrls: ['./bulk-messages.component.scss']
})

export class BulkMessagesComponent implements OnInit {
  data: AOA = [[1, 2], [3, 4]];
  number: any;
  img: any;
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };

  // img: any;
  constructor(private http: HttpClient, private cdr: ChangeDetectorRef) { }
  ngOnInit(): void {
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];


      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));

      this.number = XLSX.utils.sheet_to_json(ws);

    };
    reader.readAsBinaryString(target.files[0]);
  }

  async sendMsg() {

    const payload = {
      name: "marketing",
      language: {
        code: "en_US",
      },
      components: [
        {
          type: "header",
          parameters: [
            {
              type: "image",
              image: {
                link: this.img,
              },
            }
          ],
        }
      ],
    };

    try {

      this.number.forEach((item) => {

        let body = {
          "messaging_product": "whatsapp",
          "to": item.mobileNo,
          "type": "template",
          "template": JSON.stringify(payload)
        }

        const token = "EAAMbzEaZBLkQBOzd1bZCg4mVrftsHfQ1LZASPLOm8F6urddAAvhBL05rW9EqJaFI1TBfN83UQdJE4vjhGzBYHOu7ahlehsy2pEN9ha9FXXuzZAZCb0DOqan3QqLsvciQZB2mg76sOkn3cCFyomhRFFn8S16wIMPtVpjySs5g1U0H6vd88q5rZCadZBas1kPkjhmJfIDp7HuzXttTCt6Y"

        let headers = {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        }

        this.http.post('https://graph.facebook.com/v18.0/114976675042876/messages', body, { headers }).subscribe({
          next: (res: any) => {
            console.log('send msg ==>', res);
          }, error: (err: any) => {
            console.log(err);
          }
        })

      });



      // console.log(await JSON.stringify(await JSON.stringify(payload)));

    } catch (error) {
      console.log("try error ===>", error);
    }
  }

  async getImage(evt: any) {
    // console.log(URL.createObjectURL(evt.target.files[0]));
    let reader: any = new FileReader();
    let base64String: any = "";
    reader.onload = async () => {
      base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
      // this.img = reader.result;
      let body = {
        base64_image: base64String
      }
      this.http.post('https://www.epsilon-technology.com/whatsapp/marketingImg.php', body).subscribe({
        next: (res: any) => {
          this.img = res.url;
          // console.log('upload img ==>', res.url);
        }, error: (err: any) => {
          // console.log('upload img ==>', err);
        }
      })
      this.cdr.detectChanges();
    }
    await reader.readAsDataURL(evt.target.files[0])
    // console.log(btoa(evt.target.files[0]));
  }
}
