import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RestApiService } from 'src/app/services/rest-api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  isSpinner = false;
  constructor(private formBuilder: FormBuilder, private restApiService: RestApiService, private router: Router) {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  ngOnInit(): void {
  }
  get f() { return this.loginForm.controls; }
  onLogin() {
    this.isSpinner = true;
    this.submitted = true;
    const { value } = this.loginForm
    this.restApiService.login(value).then(res => {
      if (res.code === 200) {
        this.isSpinner = false;
        localStorage.setItem('admin', JSON.stringify(res.data))
        localStorage.setItem('token', res.data.token)
        // console.log(res.data.token);
        
        if (res.data.user.role != "D") {
          this.router.navigateByUrl('/dashboard')
        } else {
          this.router.navigateByUrl('/category')
        }
      }
    }).catch(err => {
      console.log('err', err);
    })
  }
}
